import Actions from '../../../state/actions';
import { ReduxAction } from '../../../types';
import { doAuth } from '../../auth/state/action';
import { fetchTranslations } from '../../translation';
import {initialiseClientApiBase} from '../../clientUrl';
import { fetchConfiguration } from '../../configuration';

export const doInit = () => {
  return async (dispatch: any, getState: any) => {
    await dispatch(initialiseClientApiBase());
    await dispatch(doAuth());
    await dispatch(fetchConfiguration());
    return dispatch(fetchTranslations(getState().userState.locale));
  }
}

export const doInitActionSuccess = (data: any): ReduxAction => {
  return {
    type: Actions.INIT_ACTION_SUCCESS,
    payload: { ...data }
  }
}