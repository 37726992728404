import './FilterForm.css';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import {
  Modal, Button
} from 'elmo-elements';
import trans from 'i18next';
import {
  FilterFormProps,
  FilterFormState,
} from './type';
import { RefreshIcon } from 'elmo-elements-icons';

/**
 * Popup Filter Form for Apply and Create.
 */
class FilterForm extends Component<FilterFormProps, FilterFormState>
{
  defaultTitle = trans.t('Create Filter');

  constructor(props: FilterFormProps)
  {
    super(props);
  }

  handleSubmit = () => {
    this.props.handleSubmit(this.props.filterValues);
  };

  handleFormClear = () => {
    this.props.handleClearForm();
  };

  getTitle = () => {
    if (this.props.title) {
      return this.props.title;
    }

    return this.defaultTitle;
  }

  render()
  {
    const titleWithButton =
    <>
      <span>{ this.getTitle() }</span>
      <Button
        onClick={ this.handleFormClear }
        className="float-right"
        id="btnClearFilterForm"
      >
        <RefreshIcon />
      </Button>
    </>;

    const primaryButton = (
      <Button
        id="btnApplyFilter"
        type={'primary'}
        onClick={() => this.handleSubmit()}
        isDisabled={this.props.isApplyDisabled}
      >
        <Trans>Apply</Trans>
      </Button>
    );

    return (
      <Modal
        id="modalListFilters"
        type="side"
        ariaLabel={ this.getTitle() }
        title={titleWithButton}
        isOpened={this.props.isModalOpen}
        closeModal={this.props.toggleModal}
        className="elmo-modal-header-title-full"
        primaryButton={primaryButton}
      >
        { this.props.renderFormContent ? this.props.renderFormContent : '' }
      </Modal>
    );
  }
}

export default FilterForm;