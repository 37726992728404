import React from 'react';
import { DownloadableFileProps } from './types';
import { Link } from 'elmo-elements';
import { AttachFileIcon } from 'elmo-elements-icons';
import { getExistingApiUrl } from '../../lib/api';
import { Trans } from 'react-i18next';

class DownloadableFile extends React.Component<DownloadableFileProps>
{
  generateUrl(): string
  {
    let url:string = '';

    switch (this.props.type) {
      case 'internal':
        url = getExistingApiUrl('INTERNAL_DOWNLOAD_FILE', {hash: this.props.hash});
    }

    return url;
  }

  handleOnClick(): void
  {
    window.location.href = this.generateUrl();
  }

  render()
  {
    return (
      <Link onClick={() => this.handleOnClick()}>
        <AttachFileIcon />
        <Trans>{ this.props.label }</Trans>
      </Link>
    );
  }
}

export default DownloadableFile;