import './index.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Routes from './routes';
import Root from './Root';

ReactDOM.render(
    <Root>
      <Routes />
    </Root>
  ,
  document.getElementById('root'));
