import * as React from 'react';
import { RequisitionItem } from '../../models/RequisitionItem';
import { PaginatedList } from '../../models/PaginatedList';
import { RequisitionListRequest } from '../../models/RequisitionListRequest';
import { WithdrawRequisitionModal } from './modals/WithdrawRequisitionModal';
import { FinaliseRequisitionModal } from './modals/FinaliseRequisitionModal';
import { ReOpenRequisitionModal } from './modals/ReOpenRequisitionModal';
import { ViewRequisitionApprovalStatusModal } from './modals/ViewRequisitionApprovalStatusModal';
import { EditRequisitionModal } from './modals/EditRequisitionModal';
import {
  Badge,
  Link,
  Pagination,
  Loader,
  MessageBlock,
} from 'elmo-elements';
import { BadgeType } from 'elmo-elements/Badge/type';
import trans from 'i18next';
import { Sort } from '../../../../lib/api/models/Sort';
import { Pagination as PaginationInterface } from '../../../../lib/api/models/Pagination';
import MoreActions, { MoreActionItems } from '../helper/MoreActions';
import HireListTable, { HireListTableAction, HireLayoutAwareListTable } from '../../../../elements/HireListTable';
import { CandidateCount, getCountText } from './CandidateCountDropdown';
import { KEY_TOTAL } from './CandidateCountDropdown/type';
import './view.css';
import { formatUnixToDateString } from '../../../../lib/helper/date';
import { getConfiguration } from '../../../../lib/configuration/state/action';
import OnHoldRequisitionModal from './modals/OnHoldRequisitionModal';
import RequisitionDetailsModal from './modals/RequisitionDetailsModal';
import { RequisitionConfig } from '../../../../type/requisition/Requisition';
import { RequisitionFilterSuite } from './RequisitionFilterSuite';
import {
  RequisitionFilterValues,
  RequisitionFilterRequest, RequisitionSavedFilterValues,
} from '../../../../type/requisition/Filter';
import {RequisitionTitle} from '../../../../elements/RequisitionTitle';
import RequisitionHelper from '../../../../config/requisition';
import { getSelectedIds, getSelectedStringValue } from '../../../../lib/helper/form';

const tableRowDateFormat = 'D MMM YYYY';

export interface RequisitionViewProps {
  requisitions: PaginatedList<RequisitionItem>;
  displayAddForm: () => void;
  fetchRequisitionList: (request: RequisitionListRequest) => void;
  isSelectAllEnabled: boolean;
  dispatch: any;
  filter: RequisitionFilterValues;
  pagination: PaginationInterface;
  savedFilters: RequisitionSavedFilterValues[];
  sort: Sort;
  requisitionConfig: RequisitionConfig;
  showNotification: Function;
  loadRequisitionConfig: Function;
  myRecords: boolean;
}

export interface RequisitionViewState {
  currentPage: number;
  pageSize: number;
  selectedIds: boolean[];
  selectedItems: RequisitionItem[];
  isAllAvailableSelected: boolean;
  filter: RequisitionFilterValues;
  isWithdrawRequisitionModalOpen: boolean;
  withdrawRequisitionItem?: RequisitionItem;
  isFinaliseRequisitionModalModalOpen: boolean;
  isOnHoldRequisitionModalOpen: boolean;
  isReOpenRequisitionModalModalOpen: boolean;
  finaliseRequisitionItem?: RequisitionItem;
  onHoldRequisitionItem?: RequisitionItem;
  reOpenRequisitionItem?: RequisitionItem;
  isViewDetailsModalOpen: boolean; 
  viewDetailsItem?: RequisitionItem;
  isViewRequisitionApprovalStatusModalOpen: boolean;
  viewRequisitionApprovalStatusItem?: RequisitionItem;
  isEditRequisitionModalOpen: boolean;
  editRequisitionItem?: RequisitionItem;
}

export class RequisitionsTable extends React.Component<
  RequisitionViewProps,
  RequisitionViewState
> {
  constructor(props: RequisitionViewProps) {
    super(props);

    const keyword =
      typeof this.props.filter !== 'undefined' &&
      typeof this.props.filter.keyword !== 'undefined'
        ? this.props.filter.keyword
        : '';

    this.state = {
      currentPage: props.requisitions.pagination.pageNumber,
      pageSize: props.requisitions.pagination.resultsPerPage | 10,
      selectedIds: [],
      selectedItems: [],
      isAllAvailableSelected: false,
      filter: {
        myRecords: this.props.myRecords,
        keyword: keyword,
        statuses: [
          RequisitionHelper.status.codes.pending,
          RequisitionHelper.status.codes.open,
          RequisitionHelper.status.codes.rejected,
        ],
      },
      isWithdrawRequisitionModalOpen: false,
      isFinaliseRequisitionModalModalOpen: false,
      isOnHoldRequisitionModalOpen: false,
      isReOpenRequisitionModalModalOpen: false,
      isViewDetailsModalOpen: false,
      isViewRequisitionApprovalStatusModalOpen: false,
      isEditRequisitionModalOpen: false,
    };
  }

  searchByRequisitionFilter = (filter: RequisitionFilterValues) => {
    const state = {
      filter: {
        ...this.state.filter,
        ...filter
      },
      currentPage: 1,
    };
    if (!filter.hasOwnProperty("id")) { // filter is not a saved filter.
      delete state.filter['id'];
      delete state.filter['name'];
    }

    this.setState(state, () => {
      this.reloadRequisitionList();
    });
  };

  getCurrentPaginationRequest = (pageSize: number, currentPage: number) => {
    const filter: RequisitionFilterRequest = this.getFilterPayload();

    const currentListingRequest: RequisitionListRequest = {
      pagination: {
        resultsPerPage: pageSize,
        pageNumber: currentPage,
      },
      sort: {
        ...this.props.sort,
      },
      filter: filter,
    };

    return currentListingRequest;
  };

  componentDidUpdate(prevProps: RequisitionViewProps) {
    const {
      requisitions: {
        state: { error, isCreating },
      },
      fetchRequisitionList,
    } = this.props;
    const {
      requisitions: {
        state: { isCreating: wasCreating },
      },
    } = prevProps;
    const { pageSize, currentPage } = this.state;
    const hasRequisitionCreated = wasCreating && !isCreating;

    if (hasRequisitionCreated && !error) {
      fetchRequisitionList(
        this.getCurrentPaginationRequest(pageSize, currentPage)
      );
    }
  }

  handleToggleWithdrawRequisition = () => {
    this.setState({
      isWithdrawRequisitionModalOpen: !this.state
        .isWithdrawRequisitionModalOpen,
    });
  };

  handleToggleFinaliseRequisition = () => {
    this.setState({
      isFinaliseRequisitionModalModalOpen: !this.state
        .isFinaliseRequisitionModalModalOpen,
    });
  };

  handleToggleOnHoldRequisition = () => {
    this.setState({
      isOnHoldRequisitionModalOpen: !this.state
        .isOnHoldRequisitionModalOpen,
    });
  };

  handleToggleReOpenRequisition = () => {
    this.setState({
      isReOpenRequisitionModalModalOpen: !this.state
        .isReOpenRequisitionModalModalOpen,
    });
  };

  handleToggleViewDetails = () => {
    this.setState({
      isViewDetailsModalOpen: !this.state.isViewDetailsModalOpen
    });
  };
  
  handleToggleViewRequisitionApprovalStatus = () => {
    this.setState({
      isViewRequisitionApprovalStatusModalOpen: !this.state.isViewRequisitionApprovalStatusModalOpen,
    });
  };

  handleToggleEditRequisition = () => {
    this.setState({
      isEditRequisitionModalOpen: !this.state
        .isEditRequisitionModalOpen,
    });
  };

  handlePageSizeChange = (limit: number) => {
    this.setState({
      pageSize: limit,
      currentPage: 1,
    });

    this.props.fetchRequisitionList(this.getCurrentPaginationRequest(limit, 1));
  };

  handlePageChange = (page: number) => {
    this.setState({
      currentPage: page,
    });
    this.props.fetchRequisitionList(
      this.getCurrentPaginationRequest(this.state.pageSize, page)
    );
  };

  handleSort = (column: number, sortOrder: string): void => {
    const filter: RequisitionFilterRequest = this.getFilterPayload();

    let sortBy: string;

    switch (column) {
      case 1:
        sortBy = 'status';
        break;
      case 3:
        sortBy = 'createdDate';
        break;
      case 4:
        sortBy = 'dueDate';
        break;
      case 6:
        sortBy = 'owner';
        break;
      default:
        sortBy = '';
        sortOrder = '';
    }

    const request: RequisitionListRequest = {
      pagination: {
        ...this.props.requisitions.pagination,
      },
      sort: {
        field: sortBy,
        order: sortOrder,
      },
      filter: filter,
    };

    this.props.fetchRequisitionList(request);
  };

  private renderStatus = (data: RequisitionItem, index: number) => {
    const status = data['status'];
    let statusText = '';
    let type: BadgeType;

    switch (status) {
      case 0:
        statusText = trans.t('Pending');
        break;
      case 1:
        statusText = trans.t('Open');
        break;
      case 2:
        statusText = trans.t('Overdue');
        break;
      case 3:
        statusText = trans.t('Closed');
        break;
      case 4:
        statusText = trans.t('Rejected');
        break;
      case 5:
        statusText = trans.t('Finalised');
        break;
      case 6:
        statusText = trans.t('Withdrawn');
        break;
      case 7:
        statusText = trans.t('Resubmitted');
        break;
      case 8:
        statusText = trans.t('On Hold');
        break;
    }

    switch (status) {
      case 1:
        type = 'info';
        break;
      case 8:
        type = 'warning';
        break;
      case 0:
        type = 'warning';
        break;
      case 5:
        type = 'success';
        break;
      case 4:
        type = 'danger';
        break;
      case 6:
        type = 'danger';
        break;
      default:
        type = 'grey';
    }

    return (
      <div data-testid={`row-${index}-status`}>
        <Badge type={type}>{statusText}</Badge>
      </div>
    );
  };

  private renderOwner = (data: RequisitionItem, index: number) => {
    let owner = <>-</>;

    if (data['owner']) {
      owner = <>{data['owner']}</>;
    }

    return <p data-testid={`row-${index}-owner`}>{owner}</p>;
  };

  private renderCreatedDate = (data: RequisitionItem, index: number) => {
    return (
      <p data-testid={`row-${index}-createdDate`}>
        {formatUnixToDateString(data['createdDate'], tableRowDateFormat)}
      </p>
    );
  };

  private renderDueDate = (data: RequisitionItem, index: number) => {
    return (
      <p data-testid={`row-${index}-dueDate`}>
        {formatUnixToDateString(data['dueDate'], tableRowDateFormat)}
      </p>
    );
  };

  private renderCandidateCounts = (data: RequisitionItem, index: number): React.ReactChild => {
    const candidateCount: CandidateCount = data['candidateCount'];
    const noLinkStatus = [
      RequisitionHelper.status.codes.pending,
      RequisitionHelper.status.codes.rejected,
    ];
    let content = <>{ getCountText(candidateCount, KEY_TOTAL) }</>;

    const requisitionId = data.hasOwnProperty('id') ? data['id'] : '';
    const candidatesUrl = `/controlpanel/recruitment/requisition/job-app/${requisitionId}`;

    if (noLinkStatus.indexOf(data['status']) < 0) {
      content = (
        <Link url={candidatesUrl}>
          { content }
        </Link>
      );
    }

    return (
      <div data-testid={`row-${index}-candidateCounts`}>
        { content }
      </div>
    );
  };

  setWithdrawRequisitionItem = (withdrawRequisitionItem: RequisitionItem) => {
    this.setState({
      withdrawRequisitionItem: withdrawRequisitionItem,
    });
  };

  setFinaliseRequisitionItem = (finaliseRequisitionItem: RequisitionItem) => {
    this.setState({
      finaliseRequisitionItem: finaliseRequisitionItem,
    });
  };

  setOnHoldRequisitionItem = (requisitionItem: RequisitionItem) => {
    this.setState({
      onHoldRequisitionItem: requisitionItem,
    });
  };

  setReOpenRequisitionItem = (reOpenRequisitionItem: RequisitionItem) => {
    this.setState({
      reOpenRequisitionItem: reOpenRequisitionItem,
    });
  };

  setViewDetailsItem = (requisition: RequisitionItem) => {
    this.setState({
      viewDetailsItem: requisition
    });
  };
  
  setViewRequisitionApprovalStatusItem = (viewRequisitionApprovalStatusItem: RequisitionItem) => {
    this.setState({
      viewRequisitionApprovalStatusItem: viewRequisitionApprovalStatusItem,
    });
  };

  setEditRequisitionItem = (editRequisitionItem: RequisitionItem) => {
    this.setState({
      editRequisitionItem: editRequisitionItem,
    });
  };

  private getActionCallback = (key: string) => {
    const requisitions: RequisitionItem[] = this.props.requisitions.data
      ? this.props.requisitions.data
      : [];

    switch (key) {
      case MoreActionItems.canViewJobApplications:
        return (rowIndex: number) => {
          const requisition = requisitions[rowIndex];
          window.location.href = `/controlpanel/recruitment/requisition/job-app/${
            requisition.id
          }`;
        };

      case MoreActionItems.canViewJobAds:
        return (rowIndex: number) => {
          const requisition = requisitions[rowIndex];
          window.location.href = `/controlpanel/recruitment/requisition/jobad/${
            requisition.id
          }`;
        };

      case MoreActionItems.canViewInterviews:
        return (rowIndex: number) => {
          const requisition = requisitions[rowIndex];
          window.location.href = `/controlpanel/recruitment/requisition/interviews/${
            requisition.id
          }`;
        };

      case MoreActionItems.canViewCosts:
        return (rowIndex: number) => {
          const requisition = requisitions[rowIndex];
          window.location.href = `/controlpanel/recruitment/requisition/costs/${
            requisition.id
          }`;
        };

      case MoreActionItems.canResubmit:
        return (rowIndex: number) => {
          const requisition = requisitions[rowIndex];
          window.location.href = `/controlpanel/recruitment/requisition/resubmit/${requisition.id}`;
        }

      case MoreActionItems.canWithdraw:
        return (rowIndex: number) => {
          const requisition = requisitions[rowIndex];
          this.setWithdrawRequisitionItem(requisition);
          this.handleToggleWithdrawRequisition();
        };

      case MoreActionItems.canFinalise:
        return (rowIndex: number) => {
          const requisition = requisitions[rowIndex];
          this.setFinaliseRequisitionItem(requisition);
          this.handleToggleFinaliseRequisition();
        };

      case MoreActionItems.canOnHold:
        return (rowIndex: number) => {
          const requisition = requisitions[rowIndex];
          this.setOnHoldRequisitionItem(requisition);
          this.handleToggleOnHoldRequisition();
        };

      case MoreActionItems.canReOpen:
        return (rowIndex: number) => {
          const requisition = requisitions[rowIndex];
          this.setReOpenRequisitionItem(requisition);
          this.handleToggleReOpenRequisition();
        };

      case MoreActionItems.canViewDetails:
        return (rowIndex: number) => {
          const requisition = requisitions[rowIndex];
          this.setViewDetailsItem(requisition);
          this.handleToggleViewDetails();
        }
        
      case MoreActionItems.canViewApprovalStatus:
        return (rowIndex: number) => {
          const requisition = requisitions[rowIndex];
          this.setViewRequisitionApprovalStatusItem(requisition);
          this.handleToggleViewRequisitionApprovalStatus();
        };

      case MoreActionItems.canEdit:
        return (rowIndex: number) => {
          const requisition = requisitions[rowIndex];
          this.setEditRequisitionItem(requisition);
          this.handleToggleEditRequisition();
        };
    }
  };

  private renderActions = (
    data: RequisitionItem,
    index: number
  ): HireListTableAction[] | [] => {
    if (data['allowedActions']) {
      return MoreActions(data['allowedActions'], data, this.getActionCallback);
    }

    return [];
  };

  getCurrentSortedColumnIndex(): number {
    if (this.props.requisitions['sort']) {
      // this should reflect the table columns
      return [
        'requisitionTitle',
        'status',
        'candidateCounts',
        'createdDate',
        'dueDate',
        'owner',
      ].indexOf(this.props.requisitions.sort.field);
    }

    return -1;
  }

  getCurrentSortDirection(): string {
    if (this.props.requisitions['sort']) {
      return this.props.requisitions.sort.order;
    }

    return 'asc';
  }

  private renderTitle = (data: RequisitionItem, index: number) => {
    if (data['title']) {
      const { company } = getConfiguration();
      const { showRequisitionId } = company;

      return (
        <>
          <div>
            <strong data-testid={`row-${index}-title`}>
              <RequisitionTitle
                id={data.id}
                originalId={data.originalId}
                showLink={true}
                showRequisitionId={showRequisitionId}
                title={data.title}
              />
            </strong>
          </div>
        </>
      );
    }

    return '';
  };

  onSelectAllAvailableToggle = (isSelect: boolean) => {
    let selectedIds = this.state.selectedIds;
    let selected = this.state.selectedItems;

    if (!isSelect) {
      selectedIds = [];
    }

    this.setState({
      isAllAvailableSelected: isSelect,
      selectedIds: selectedIds,
      selectedItems: selected,
    });
  };

  isItemSelected = (data: any) => {
    return (
      !!this.state.selectedIds[data['id']] || this.state.isAllAvailableSelected
    );
  };

  onSelectAllVisibleToggle = (isSelect: boolean) => {
    const selectedIds = this.state.selectedIds.slice();
    let selectedItems = this.state.selectedItems.slice();

    this.props.requisitions.data.forEach((item: any) => {
      const currentlySelected = !!selectedIds[item['id']];
      selectedIds[item['id']] = isSelect;

      if (currentlySelected) {
        // remove the item from the array
        const foundIndex = selectedItems.findIndex((i: any) => {
          return i['id'] === item['id'];
        });
        selectedItems.splice(foundIndex, 1);
      } else {
        // add the item
        selectedItems.push(item);
      }
    });

    this.setState({
      selectedIds: selectedIds,
      selectedItems: selectedItems,
    });
  };

  onItemToggle = (data: any, isSelected: boolean) => {
    const selectedIds = this.state.selectedIds.slice();
    const currentlySelected = !!selectedIds[data['id']];
    selectedIds[data['id']] = isSelected;

    const selectedItems = this.state.selectedItems.slice();
    let isAllAvailableSelected = this.state.isAllAvailableSelected;

    if (currentlySelected) {
      isAllAvailableSelected = false;
      // remove the item from the array
      const foundIndex = selectedItems.findIndex((i: any) => {
        return i['id'] === data['id'];
      });
      selectedItems.splice(foundIndex, 1);
    } else {
      // add the item
      selectedItems.push(data);
    }

    this.setState({
      selectedIds: selectedIds,
      selectedItems: selectedItems,
      isAllAvailableSelected: isAllAvailableSelected,
    });
  };

  reloadRequisitionList = () => {
    const { pageSize, currentPage } = this.state;
    const filter: RequisitionFilterRequest = this.getFilterPayload();

    this.props.loadRequisitionConfig();

    const request: RequisitionListRequest = {
      pagination: {
        resultsPerPage: pageSize,
        pageNumber: currentPage,
      },
      sort: {
        ...this.props.sort,
      },
      filter: filter,
    };

    this.props.fetchRequisitionList(request);
  };

  getFilterPayload(): RequisitionFilterRequest {
    const { filter } = this.state;

    const payload: RequisitionFilterRequest = {};

    Object.keys(filter).forEach((filterKey: string) => {
      switch (filterKey) {
        case 'jobTypes':
        case 'owners':
        case 'departments':
        case 'positions':
        case 'locations':
        case 'requesters':
          payload[filterKey] = null;
          const filterValues = (filter[filterKey]) ? filter[filterKey] : [];
          if (filterValues && filterValues.length > 0) {
            payload[filterKey] = getSelectedIds(filterValues);
          }
          break;
        case 'keywords':
          const values = filter[filterKey] || [];
          if (values.length) {
            payload[filterKey] = getSelectedStringValue(values);
          }
          break;
        default:
          payload[filterKey] = filter[filterKey];
      }
    });
    return payload;
  }


  private renderMessageBlock = (
    heading: string,
    body: string
  ): React.ReactNode => {

    return (
      <MessageBlock>
        <MessageBlock.Header>
          <div className="mt-5 pb-3">
            {!!heading && trans.t(heading)}
          </div>
        </MessageBlock.Header>
        <MessageBlock.Body>{!!body && trans.t(body)}</MessageBlock.Body>
      </MessageBlock>
    );
  };

  private renderNoResultsFound = (): React.ReactNode => {
    return this.renderMessageBlock('There are no requisitions to show.', 'Try a different search or create a requisition.');
  };

  private renderWithdrawRequisitionModal = () => {
    if (this.state.withdrawRequisitionItem) {
      return (
        <WithdrawRequisitionModal
          isModalOpen={this.state.isWithdrawRequisitionModalOpen}
          toggleModal={this.handleToggleWithdrawRequisition}
          data={this.state.withdrawRequisitionItem}
          handlePostSubmit={this.reloadRequisitionList}
        />
      );
    }
  };

  private renderFinaliseRequisitionModal = () => {
    if (this.state.finaliseRequisitionItem) {
      return (
        <FinaliseRequisitionModal
          isModalOpen={this.state.isFinaliseRequisitionModalModalOpen}
          toggleModal={this.handleToggleFinaliseRequisition}
          data={this.state.finaliseRequisitionItem}
          handlePostSubmit={this.reloadRequisitionList}
        />
      );
    }
  };

  private renderReOpenRequisitionModal = () => {
    if (this.state.reOpenRequisitionItem) {
      return (
        <ReOpenRequisitionModal
          isModalOpen={this.state.isReOpenRequisitionModalModalOpen}
          toggleModal={this.handleToggleReOpenRequisition}
          data={this.state.reOpenRequisitionItem}
          handlePostSubmit={this.reloadRequisitionList}
        />
      );
    }
  };

  private renderOnHoldRequisitionModal = () => {
    if (this.state.onHoldRequisitionItem) {
      return (
        <OnHoldRequisitionModal
          isModalOpen={this.state.isOnHoldRequisitionModalOpen}
          toggleModal={this.handleToggleOnHoldRequisition}
          data={this.state.onHoldRequisitionItem}
          handlePostSubmit={this.reloadRequisitionList}
        />
      );
    }
  };

  private renderRequisitionDetailsModal = ():React.ReactFragment => {
    if (!this.state.viewDetailsItem) {
      return <></>;
    };

    return (
      <RequisitionDetailsModal
        key={this.state.viewDetailsItem.id}
        isModalOpen={this.state.isViewDetailsModalOpen}
        toggleModal={this.handleToggleViewDetails}
        data={this.state.viewDetailsItem}
        handlePostSubmit={() => {}}
      />
    );
  };
  
  private renderViewRequisitionApprovalStatusModal = () => {
    if (this.state.viewRequisitionApprovalStatusItem) {
      return (
        <ViewRequisitionApprovalStatusModal
          isModalOpen={this.state.isViewRequisitionApprovalStatusModalOpen}
          toggleModal={this.handleToggleViewRequisitionApprovalStatus}
          data={this.state.viewRequisitionApprovalStatusItem}
          key={this.state.viewRequisitionApprovalStatusItem.id}
          handlePostSubmit={this.reloadRequisitionList}
        />
      );
    }
  };

  private renderEditRequisitionModal = () => {
    if (this.state.editRequisitionItem) {
      return (
        <EditRequisitionModal
          isModalOpen={this.state.isEditRequisitionModalOpen}
          toggleModal={this.handleToggleEditRequisition}
          data={this.state.editRequisitionItem}
          handlePostSubmit={this.reloadRequisitionList}
          requisitionConfig={this.props.requisitionConfig}
          showNotification={this.props.showNotification}
          key={`editRequisitionModal-${this.state.editRequisitionItem.id}`}
        />
      );
    }
  };

  renderFilterSuite = (): React.ReactFragment => {
    return(
      <RequisitionFilterSuite
        filter={this.state.filter}
        savedFilters={this.props.savedFilters}
        onChange={(filter: RequisitionFilterValues) => {
          this.searchByRequisitionFilter(filter);
        }}
        requisitionConfig={this.props.requisitionConfig}
      />
    );
  };

  renderTable(): React.ReactNode
  {
    if (this.props.requisitions.state.isFetching) {
      return <Loader type="bullet" />;
    }

    if (
      !this.props.requisitions.data ||
      !this.props.requisitions.data ||
      this.props.requisitions.data.length === 0
    ) {
      return (
        <>
          {this.renderNoResultsFound()}
        </>
      );
    }

    const requisitions: RequisitionItem[] = this.props.requisitions.data;
    const ariaLabel = trans.t('List of My Requisitions');

    return (
      <HireLayoutAwareListTable
        data={requisitions}
        onItemToggle={this.onItemToggle}
        onSelectAllToggle={this.onSelectAllVisibleToggle}
        countSelectedItems={this.state.selectedItems.length}
        onSort={this.handleSort}
        isItemDisabled={(data: any) => {
          // TODO: figure out the criteria of disabled rows and apply it here
          return false;
        }}
        isItemSelected={this.isItemSelected}
        ariaLabel={ariaLabel}
        actions={this.renderActions}
        sortDirection={this.getCurrentSortDirection()}
        sortColumn={this.getCurrentSortedColumnIndex()}
        countItemsAvailable={this.props.requisitions.pagination.totalResults}
        onSelectAllAvailableToggle={this.onSelectAllAvailableToggle}
        isAllAvailableSelected={this.state.isAllAvailableSelected}
        countSelectable={requisitions.length}
        hasLayout={false}
        hasBulkActionsButton={false}
      >
        <HireListTable.Column
          label={trans.t('Requisition Title')}
          sortable={false}
          render={this.renderTitle}
        />
        <HireListTable.Column
          label={trans.t('Status')}
          render={this.renderStatus}
          sortable={true}
        />
        <HireListTable.Column
          label={trans.t('Candidates')}
          render={this.renderCandidateCounts}
          sortable={false}
        />
        <HireListTable.Column
          label={trans.t('Created Date')}
          render={this.renderCreatedDate}
          sortable={true}
        />
        <HireListTable.Column
          label={trans.t('Due Date')}
          render={this.renderDueDate}
          sortable={true}
        />
        <HireListTable.Column
          label={trans.t('Owner')}
          render={this.renderOwner}
          sortable={false}
        />
        <HireListTable.Pagination
          pageSize={this.state.pageSize}
          currentPage={this.state.currentPage}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          totalResults={this.props.requisitions.pagination.totalResults || 0}
          id="requisitionList"
        />
      </HireLayoutAwareListTable>
    );
  }

  render() {
    return (
      <>
        {this.renderFilterSuite()}
        {this.renderTable()}
        {this.renderWithdrawRequisitionModal()}
        {this.renderFinaliseRequisitionModal()}
        {this.renderOnHoldRequisitionModal()}
        {this.renderReOpenRequisitionModal()}
        {this.renderRequisitionDetailsModal()}
        {this.renderViewRequisitionApprovalStatusModal()}
        {this.renderEditRequisitionModal()}
      </>
    );
  }
}
