import { sendRequest, getExistingApiUrl } from '../../api';
import { ExtractionGetDepartmentsResponse } from './type';

export async function getDepartments(
  keyword: string
): Promise<ExtractionGetDepartmentsResponse> {
  return await sendRequest(
    `${getExistingApiUrl('DEPARTMENT_SEARCH', {
      keyword: keyword,
    })}`,
    'get'
  );
}
