import {Filter} from "../../../type/requisition/Requisition";
import {getSavedFilter} from "../requisition/service";
import {RequisitionSavedFilterValues} from "../../../type/requisition/Filter";
import {BackendToFrontendFilterMapper} from "./helper";
import {RequisitionExtractionFilterResponse} from "./type";
import { sendRequest, getExistingApiUrl } from '../../api';
import { RequisitionExtractionFilter } from './type';

export async function getSavedFilters(
  filters: Filter[]
): Promise<RequisitionSavedFilterValues[]> {
  const promises: Promise<RequisitionExtractionFilterResponse>[] = [];

  filters.forEach((filter: Filter) => {
    promises.push(getSavedFilter(filter.id));
  });

  const savedFiltersResponse = await Promise.all(promises);
  const savedFilters:RequisitionSavedFilterValues[] = savedFiltersResponse
    .map((savedFilter: RequisitionExtractionFilterResponse, index: number) => {
      return {
        id: filters[index].id,
        name: filters[index].title,
        ...BackendToFrontendFilterMapper.map(savedFilter.data)
      };
    });

  return savedFilters;
}

export async function saveRequisitionFilter(title:string, filter: RequisitionExtractionFilter)
{
  const url = getExistingApiUrl('SAVE_REQUISITION_FILTER');
  const payload = {
    requisitionFilter: {
      title,
      content: {}
    }
  };

  for (let property in filter) {
    payload.requisitionFilter.content[property] = filter[property];
  }

  return await sendRequest(url, 'post', payload);
}
