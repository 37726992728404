export const initActions = [
  'translations',
  'authentication',
  'initialiseClientApiBase',
  'configuration',
];

export const SESSION_STORAGE = {
  TRANSLATIONS: 'elmo_translations',
  CLIENT_API_BASE: 'elmo_recruitment_client_api_base',
  CONFIGURATION: 'elmo_configuration',
};
