export const KEY_NEW = 'new';
export const KEY_UNSUCCESSFUL = 'unsuccessful';
export const KEY_IN_PROGRESS = 'in_progress';
export const KEY_SHORTLIST = 'shortlist';
export const KEY_OFFER = 'offer';
export const KEY_OFFER_ACCEPTED = 'offer_accepted';
export const KEY_TOTAL = 'total';

export interface CandidateCount {
  total: number;
};

type TYPE_KEY_NEW = typeof KEY_NEW;
type TYPE_KEY_UNSUCCESSFUL = typeof KEY_UNSUCCESSFUL;
type TYPE_KEY_IN_PROGRESS = typeof KEY_IN_PROGRESS;
type TYPE_KEY_SHORTLIST = typeof KEY_SHORTLIST;
type TYPE_KEY_OFFER = typeof KEY_OFFER;
type TYPE_KEY_OFFER_ACCEPTED = typeof KEY_OFFER_ACCEPTED;
type TYPE_KEY_TOTAL = typeof KEY_TOTAL;
export type TYPE_KEYS =
  TYPE_KEY_NEW
  | TYPE_KEY_UNSUCCESSFUL
  | TYPE_KEY_IN_PROGRESS
  | TYPE_KEY_SHORTLIST
  | TYPE_KEY_OFFER
  | TYPE_KEY_OFFER_ACCEPTED
  | TYPE_KEY_TOTAL
;