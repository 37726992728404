import axios from 'axios';
import {
  RECRUITMENT_API_PATH,
  API_ENDPOINTS,
  API_RESOURCES,
  TRANSLATIONS,
} from './config';
import { getClientApiHost } from '../clientUrl/state/action';
import { CRUD_ACTION_NAMES } from '../../types';

export interface Resource {
  resourceName: string;
}

export function sendRequest<T>(
  url: string,
  method: string,
  data?: object
): Promise<T> {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url,
      data: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * Primary purpose of this function is to store all data for dispatching action.
 * This is basically a higher order which executes when it is called for the third times.
 * At first call, it stores all the action types and return a function for passing endpoint, data etc.
 * At second call, it stores endpoint, data etc and return a function for passing redux dispatch method as params(redux thunk does this).
 * At third call, it actually executes using all the value it stored in first and second call and return a promise.
 */

export const getActionMapper = (
  pendingAction: Function,
  fulfilledAction: Function,
  errorAction: Function
): Function => (
  url: string,
  method: string,
  crudActionName: string,
  data?: object
): Function => (dispatch: Function): Promise<any> => {
  dispatch(pendingAction({ crudActionName }));
  return sendRequest(url, method, data)
    .then((response: any) => {
      const payload = {
        ...response,
        crudActionName,
      };
      dispatch(fulfilledAction(payload));
    })
    .catch((error: any) => {
      dispatch(errorAction({ error, crudActionName }));
    });
};

const generateBasicAction = (type: string) => (payload: object) => ({
  type,
  payload,
});

export interface CrudAction {
  pendingActionType: string;
  fulfilledActionType: string;
  errorActionType: string;
  resetActionType: string;
}
/**
 * This function is basically a higher order function which return function for performing crud operation
 * if you need to carry out any crud operation, initialize this function with actions, then invoke the corresponding returned functions.
 * This function uses action type to generate the action, which helps to minimize the repetitive work.
 */

export const generateCrudAction = ({
  pendingActionType,
  fulfilledActionType,
  errorActionType,
  resetActionType,
}: CrudAction) => {
  const pendingAction = generateBasicAction(pendingActionType);
  const fulfilledAction = generateBasicAction(fulfilledActionType);
  const errorAction = generateBasicAction(errorActionType);
  const resetAction = generateBasicAction(resetActionType);

  const actionMapper = getActionMapper(
    pendingAction,
    fulfilledAction,
    errorAction
  );

  return {
    // Call this function when you need to fetch data using get/post method
    fetchRecords: (endpoint: string, data?: object, method?: string) => {
      const methodName: string =
        method && method.toLowerCase() === 'post' ? 'post' : 'get';
      return actionMapper(endpoint, methodName, CRUD_ACTION_NAMES.read, data);
    },
    createRecords: (endpoint: string, data: object) => {
      return actionMapper(endpoint, 'post', CRUD_ACTION_NAMES.create, data);
    },
    updateRecords: (endpoint: string, data: object) => {
      return actionMapper(endpoint, 'put', CRUD_ACTION_NAMES.update, data);
    },
    deleteRecords: (endpoint: string, data: object) => {
      return actionMapper(endpoint, 'delete', CRUD_ACTION_NAMES.delete, data);
    },
  };
};

export function saveResource<T>(resource: string, data: object): Promise<T> {
  const url = `${getClientApiHost().url}/${RECRUITMENT_API_PATH}/${resource}/${
    API_ENDPOINTS.SAVE
  }`;
  return sendRequest<T>(url, 'GET', data);
}

// @TODO: Authentication solution is not finalised. Need to update once confirmed the solution.
export function authenticate<T>(): Promise<any> {
  const url = `${getClientApiHost().url}/${RECRUITMENT_API_PATH}/${
    API_RESOURCES.AUTHENTICATION
  }/${API_ENDPOINTS.DO_AUTH}`;
  return sendRequest(url, 'GET');
}

export function fetchTranslation<T>(locale: string): Promise<T> {
  const url = `${generateApiUrl('TRANSLATION', '')}/${locale}`;
  return sendRequest<T>(url, 'GET');
}

/**
 * Build url with given resource name found in API_ENDPOINTS.
 *
 * This should generate url like http://yourdomain.com/api/recruitment/v1/config
 * As a backward compatibility the method allows generating endopoint with no version
 * by passing '' as the version argument.
 *
 * @param resourceName
 * @param version
 * @return string URL
 */
export function generateApiUrl(resourceName: string, version?: string): string {
  if (!API_ENDPOINTS.hasOwnProperty(resourceName)) {
    throw new Error('Invalid resource given to build a URL');
  }

  let versionPathFragment = '';
  if (typeof version !== 'undefined') {
    versionPathFragment = version ? `${version}/` : '';
  } else {
    versionPathFragment = `${getClientApiHost().apiVersion}/`;
  }

  return `${
    getClientApiHost().url
  }/${RECRUITMENT_API_PATH}/${versionPathFragment}${
    API_ENDPOINTS[resourceName]
  }`;
}

/**
 * Method to construct existing ER endpoint while pointing to mock
 * if mockserver is enabled.
 *
 * @param resourceName
 * @return string URL
 */
export function getExistingApiUrl(
  resourceName: string,
  resourceParams: object = {}
): string {
  if (!API_ENDPOINTS.hasOwnProperty(resourceName)) {
    throw new Error('Invalid resource given to build a URL');
  }

  let apiEndpoint = '';

  if (typeof API_ENDPOINTS[resourceName] === 'function') {
    apiEndpoint = API_ENDPOINTS[resourceName](resourceParams);
  } else {
    apiEndpoint = API_ENDPOINTS[resourceName];
  }

  return `${getApiDomain()}/${apiEndpoint}`;
}

/**
 * Get API domain.
 * 
 * Return mock API domain or return empty for current domain.
 *
 * @return string protocol://domain | empty
 */
export function getApiDomain(): string
{
  let protocol:string = 'https';

  if (process.env.REACT_APP_API_USE_MOCK_SERVER === 'true') {
    protocol = process.env.REACT_APP_API_HTTP_PROTOCOL
      ? process.env.REACT_APP_API_HTTP_PROTOCOL
      : protocol;

    return `${protocol}://${process.env.REACT_APP_API_MOCK_SERVER_BASE_URL}`;
  }

  return '';
}