import React, { ReactNode } from 'react';
import { MoreHorizIcon as ActionsIcon } from 'elmo-elements';
import { HireListTableAction } from '../type';
import { Dropdown } from 'elmo-elements';

export type ListTableActionsProps = {
  /** Row index */
  rowIndex: number;
  /** The icon to show */
  icon?: ReactNode;
  /** The array of actions for the dropdown */
  actions?: HireListTableAction[];
};

class HireListTableActions extends React.Component<ListTableActionsProps> {
  onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // prevent triggering onRowClick in the parent component (ListTableRow);
  };

  renderDropdown() {
    const { actions, icon, rowIndex } = this.props;

    const rowIcon = icon ? icon : <ActionsIcon />;

    return (
      <Dropdown icon={rowIcon} position={'bottom-end'} ariaLabel="Actions">
        {actions &&
          actions.map((action: HireListTableAction, index: number) => {
            let dropdownElem = [];
            dropdownElem.push(
              <Dropdown.Item
                ariaLabel={action.ariaLabel}
                onClick={action.onClick}
                value={rowIndex}
                isDisabled={action.isDisabled}
                key={'dropdown-item-' + index}
              >
                {action.icon} {action.label}
              </Dropdown.Item>
            );

            if (action.withDivider) {
              dropdownElem.push(
                <Dropdown.Divider
                  type="short"
                  key={'dropdown-divider-' + index}
                />
              );
            }

            return dropdownElem;
          })}
      </Dropdown>
    );
  }

  renderIcon() {
    const { icon } = this.props;
    return <div className="elmo-listtable__actions-icon">{icon}</div>;
  }

  render() {
    const { actions, icon } = this.props;

    if (!actions && !icon) {
      return null;
    }

    const content = actions ? this.renderDropdown() : this.renderIcon();

    return (
      <div
        className="elmo-listtable__row-actions"
        role="cell"
        onClick={this.onClick}
      >
        {content}
      </div>
    );
  }
}

export default HireListTableActions;
