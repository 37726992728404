import * as React from 'react';
import {
  Badge,
  Dropdown,
  BlockIcon,
  PersonAddIcon,
  Link
} from 'elmo-elements';
import {
  PlaylistAddIcon,
  ForumIcon,
  CardGiftcardIcon
} from 'elmo-elements-icons';
import {
  BadgeType
} from 'elmo-elements/Badge/type';
import trans from 'i18next';
import { DropdownProps } from 'elmo-elements/Dropdown';
import { CandidateCount } from '../CandidateCountDropdown/type';
import {
  KEY_TOTAL,
  KEY_OFFER,
  KEY_IN_PROGRESS,
  KEY_UNSUCCESSFUL,
  KEY_NEW,
  KEY_SHORTLIST,
  KEY_OFFER_ACCEPTED,
  TYPE_KEYS
} from './type';

export const getIconLabel = (key: TYPE_KEYS): string => {
  let label = '';
  switch (key) {
    case KEY_TOTAL: label = 'Total'; break;
    case KEY_OFFER: label = 'Offer Made'; break;
    case KEY_IN_PROGRESS: label = 'In Progress'; break;
    case KEY_UNSUCCESSFUL: label = 'Unsuccessful'; break;
    case KEY_NEW: label = 'New'; break;
    case KEY_SHORTLIST: label = 'Shortlisted'; break;
    case KEY_OFFER_ACCEPTED: label = 'Offer Accepted'; break;
  }

  return trans.t(label);
};

export const getIconBadgeType = (key: TYPE_KEYS): BadgeType => {
  let type:BadgeType = 'grey';

  switch (key) {
    case KEY_TOTAL: type = 'grey'; break;
    case KEY_OFFER: type = 'info'; break;
    case KEY_IN_PROGRESS: type = 'info'; break;
    case KEY_UNSUCCESSFUL: type = 'danger'; break;
    case KEY_NEW: type = 'warning'; break;
    case KEY_SHORTLIST: type = 'info'; break;
    case KEY_OFFER_ACCEPTED: type = 'success'; break;
  }

  return type;
};

export const getCountIcon = (key: TYPE_KEYS) => {
  let icon = <></>;

  switch (key) {
    case KEY_IN_PROGRESS: icon = <ForumIcon />; break;
    case KEY_UNSUCCESSFUL: icon = <BlockIcon />; break;
    case KEY_NEW: icon = <PersonAddIcon />; break;
    case KEY_SHORTLIST: icon = <PlaylistAddIcon />; break;
    case KEY_OFFER: icon = <CardGiftcardIcon />; break;
    case KEY_OFFER_ACCEPTED: icon = <CardGiftcardIcon />; break;
  }

  return icon;
};

export const getCountText = (data: CandidateCount, key: TYPE_KEYS) => {
  let count = data && data.hasOwnProperty(key) ? data[key] : 0;

  return (
    <span>
      <span>{count}</span> <span>{ getIconLabel(key) }</span>
    </span>
  );
};

export const getCountItem = (data: CandidateCount, key: TYPE_KEYS) => {
  const type:BadgeType = getIconBadgeType(key);

  return (
    <Dropdown.Item
        ariaLabel={key}
        value={key}
        onClick={()=> {}}
        isDisabled={false}
      >
      <Badge type={ type } className="mr-3" isCircular={true} icon={ getCountIcon(key) } /> { getCountText(data, key) }
    </Dropdown.Item>
  );
};

export default function CandidateCountDropdown(props: DropdownProps, data: CandidateCount, requisitionId: number) {
  const url:string = "/controlpanel/recruitment/requisition/job-app/" + requisitionId;
  const totalLink =
    <Link url={url}>
      { getCountText(data, KEY_TOTAL) }
    </Link>;

  return (
    <Dropdown
      {...props}
      text={ totalLink }
    >
      { getCountItem(data, KEY_NEW) }
      { getCountItem(data, KEY_SHORTLIST) }
      { getCountItem(data, KEY_IN_PROGRESS) }
      { getCountItem(data, KEY_OFFER) }
      { getCountItem(data, KEY_OFFER_ACCEPTED) }
      { getCountItem(data, KEY_UNSUCCESSFUL) }
    </Dropdown>
  );
}