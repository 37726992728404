import { RequisitionsTable } from './components/RequisitionsTable';
import { PaginatedList } from "../models/PaginatedList";
import { routePaths } from '../../../routes';
import React from 'react';
import { RequisitionListRequest } from '../models/RequisitionListRequest';
import { RouteComponentProps } from 'react-router';
import { RequisitionItem } from '../models/RequisitionItem';
import {
  Layout,
  Notification as Toaster,
  ErrorOutlineIcon,
  CheckCircleIcon,
  Loader,
} from 'elmo-elements';
import RequisitionListHeader from './components/RequisitionListHeader';
import {RequisitionFilterValues, RequisitionSavedFilterValues} from '../../../type/requisition/Filter';
import { Pagination } from '../../../lib/api/models/Pagination';
import { Sort } from '../../../lib/api/models/Sort';
import trans from 'i18next';
import { getRequisitionConfig, getSavedFilter } from '../../../lib/api/requisition/service';
import {Filter, RequisitionConfig} from '../../../type/requisition/Requisition';
import { Notification } from '../../../type/notification';
import RequisitionHelper from '../../../config/requisition';
import {getSavedFilters} from "../../../lib/api/filter/service";

export interface RequisitionListProps extends RouteComponentProps {
  myRecords: boolean,
  requisitions: PaginatedList<RequisitionItem>;
  filter: RequisitionFilterValues;
  pagination: Pagination;
  sort: Sort;
  fetchRequisitionList: (request: RequisitionListRequest) => void;
  dispatch: any;
}

interface RequisitionListState
{
  myRecords: boolean;
  keyword: string;
  isSelectEnabled: boolean;
  isDemoModalActionOpen: boolean;
  isFilterFormOpen: boolean;
  requisitionConfig: RequisitionConfig;
  savedFilters: RequisitionSavedFilterValues[];
  notification: Notification;
  isLoading: boolean;
}

export class RequisitionListPage extends React.Component<RequisitionListProps, RequisitionListState> {
  constructor(props: RequisitionListProps) {
    super(props);
    this.displayAddForm = this.displayAddForm.bind(this);

    this.state = {
      myRecords: this.props.myRecords,
      keyword: '',
      isSelectEnabled: false,
      isDemoModalActionOpen: false,
      isFilterFormOpen: false,
      notification: {
        show: false,
        message: '',
      },
      isLoading: true,
      requisitionConfig: {
        jobTypes: [],
        departments: [],
        filters: [],
      },
      savedFilters: [],
    };
  }

  public displayAddForm() {
    this.props.history.push(routePaths.requisitionAdd.path);
  }

  setSavedFilters = (savedFilters: RequisitionSavedFilterValues[]) => {
    this.setState({
      requisitionConfig: {
        ...this.state.requisitionConfig,
        filters: savedFilters.map(savedFilter => ({ id: savedFilter.id, title: savedFilter.name })),
      },
      savedFilters,
    });
  }

  loadRequisitionConfig = () => {
    const type = this.props.myRecords ? 'myRequisition' : 'openRequisition';

    return getRequisitionConfig(type)
      .then(async (response: RequisitionConfig) => {
        const requisitionConfig = {
          departments: response.departments,
          filters: response.filters,
          jobTypes: response.jobTypes
        };

        const savedFilters = await getSavedFilters(requisitionConfig.filters);
        this.setSavedFilters(savedFilters)

        this.setState({
          requisitionConfig,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.showNotification({
          show: true,
          message: `${trans.t('Unable to load requisition config.')}`,
          type: 'danger',
        });
      });
  }

  componentDidMount() {
    this.loadRequisitionConfig();

    // todo: this can be moved to a factory later.
    const requisitionListRequest: RequisitionListRequest = {
      pagination: {
        resultsPerPage: 10,
        pageNumber: 1
      },
      sort: {
        field: "createdDate",
        order: "desc"
      },
      filter: {
        myRecords: this.state.myRecords,
        keyword: this.state.keyword,
        statuses: [
          RequisitionHelper.status.codes.pending,
          RequisitionHelper.status.codes.open,
          RequisitionHelper.status.codes.rejected,
        ]
      }
    };

    this.props.fetchRequisitionList(requisitionListRequest);
  }

  handleToggleSelectAll = () => {
    this.setState({
      isSelectEnabled: !this.state.isSelectEnabled
    });
  };

  handleToggleDemoActionModal = () => {
    this.setState({
      isDemoModalActionOpen: !this.state.isDemoModalActionOpen
    });
  };

  handleToggleFilterFormModal = () => {
    this.setState({
      isFilterFormOpen: !this.state.isFilterFormOpen
    });
  }

  showNotification = (params: Notification) => {
    let notification = { ...this.state.notification };

    notification.show =
      params.hasOwnProperty('show') && params.show ? true : false;
    notification.message = params.hasOwnProperty('message')
      ? params.message
      : '';
    
    notification.type =
      (params.hasOwnProperty('type') && params.type !== null)
        ? params.type
        : 'info';

    this.setState({ notification });
  };

  hideNotification() {
    let notification = { ...this.state.notification };
    notification.show = false;
    notification.message = '';

    this.setState({ notification });
  }

  getNotification() {
    if (this.state.notification.show) {
      const icon =
        this.state.notification.type == 'success' ? (
          <CheckCircleIcon />
        ) : (
          <ErrorOutlineIcon />
        );
      return (
        <Toaster
          isActive={true}
          message={this.state.notification.message}
          type="dark"
          icon={icon}
          onClose={() => this.hideNotification()}
        />
      );
    }
  }

  public render() {
    if (this.state.isLoading) {
      return <Loader type="bullet" />;
    }

    return (
      <>
        <Layout.Header>
          <RequisitionListHeader
            title={this.props.myRecords ? trans.t('My Requisitions') : trans.t('Requisitions')}
            dispatch={this.props.dispatch}
            toggleDemoActionModal={this.handleToggleDemoActionModal}
          />
        </Layout.Header>
        {this.getNotification()}
        <Layout.Content>
          <RequisitionsTable
            {...this.props}
            displayAddForm={this.displayAddForm}
            isSelectAllEnabled={this.state.isSelectEnabled}
            requisitionConfig={this.state.requisitionConfig}
            loadRequisitionConfig={this.loadRequisitionConfig}
            savedFilters={this.state.savedFilters}
            showNotification={this.showNotification}
            myRecords={this.state.myRecords}
            />
        </Layout.Content>
      </>
    );
  }
}