import { LabelValue } from "../type/common/items";

const status = {
  codes: {
    pending: 0,
    open: 1,
    rejected: 4,
    finalised: 5,
    withdrawn: 6,
    onHold: 8,
  },
  getLabel: function(code: number): string {
    switch (code) {
      case this.codes.pending:
        return 'Pending';
      case this.codes.open:
        return 'Open';
      case this.codes.rejected:
        return 'Rejected';
      case this.codes.finalised:
        return 'Finalised';
      case this.codes.withdrawn:
        return 'Withdrawn';
      case this.codes.onHold:
        return 'On-hold';
      default:
        return '';
    }
  },
  getLabelAndValue: function(key: string): LabelValue {
    if (!this.codes.hasOwnProperty(key)) {
      throw new Error('Invalid requisition status key');
    }

    const code:number = this.codes[key];
    const result: LabelValue = {
        label: this.getLabel(code),
        value: code.toString()
      }

    return result;
  }
};

export default {
  requisitionActionStatus: {
    "PENDING": 1,
    "APPROVED": 2,
    "REJECTED": 3
  },
  status,
};