import { ReduxAction } from "../../../types";
import Actions from "../../../state/actions";
import { fetchTranslation } from "../../api";
import { TRANSLATIONS } from "../../api/config";
import { TranslationResponse } from "../models";
import { doInitActionSuccess } from "../../init/state/action";
import { SESSION_STORAGE } from "../../../config/initialisation";

export const fetchTranslations = (locale: string) => {
  return async (dispatch: any, getState: any) => {
    dispatch(initialiseTranslationStart(true));
    try {
      const defaultLocale = TRANSLATIONS.DEFAULT_LOCALE;
      const defaultTrans = await fetchTranslation<TranslationResponse>(defaultLocale);

      let translations = {
        [defaultTrans.locale]: {
          translations: { ...defaultTrans.data }
        }
      };

      if (locale && defaultLocale !== locale) {
        const otherTrans = await fetchTranslation<TranslationResponse>(locale);
        translations[otherTrans.locale] = {
          translations: { ...otherTrans.data }
        };
      }

      storeTranslations(translations);
      dispatch(doInitActionSuccess( { initActionCount: getState().initState.initActionCount-1 } ));
      dispatch(initialiseTranslationStart(false));
    } catch (err) {
      dispatch(fetchTranslationsFailure(err));
      console.log(err);
    }
  };
};

export const initialiseTranslationStart = (fetching: boolean): ReduxAction => {
  return {
    type: Actions.TRANSLATIONS_FETCH_START,
    payload: { fetching }
  }
}

export const storeTranslations = (trans: object) => {
  sessionStorage.setItem(SESSION_STORAGE.TRANSLATIONS, JSON.stringify(trans));
};

export const getTranslations = () =>  {
  return JSON.parse(sessionStorage.getItem(SESSION_STORAGE.TRANSLATIONS) || '{}');
};

export const fetchTranslationsFailure = (data: any): ReduxAction => {
  return {
    type: Actions.TRANSLATIONS_FETCH_FAILURE,
    payload: { data }
  };
};