import * as React from 'react';
import { JobApplicationItem } from '../../models/JobApplicationItem';
import { JobApplicationPaginatedList } from '../../models/JobApplicationPaginatedList';
import { JobApplicationListRequest } from '../../models/JobApplicationListRequest';
import { AccessAlarmIcon } from 'elmo-elements-icons';
import {
  Row,
  Col,
  Badge,
  Link,
  Avatar,
  WorkOutlineIcon,
  FileIcon,
  MoreHorizIcon,
  CallMadeIcon,
  MailOutlinedIcon,
  CheckIcon,
  HelpOutlineIcon,
  LocationOnOutlinedIcon,
  Dropdown,
} from 'elmo-elements';
import { FlagIcon, OutlinedFlagIcon } from '../../../../elements/customIcons';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { BadgeType } from 'elmo-elements/Badge/type';
import trans from 'i18next';

import HireListTable, { HireListTableAction, HireLayoutAwareListTable } from '../../../../elements/HireListTable';

const FLAG_TYPES = {
  'candidate-flag-none': <OutlinedFlagIcon />,
  'candidate-flag-flag': <FlagIcon />,
  'candidate-flag-earphone': <CallMadeIcon />,
  'candidate-flag-envelope': <MailOutlinedIcon />,
  'candidate-flag-ok': <CheckIcon />,
  'candidate-flag-pushpin': <LocationOnOutlinedIcon />,
  'candidate-flag-sign': <HelpOutlineIcon />,
};

const STATUS_TYPE = {
  Draft: 'info',
  'New Applications': 'warning',
  'Not Suitable': 'danger',
  Rejected: 'danger',
  'Rejected - Email': 'danger',
  'Rejected - No Email': 'danger',
  Unsuccessful: 'danger',
  Withdrawn: 'danger',
  Started: 'info',
  Shortlist: 'info',
  Interview: 'info',
  Offer: 'info',
  'Offer - Pending': 'warning',
  'Offer - Pending & Rejected': 'danger',
  'Offer - Rejected': 'danger',
  Accepted: 'success',
  Active: 'warning',
};

export interface JobApplicationsViewProps {
  jobApplications: JobApplicationPaginatedList<JobApplicationItem>;
  displayAddForm: () => void; 
  isSelectAllEnabled: boolean;
  dispatch: any;
}

export interface JobApplicationViewState {
  currentPage: number;
  pageSize: number;
  selectedIds: boolean[];
  selectedItems: JobApplicationItem[];
  isAllAvailableSelected: boolean;
}

export class JobApplications extends React.Component<
  JobApplicationsViewProps,
  JobApplicationViewState
> {
  constructor(props: JobApplicationsViewProps) {
    super(props);

    this.state = {
      currentPage:
        props.jobApplications.pagination &&
        props.jobApplications.pagination.pageNumber
          ? props.jobApplications.pagination.pageNumber
          : 1,
      pageSize:
        props.jobApplications.pagination &&
        props.jobApplications.pagination.resultsPerPage
          ? props.jobApplications.pagination.resultsPerPage
          : 1 | 10,
      selectedIds: [],
      selectedItems: [],
      isAllAvailableSelected: false,
    };
  }

  getCurrentPaginationRequest = (pageSize: number, currentPage: number) => {
    //@TODO: implement this
  };

  componentDidUpdate(prevProps: JobApplicationsViewProps) {
    //@TODO: implement this
  }

  handlePageSizeChange = (limit: number) => {
    //@TODO: implement this
  };

  handlePageChange = (page: number) => {
    //@TODO: implement this
  };

  private renderStatus = (data: JobApplicationItem, index: number) => {
    const status = data['status'];
    let type: BadgeType = 'grey';

    if (STATUS_TYPE[status]) {
      type = STATUS_TYPE[status];
    }

    return (
      <div data-testid={`row-${index}-status`}>
        <Badge type={type}>{status}</Badge>
      </div>
    );
  };

  //@TODO: implement preview pop out panel on the right
  private renderPreview = (data: JobApplicationItem, index: number) => {    
    const previewUrl = `#`;

    return (
      <div data-testid={`row-${index}-status`}>
        <Link url={previewUrl}>Preview</Link>
      </div>
    );
  };

  private renderApplicationDate = (data: JobApplicationItem, index: number) => {
    let date = data['submitTime'] ? new Date(data['submitTime']) : '';


    // @ts-ignore
    return (
      date && (
        <div data-testid={`row-${index}-createdDate`}>
          <p>
            {moment(date).format('D MMM YYYY')}
          </p>
          <p>Application Source: {`${data['source']}`}</p>
        </div>
      )
    );
  };

  private renderScore = (data: JobApplicationItem, index: number) => {
    let score = data['panelScore'] ? data['panelScore'] : '';
    const scoreRender = score ? score : <span>No score yet</span>;

    let rating = data['rating'] ? data['rating'] : '';

    return (
      <>
        <div>
          <span data-testid={`row-${index}-candidateTitle`}>{scoreRender}</span>
        </div>
        <div>
          <sub />
        </div>
      </>
    );
  };

  private renderFlag = (data: JobApplicationItem, index: number) => {
    const flagName = data['flag'] ? data['flag'] : '';
    let flagComponent = <OutlinedFlagIcon />;
    if (FLAG_TYPES[flagName]) {
      flagComponent = FLAG_TYPES[flagName];
    }

    return (
        <Dropdown ariaLabel="More actions" icon={flagComponent} className='flag-center'>
          <Dropdown.Item
            ariaLabel="Option 1"
            value={1}
            onClick={() => {}}
            isDisabled={true}
          >
            Option 1
          </Dropdown.Item>
          <Dropdown.Item ariaLabel="Option 2" value={2} onClick={() => {}}>
            Option 2
          </Dropdown.Item>
          <Dropdown.Item ariaLabel="Option 3" value={3} onClick={() => {}}>
            Option 3
          </Dropdown.Item>
        </Dropdown>
    );
  };

  private renderActions = (
    data: JobApplicationItem,
    index: number
  ): HireListTableAction[] | [] => {
    return [];
  };

  private renderMoreActions = () => {
    return (
      <>
        <Dropdown ariaLabel="More actions" icon={<MoreHorizIcon />} className='flag-center'>
          <Dropdown.Item
            ariaLabel="Option 1"
            value={1}
            onClick={() => {}}
            isDisabled={true}
          >
            Option 1
          </Dropdown.Item>
          <Dropdown.Item ariaLabel="Option 2" value={2} onClick={() => {}}>
            Option 2
          </Dropdown.Item>
          <Dropdown.Item ariaLabel="Option 3" value={3} onClick={() => {}}>
            Option 3
          </Dropdown.Item>
        </Dropdown>
      </>
    );
  };

  getCurrentSortDirection(): string {
    return this.props.jobApplications.sort &&
      this.props.jobApplications.sort.order
      ? this.props.jobApplications.sort.order
      : 'asc';
  }

  private renderName = (data: JobApplicationItem, index: number) => {
    if (data['candidate']) {
      const candidate = data['candidate'];
      const candidateViewProfileLink = '/controlpanel/careers/job-application/view-details/'+data['id'];
      const candidateFirstName = candidate['firstName']
        ? candidate['firstName']
        : '';
      const candidateLastName = candidate['lastName']
        ? candidate['lastName']
        : '';
      const resumeFile =
          data['resumeFile']
          ? data['resumeFile']
          : '';
      const coverLetterFile =
          data['coverLetterFile']
          ? data['coverLetterFile']
          : '';

      return (
        <>
          <div>
            <strong data-testid={`row-${index}-candidateTitle`}>
              <Avatar label={`${candidateFirstName} ${candidateLastName}`} />
              <Link url={`${candidateViewProfileLink}`}>
                {candidateFirstName} {candidateLastName}
              </Link>
            </strong>
          </div>
          <div>
            <sub>
              {resumeFile && (
                <span>
                  <Link url={resumeFile}>
                    <WorkOutlineIcon />
                  </Link>{' '}
                  Resume
                </span>
              )}{' '}
              {coverLetterFile && (
                <span>
                  <Link url={coverLetterFile}>
                    <FileIcon />
                  </Link>{' '}
                  Cover letter
                </span>
              )}
            </sub>
          </div>
        </>
      );
    }

    return '';
  };

  onSelectAllAvailableToggle = (isSelect: boolean) => {
    let selectedIds = this.state.selectedIds;
    let selected = this.state.selectedItems;

    if (!isSelect) {
      selectedIds = [];
    }

    this.setState({
      isAllAvailableSelected: isSelect,
      selectedIds: selectedIds,
      selectedItems: selected,
    });
  };

  isItemSelected = (data: any) => {
    return (
      !!this.state.selectedIds[data['id']] || this.state.isAllAvailableSelected
    );
  };

  onSelectAllVisibleToggle = (isSelect: boolean) => {
    const selectedIds = this.state.selectedIds.slice();
    let selectedItems = this.state.selectedItems.slice();

    this.props.jobApplications &&
      this.props.jobApplications.data &&
      this.props.jobApplications.data.forEach((item: any) => {
        const currentlySelected = !!selectedIds[item['id']];
        selectedIds[item['id']] = isSelect;

        if (currentlySelected) {
          // remove the item from the array
          const foundIndex = selectedItems.findIndex((i: any) => {
            return i['id'] === item['id'];
          });
          selectedItems.splice(foundIndex, 1);
        } else {
          // add the item
          selectedItems.push(item);
        }
      });

    this.setState({
      selectedIds: selectedIds,
      selectedItems: selectedItems,
    });
  };

  onItemToggle = (data: any, isSelected: boolean) => {
    const selectedIds = this.state.selectedIds.slice();
    const currentlySelected = !!selectedIds[data['id']];
    selectedIds[data['id']] = isSelected;

    const selectedItems = this.state.selectedItems.slice();
    let isAllAvailableSelected = this.state.isAllAvailableSelected;

    if (currentlySelected) {
      isAllAvailableSelected = false;
      // remove the item from the array
      const foundIndex = selectedItems.findIndex((i: any) => {
        return i['id'] === data['id'];
      });
      selectedItems.splice(foundIndex, 1);
    } else {
      // add the item
      selectedItems.push(data);
    }

    this.setState({
      selectedIds: selectedIds,
      selectedItems: selectedItems,
      isAllAvailableSelected: isAllAvailableSelected,
    });
  };

  render() {
    if (this.props.jobApplications.state.isFetching) {
      return (
        <p>
          <Trans>Loading</Trans>...
        </p>
      );
    }

    if (
      !this.props.jobApplications.data ||
      this.props.jobApplications.data.length === 0
    ) {
      if (
        this.props.jobApplications.searchOptions &&
        this.props.jobApplications.searchOptions.searchKey
      ) {
        return (
          <Row>
            <Col>
              <Trans>There are no results matching this search.</Trans>
            </Col>
          </Row>
        );
      }

      return (
        <Row>
          <Col>
            <h4>
              <Trans>Need a Superhero?</Trans>
            </h4>
            <h4>
              <Trans>
                Tap the + button at the top of the screen to add a job
                application.
              </Trans>
            </h4>
          </Col>
        </Row>
      );
    }

    const jobApplications: JobApplicationItem[] = this.props.jobApplications
      .data;
    const ariaLabel = trans.t('List of Job Applications');

    return (
      <HireLayoutAwareListTable
        data={jobApplications}
        onItemToggle={this.onItemToggle}
        onSelectAllToggle={this.onSelectAllVisibleToggle}
        countSelectedItems={this.state.selectedItems.length}
        isItemDisabled={(data: any) => {
          // TODO: figure out the criteria of disabled rows and apply it here
          return false;
        }}
        isItemSelected={this.isItemSelected}
        ariaLabel={ariaLabel}
        actions={this.renderActions}
        sortDirection={this.getCurrentSortDirection()}
        onSelectAllAvailableToggle={this.onSelectAllAvailableToggle}
        isAllAvailableSelected={this.state.isAllAvailableSelected}
        countSelectable={
          this.props.jobApplications.pagination &&
          this.props.jobApplications.pagination.totalResults
            ? this.props.jobApplications.pagination.totalResults
            : 0
        }
        hasLayout={true}
        hasBulkActionsButton={true}
      >
        <HireListTable.Column
          label={trans.t('Name')}
          property={'candidate'}
          sortable={true}
          render={this.renderName}
        />
        <HireListTable.Column
          label={trans.t('Status')}
          render={this.renderStatus}
          sortable={true}
        />
        <HireListTable.Column
          label={trans.t('Application Date')}
          render={this.renderApplicationDate}
          sortable={true}
        />
        <HireListTable.Column
          label={trans.t('Score')}
          render={this.renderScore}
          sortable={true}
        />
        <HireListTable.Column
          label={trans.t('Flag')}
          render={this.renderFlag}
          sortable={true}
        />
        <HireListTable.Column
          label={''}
          render={this.renderPreview}
          sortable={false}
        />
        <HireListTable.Column
          label={''}
          render={this.renderMoreActions}
          sortable={false}
        />
      </HireLayoutAwareListTable>
    );
  }
}
