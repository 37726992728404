import { FormikProps } from 'formik';

export interface ObjStringValueMap {
  [key: string]: string
}

export interface Obj {
  [key: string]: any,
}

export interface ReduxAction {
  type: string,
  payload: Obj
}

export type boleanOrObject = boolean | {} | Obj; // as we pass object to immutable helper to update values
export type stringOrObject = string | {} | Obj;

export interface FormProps<T> extends FormikProps<T> {
  [key: string]: any
}

export enum MessageTypes {
  ERROR = "danger",
  SUCCESS = "success",
  WARNING = "warning",
}

export interface GlobalMessage {
  text: string,
  type: MessageTypes
}

export interface CommonAppState {
  isLoading: boolean,
  message: GlobalMessage
}

export interface PaginationRequest {
  resultsPerPage: number,
  pageNumber: number,
}

export interface ModalWindow {
  id: string,
  type: string,
  title: string,
  content: any,
  cancelLabel?: string,
  confirmLabel?: string,
  onConfirm?: Function,
}

export interface RouteConfigItem {
  path: string,
  exact: boolean,
}

export interface RouteConfig {
  home: RouteConfigItem,
  requisitionAdd: RouteConfigItem,
  requisitionList: RouteConfigItem,
  openRequisitionList: RouteConfigItem,
  talentSearch: RouteConfigItem,
  interviews: RouteConfigItem,
  panelReview: RouteConfigItem,
  approval: RouteConfigItem,
  jobApplicationList: RouteConfigItem,
}

export const CRUD_ACTION_NAMES = {
  create: 'create',
  read: 'read',
  readSingle: 'readSingle',
  update: 'update',
  delete: 'delete',
};

export const CrudLoadingAttributes = {
  [CRUD_ACTION_NAMES.read] : 'isFetching',
  [CRUD_ACTION_NAMES.create]: 'isCreating',
  [CRUD_ACTION_NAMES.update]: 'isUpdating',
  [CRUD_ACTION_NAMES.delete]: 'isDeleting',
};
