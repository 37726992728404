import * as React from 'react';

export const OutlinedFlagIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path d="M14 6l-1-2H5v17h2v-7h5l1 2h7V6h-6zm4 8h-4l-1-2H7V6h5l1 2h5v6z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </>
  );
};
