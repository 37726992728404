import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS } from '../api/config';

export default (resources: any, locale?: string) => {

  locale = !locale ? TRANSLATIONS.DEFAULT_LOCALE : locale;

  i18n.use(LanguageDetector).init({
    // we init with resources
    resources: resources,
    fallbackLng: TRANSLATIONS.DEFAULT_LOCALE,
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    },

    react: {
      wait: true
    }
  });

  i18n.changeLanguage(locale);

  return i18n;
};
