import { ReduxAction } from "../../../types";
import Actions from "../../../state/actions";
import { sendRequest, generateApiUrl } from "../../api";
import { ConfigurationResponse } from "../models";
import { doInitActionSuccess } from "../../init/state/action";
import { SESSION_STORAGE } from "../../../config/initialisation";

export const fetchConfiguration = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(initialiseConfigurationStart(true));
    try {
      const config = await sendRequest<ConfigurationResponse>(
        generateApiUrl('CONFIGURATION', 'v1'),
        'GET',
      );

      storeConfiguration(config);
      dispatch(doInitActionSuccess( { initActionCount: getState().initState.initActionCount-1 } ));
      dispatch(initialiseConfigurationStart(false));
    } catch (err) {
      dispatch(fetchConfigurationFailure(err));
      console.log(err);
    }
  };
};

export const initialiseConfigurationStart = (fetching: boolean): ReduxAction => {
  return {
    type: Actions.CONFIGURATION_FETCH_START,
    payload: { fetching }
  }
}

export const storeConfiguration = (config: object) => {
  sessionStorage.setItem(SESSION_STORAGE.CONFIGURATION, JSON.stringify(config));
};

export const getConfiguration = () =>  {
  return JSON.parse(sessionStorage.getItem(SESSION_STORAGE.CONFIGURATION) || '{}');
};

export const fetchConfigurationFailure = (data: any): ReduxAction => {
  return {
    type: Actions.CONFIGURATION_FETCH_FAILURE,
    payload: { data }
  };
};