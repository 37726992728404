import RequisitionConfig from "../../config/requisition";
import {getRequisition} from '../../lib/api/requisition/service';
import {
  ExtractionRequisitionApprovalMessage,
  ExtractionRequisitionRequisitionAction
} from '../../lib/api/requisition/type';
import {
  unixTimestampToDate,
  validateUnixTimestamp
} from '../../lib/helper/date';
import {ApprovalStatus} from "./models/ApprovalStatus";

export async function fetchApprovalStatusesForRequisition(requisitionId: number) : Promise<ApprovalStatus[]>
{
  const approvalStatuses = [];

  const requisition = await getRequisition(requisitionId);

  if (requisition && requisition.hasOwnProperty('requisitionActions')) {
    const requisitionActions: ExtractionRequisitionRequisitionAction[] = requisition['requisitionActions'];

    for (const requisitionAction of requisitionActions) {
      if (requisitionAction.deleted) { // Backend always sends non-deleted requisition action, but let's play safe.
        continue;
      }

      let approvalMessage = requisition['approvalMessages'][requisitionAction.step - 1] || null;

      approvalStatuses.push({
        id: requisitionAction.id,
        approver: getApproverForApprovalStatus(requisitionAction, approvalMessage),
        status: getStatusForApprovalStatus(requisitionAction),
        date: getDateForApprovalStatus(requisitionAction, approvalMessage),
        message: getMessageForApprovalStatus(requisitionAction, approvalMessage),
      });
    }
  }

  return approvalStatuses;
}

function getApproverForApprovalStatus(
  requisitionAction: ExtractionRequisitionRequisitionAction,
  approvalMessage: ExtractionRequisitionApprovalMessage|null
) : string|null
{
  let approver = null;

  if (requisitionAction.hasOwnProperty('actionMessage')) {
    if (requisitionAction.actionMessage
      && requisitionAction.actionMessage.hasOwnProperty('approver')
      && requisitionAction.actionMessage.approver
      && requisitionAction.actionMessage.approver.hasOwnProperty('firstName')
      && requisitionAction.actionMessage.approver.hasOwnProperty('lastName')) {
      approver = `${requisitionAction.actionMessage.approver.firstName} ${requisitionAction.actionMessage.approver.lastName}`;
    } else if (requisitionAction.hasOwnProperty('approver') && requisitionAction.approver) {
      approver = requisitionAction.approver;
    }
  } else {
    if (approvalMessage
      && approvalMessage.hasOwnProperty('approver')
      && approvalMessage.approver
      && approvalMessage.approver.hasOwnProperty('firstName')
      && approvalMessage.approver.hasOwnProperty('lastName')) {
      approver = `${approvalMessage.approver.firstName} ${approvalMessage.approver.lastName}`;
    } else if (requisitionAction.hasOwnProperty('approver') && requisitionAction.approver) {
      approver = requisitionAction.approver;
    }
  }

  return approver;
}

function getStatusForApprovalStatus(requisitionAction: ExtractionRequisitionRequisitionAction) : number
{
  return requisitionAction.status;
}

function getDateForApprovalStatus(
  requisitionAction: ExtractionRequisitionRequisitionAction,
  approvalMessage: ExtractionRequisitionApprovalMessage|null) : Date|null
{
  let date = null;

  if (requisitionAction.hasOwnProperty('actionMessage')
    && requisitionAction.actionMessage
    && requisitionAction.actionMessage.hasOwnProperty('updatedTime')
    && requisitionAction.actionMessage.updatedTime
    && validateUnixTimestamp(requisitionAction.actionMessage.updatedTime)) {
    date = unixTimestampToDate(requisitionAction.actionMessage.updatedTime);
  } else if (approvalMessage
    && approvalMessage.hasOwnProperty('updatedTime')
    && approvalMessage.updatedTime
    && validateUnixTimestamp(approvalMessage.updatedTime)) {
    date = unixTimestampToDate(approvalMessage.updatedTime);
  } else if (requisitionAction.hasOwnProperty('actionTime')
    && requisitionAction.actionTime
    && validateUnixTimestamp(requisitionAction.actionTime)) {
    date = unixTimestampToDate(requisitionAction.actionTime);
  }

  return date;
}

function getMessageForApprovalStatus(
  requisitionAction: ExtractionRequisitionRequisitionAction,
  approvalMessage: ExtractionRequisitionApprovalMessage|null) : string|null
{
  let message = null;

  if (requisitionAction.hasOwnProperty('actionMessage')
    && requisitionAction.actionMessage
    && requisitionAction.actionMessage.hasOwnProperty('message')
    && (requisitionAction.status === RequisitionConfig.requisitionActionStatus.APPROVED
      || requisitionAction.status === RequisitionConfig.requisitionActionStatus.REJECTED)) {
    message = requisitionAction.actionMessage.message;
  } else if (approvalMessage
    && approvalMessage.hasOwnProperty('message')
    && (requisitionAction.status === RequisitionConfig.requisitionActionStatus.APPROVED
        || requisitionAction.status === RequisitionConfig.requisitionActionStatus.REJECTED)) {
    message = approvalMessage.message;
  }

  return message;
}
