import React, { ReactElement } from 'react';
import HireLayoutContext from './HireLayoutContext';
import { Heading } from 'elmo-elements';
import { Button } from 'elmo-elements';
import { CloseIcon } from 'elmo-elements';

export type HireLayoutSidePanelProps = {
  children: ReactElement<any>;
  title: string;
};

function HireLayoutSidePanel({ children, title }: HireLayoutSidePanelProps) {
  return (
    <HireLayoutContext.Consumer>
      {({ isSidePanelOpen, toggleSidePanelOpen }: any) => {
        return (
          isSidePanelOpen && (
            <>
              <div
                className="elmo-layout__side-panel-bg"
                onClick={toggleSidePanelOpen}
              />
              <div className="elmo-layout__side-panel">
                <div className="elmo-layout__side-panel__header">
                  <Heading htmlTag="h2">{title}</Heading>
                  <Button
                    isText={true}
                    icon={<CloseIcon />}
                    onClick={toggleSidePanelOpen}
                  />
                </div>
                <div className="elmo-layout__side-panel__body">
                  {React.cloneElement(children, {
                    isSidePanelOpen,
                    toggleSidePanelOpen,
                  })}
                </div>
              </div>
            </>
          )
        );
      }}
    </HireLayoutContext.Consumer>
  );
}

export default HireLayoutSidePanel;
