import immutable from 'immutability-helper';
import { createReducer } from '../../lib/helper/createReducer';
import { Actions } from '../actions';
import { DEFAULT_STATE } from '../defaultState';
import { ReduxAction } from '../../types';
import { State } from '../reducer';

export const appNewMessage = (state: State, action: ReduxAction) => {
  return immutable(state.sharedAppState, {
    message: { $set: action.payload.message }
  });
};

export default createReducer(DEFAULT_STATE.app, {
  [Actions.APP_NEW_MESSAGE]: appNewMessage
});