import immutable from 'immutability-helper';
import { createReducer } from '../../../lib/helper/createReducer'
import { Actions } from '../../../state/actions';
import { DEFAULT_STATE } from '../../../state/defaultState';
import { ReduxAction } from '../../../types';

export const fetchAuthenticationSuccess = (state: any, action: ReduxAction) => {
  return immutable(state, { $merge: action.payload });
};

export default createReducer(DEFAULT_STATE.user, {
  [Actions.AUTHENTICATION_SUCCESS]: fetchAuthenticationSuccess
});