import React, { ReactNode } from 'react';

export type HireLayoutMenuProps = {
  children: ReactNode;
};

function HireLayoutMenu({ children }: HireLayoutMenuProps) {
  return (
    <div
      className="elmo-layout__main-menu"
      role="navigation"
      aria-label="main menu"
    >
      {children}
    </div>
  );
}

export default HireLayoutMenu;
