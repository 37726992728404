import * as React from 'react';
import { Provider } from 'react-redux';
import store from './state/store';

export default (props: any) => {
  return (
    <Provider store={store}>
      {props.children}
    </Provider>
  );
};