import React from 'react';
import {
  CheckBoxOutlinedIcon as CheckBoxIcon,
  TransferWithinAStationIcon as TransferIcon,
  SaveAltOutlinedIcon,
} from 'elmo-elements-icons';
import { 
  Header, 
  Search, 
  BulkAction,
  SearchIcon,
  DoneAllIcon,
} from 'elmo-elements';
import trans from 'i18next';
import { JobApplicationItem } from '../../models/JobApplicationItem';
import { PaginatedList } from '../../../../lib/api/models/PaginatedList';

type HeaderProps = {
  jobApplications: PaginatedList<JobApplicationItem>;  
  onToggleSelectAll: () => void;
  dispatch: any;
  requisitionId: string;
  requisitionName: string;
};

type HeaderState = {
  isBulkActionOpen: boolean;
  isSearchOpen: boolean;
  search: string;
  requisitionName: string;
  requisitionId: string;
};

const HeaderTabLinks = {
  overview: 'OVERVIEW',
  jobAds: 'JOB ADS',
  candidateList: 'CANDIDATE LIST',
  interviews: 'INTERVIEWS',
  panelReviews: 'PANEL REVIEWS',
  offers: 'OFFERS',
  costs: 'COSTS'
}

class JobApplicationListHeader extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      isBulkActionOpen: false,
      isSearchOpen: false,
      search: '',    
      requisitionId: props.requisitionId,
      requisitionName: `${props.requisitionName} (${props.requisitionId})`,
    };
  }

  getHeaderButtons = () => [
    {
      icon: <SearchIcon />,
      label: trans.t('Search'),
      onClick: this.openSearch
    },
    {
      icon: <SaveAltOutlinedIcon />,
      label: trans.t('Export List'),
      onClick: function() {
        //@TODO: implement this
      }
    },
    {
      icon: <DoneAllIcon />,
      label: trans.t('Select All'),
      onClick: this.openBulkAction
    }
  ];

  closeBulkAction = () => {
    //@TODO: implement this
  };

  openBulkAction = () => {
    //@TODO: implement this
  };

  closeSearch = () => {
    //@TODO: implement this
  };

  openSearch = () => {
    //@TODO: implement this
  };

  handleSearchKeyDown = () => {
    //@TODO: implement this
  };

  headerTabLinks = () => [
    {
      label: trans.t(HeaderTabLinks.overview),
      onClick: function() {
        //@TODO: implement this
      },
      active: false
    },
    {
      label: trans.t(HeaderTabLinks.jobAds),
      onClick: function() {
        //@TODO: implement this
      },
      active: false
    },
    {
      label: trans.t(HeaderTabLinks.candidateList),
      onClick: function() {
        //@TODO: implement this
      },
      active: true
    },
    {
      label: trans.t(HeaderTabLinks.interviews),
      onClick: function() {
        //@TODO: implement this
      },
      active: false
    },
    {
      label: trans.t(HeaderTabLinks.panelReviews),
      onClick: function() {
        //@TODO: implement this
      },
      active: false
    },
    {
      label: trans.t(HeaderTabLinks.offers),
      onClick: function() {
        //@TODO: implement this
      },
      active: false
    },
    {
      label: trans.t(HeaderTabLinks.costs),
      onClick: function() {
        //@TODO: implement this
      },
      active: false
    },
  ];

  renderRequisitionTitle = () => {
    if (this.props.requisitionName) {
      return trans.t(`${this.props.requisitionName} (${this.props.requisitionId})`);
    }
    
    return this.props.requisitionId;
  }

  render() {
    return (
      <>
        <Header
          buttons={this.getHeaderButtons()}
          // primaryButton={this.primaryButton()} //@TODO: add job application button
          title={this.renderRequisitionTitle()}
          titleAddon={<></>}
          isBulkActionOpen={this.state.isBulkActionOpen}
          isSearchOpen={this.state.isSearchOpen}
          tabs={this.headerTabLinks()}
        >
          <Search
            isVisible={this.state.isSearchOpen}
            onClose={this.closeSearch}            
            onKeyPress={() => {}}            
            id={'headerSearchBar'}
          />
          <BulkAction
            actions={[]}
            isVisible={this.state.isBulkActionOpen}
            onClose={this.closeBulkAction}
            isDisabled={true}
          />
        </Header>
      </>
    );
  }
}

export default JobApplicationListHeader;
