import React from 'react';
import { Select } from 'elmo-elements';
import './InputTags.css';
import { InputTagsProp } from './type';
import { SelectPropsOption } from 'elmo-elements/Select';
import trans from 'i18next';

export class InputTags extends React.Component<
  InputTagsProp
> {
  handleOnChange = (selectedOptions: SelectPropsOption[]) => {
    if (this.props.onChange) {
      this.props.onChange(selectedOptions);
    }
  };

  render()
  {
    const placeholder = this.props.placeholder ? trans.t(this.props.placeholder) : '';    
    const ariaLabel = this.props.ariaLabel || '';
    const ariaLabelledby = this.props.ariaLabelledby || '';
    const id = this.props.id ? `inputTags-${this.props.id}` : '';
    const className = this.props.className || '';

    return (
      <Select
        id={id}
        isMulti={true}
        isCreatable={true}
        className={`inputTags${className}`}
        onChange={this.handleOnChange}
        placeholder={placeholder}
        ariaLabel={ariaLabel}
        ariaLabelledby={ariaLabelledby}
        value={this.props.defaultValues}
      />
    );
  }
}

export default InputTags;