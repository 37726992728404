import { Col } from 'elmo-elements'
import React from 'react';
import HireListTableSortIcon from './HireListTableSortIcon';
import { ClassNamesForCol, ResponsiveSize, Sort } from '../type';
import { getClass } from '../../_lib/helper';
import { BulkActionIcon as SelectAllIcon } from 'elmo-elements';
import { withListTableContext } from '../HireListTableContextConsumer';
import { SORT_ASC, SORT_DESC } from '../../_lib/const';

export type HireListTableThProps = {
  /** The index of the column */
  index: number;
  /** The content */
  label: string;
  /** True if the column is sortable */
  sortable: boolean;
  /** The current sort direction and column */
  sort?: Sort;
  /** Class names for each column */
  classNamesForCols?: ClassNamesForCol[];
  /** Colspan for each column */
  colSpanForCols?: ResponsiveSize[];
  /** @ignore Comes from HireListTable Context. */
  hasBulkActionsButton?: boolean;
  /** @ignore Comes from HireLayout Context. */
  toggleBulkActionOpen?: any;
  /** @ignore Comes from HireLayout Context. */
  isBulkActionOpen?: boolean;
  onSort?: (column: number, direction: string) => void;
  /** @ignore Comes from HireListTable Context. */
  setContextState?: (state: any) => void;
  /** Set to True if this th is placeholder, to ensure WCAG compliance */
  isPlaceholder?: boolean;
};

class HireListTableTh extends React.Component<HireListTableThProps> {
  handleClick = (column: number) => () => {
    const {
      sortable,
      sort,
      setContextState,
      onSort,
      isPlaceholder,
    } = this.props;

    if (isPlaceholder) {
      return;
    }

    if (!sortable || !sort || !setContextState) {
      return;
    }

    const sortDirection =
      column !== sort.column || !sort.direction || sort.direction === SORT_DESC
        ? SORT_ASC
        : SORT_DESC;

    if (onSort) {
      onSort(column, sortDirection);
    }

    setContextState({
      sort: {
        column: column,
        direction: sortDirection,
      },
    });
  };

  handleKeyPress = (column: number) => (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.handleClick(column)();
    }
    return false;
  };

  render() {
    const {
      index,
      label,
      sortable,
      sort,
      classNamesForCols,
      colSpanForCols,
      hasBulkActionsButton,
      toggleBulkActionOpen,
      isBulkActionOpen,
      isPlaceholder,
    } = this.props;

    const classNamesForIndex = classNamesForCols
      ? classNamesForCols[index]
      : '';
    const colSpanForIndex = colSpanForCols ? colSpanForCols[index] : {};

    const headerClassNames: string = getClass(
      '',
      classNamesForIndex ? classNamesForIndex.header : ''
    );

    const headerColClassNames: string = getClass(
      'elmo-listtable__header-content',
      sortable ? 'elmo-listtable__header-content__sortable' : ''
    );

    return (
      <Col className={headerClassNames} {...colSpanForIndex}>
        <div className="elmo-listtable__header-col" role="columnheader">
          {hasBulkActionsButton && index === 0 && !isBulkActionOpen && (
            <button
              type="button"
              onClick={toggleBulkActionOpen}
              className="elmo-listtable__bulkactions-btn"
              aria-label="Select all"
            >
              <SelectAllIcon />
            </button>
          )}
          <div
            data-testid={`elmo-listtable-header-${index}`}
            className={headerColClassNames}
            {...sortable && {
              onClick: this.handleClick(index),
              tabIndex: 0,
              onKeyPress: this.handleKeyPress(index),
            }}
          >
            {label}
            {sortable && sort && sort.column === index && (
              <HireListTableSortIcon direction={sort.direction} />
            )}
          </div>
        </div>
      </Col>
    );
  }
}

export { HireListTableTh as HireListTableThNoContext };
export default withListTableContext(HireListTableTh);
