import {
  RECRUITMENT_API_PATH,
  API_RESOURCES,
  API_ENDPOINTS,
} from '../../../../lib/api/config';
import { JobApplicationListRequest } from '../../../jobApplications/models/JobApplicationListRequest';
import { generateCrudAction } from '../../../../lib/api';
import { getClientApiHost } from '../../../../lib/clientUrl/state/action';

export const PENDING = 'JOB_APPLICATION_ACTION_PENDING';
export const FULFILLED = 'JOB_APPLICATION_ACTION_FULFILLED';
export const ERROR = 'JOB_APPLICATION_ACTION_ERROR';
export const RESET = 'JOB_APPLICATION_ACTION_RESET';

export const getClientApiHostUrl = () =>
  `${getClientApiHost().url}/${RECRUITMENT_API_PATH}`;
export const getJobApplicationListEndpoint = (
  resource: string,
  requisitionId: string
) =>
  `${getClientApiHostUrl()}/${
    process.env.REACT_APP_API_DATA_VERSION
      ? process.env.REACT_APP_API_DATA_VERSION
      : 'v1'
  }/${resource}/${API_ENDPOINTS.JOB_APPLICATION_LIST}/${requisitionId}`;

export const { fetchRecords } = generateCrudAction({
  pendingActionType: PENDING,
  fulfilledActionType: FULFILLED,
  errorActionType: ERROR,
  resetActionType: RESET,
});

export const fetchJobApplicationList = (
  requisitionId: string,
  request?: JobApplicationListRequest
) => {
  return fetchRecords(
    getJobApplicationListEndpoint(API_RESOURCES.REQUISITION, requisitionId),
    request,
    'post'
  );
};
