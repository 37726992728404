import React from 'react';
import { Trans } from 'react-i18next';
import trans from 'i18next';
import { Modal, Button, Paragraph, FormItem, TextArea } from 'elmo-elements';
import { ActionModalProps } from './types';
import { sendRequest, getExistingApiUrl } from '../../../../../lib/api';

type withdrawRequisitionActionState = {
  isSubmitButtonDisabled: boolean;
  withdrawReason: string;
};

const withdrawReasonMaxLength = 255;

export class WithdrawRequisitionModal extends React.Component<
  ActionModalProps,
  withdrawRequisitionActionState
> {
  constructor(props: ActionModalProps) {
    super(props);

    this.state = {
      isSubmitButtonDisabled: true,
      withdrawReason: '',
    };
  }

  async handleSubmit(ids: Array<number>) {
    const payload = {
      ids: ids,
      reason: this.state.withdrawReason,
    };

    await sendRequest(`${getExistingApiUrl('WITHDRAW')}`, 'post', payload);
    this.props.handlePostSubmit();
    this.props.toggleModal();
  }

  toggleSubmitButtonStatus = (charLength: number) => {
    this.setState({
      isSubmitButtonDisabled:
        charLength > 0 && charLength <= withdrawReasonMaxLength ? false : true,
    });
  };

  validateReason(reason: string) {
    this.toggleSubmitButtonStatus(reason.length);
  }

  handleReasonChange(event: any) {
    this.validateReason(event.target.value);
    if (!this.state.isSubmitButtonDisabled) {
      this.setState({
        withdrawReason: event.target.value,
      });
    }
  }

  render() {
    let primaryButton;
    const requisitionId =
      this.props.data && this.props.data.hasOwnProperty('id')
        ? this.props.data.id
        : '';

    if (requisitionId) {
      primaryButton = (
        <Button
          type={'primary'}
          onClick={() => this.handleSubmit([requisitionId])}
          isDisabled={this.state.isSubmitButtonDisabled}
        >
          <Trans>Yes, withdraw</Trans>
        </Button>
      );
    }

    const ariaLabel = `${trans.t('Withdraw Requisition')}`;
    const closeLabel = `${trans.t('Close')}`;

    return (
      <Modal
        id="withdrawRequisitionModal"
        type="medium"
        isOpened={this.props.isModalOpen}
        closeModal={this.props.toggleModal}
        title={trans.t('Withdraw requisition(s)?')}
        closeLabel={closeLabel}
        ariaLabel={ariaLabel}
        primaryButton={primaryButton}
        hasCloseFooterButton={true}
      >
        <Paragraph>
          Adverts associated with the requisition will be disabled.
        </Paragraph>
        <Paragraph>Actions on candidates will be disabled.</Paragraph>
        <br />

        <FormItem
          label={`${trans.t('Reason for withdrawing the requisition(s)?')}`}
        >
          <TextArea
            id="inputWithdrawRequisitionReason"
            name="reason"
            onChange={(event) => this.handleReasonChange(event)}
          />
        </FormItem>
      </Modal>
    );
  }
}

export default WithdrawRequisitionModal;
