import * as React from "react";
import {Link} from "elmo-elements";
import {getExistingApiUrl} from "../lib/api";

export interface RequisitionTitleProps {
  id: number;
  originalId?: number;
  showLink?: boolean;
  showRequisitionId?: boolean;
  title: string;
}

interface RequisitionTitleState {}

export class RequisitionTitle extends React.Component<RequisitionTitleProps, RequisitionTitleState> {
  constructor(props: RequisitionTitleProps) {
    super(props);
  }

  public render () {
    if (!this.props.title) {
      return '';
    }

    const id = this.props.id ? this.props.id : null;

    let requisitionTitle = this.props.title;

    if (this.props.showRequisitionId) {
      const originalId = this.props.hasOwnProperty('originalId') ? this.props.originalId : null;
      const visibleId = originalId || id;

      const idText = visibleId ? `(${visibleId})` : '';
      requisitionTitle = `${requisitionTitle} ${idText}`;
    }

    let content;

    if (this.props.showLink) {
      const overviewUrl = getExistingApiUrl('REQUISITION_OVERVIEW', { requisitionId: id });
      content = (
        <Link url={overviewUrl}>
          {requisitionTitle}
        </Link>
      )
    } else {
      content = (<>{requisitionTitle}</>);
    }

    return content;
  }
}
