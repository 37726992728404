import React from 'react';
import HireLayoutContext from './HireLayoutContext';

function withLayoutAware(WrappedComponent: any) {
  const LayoutAware = (props: any) => {
    return (
      <HireLayoutContext.Consumer>
        {(layoutProps: any) => <WrappedComponent {...props} {...layoutProps} />}
      </HireLayoutContext.Consumer>
    );
  };

  LayoutAware.displayName = `LayoutAware(${getDisplayName(WrappedComponent)})`;

  return LayoutAware;
}

function getDisplayName(WrappedComponent: any): string {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withLayoutAware;
