import * as React from 'react';
import 'elmo-elements/main.css';
import './App.css';
import AlertMessage from './elements/AlertMessage';
import { translate } from "react-i18next";

const App = (props: any) => {

  return (
    <>
        <AlertMessage />
        {props.children}
    </>
  );
}

export default translate("translations")(App);

