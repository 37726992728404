import React from 'react';
import { getDepartments } from '../../../../lib/api/department/service';
import { Select } from 'elmo-elements';
import { SelectOption } from '../../../../type/form/Select';
import {
  ExtractionGetDepartmentsResponse,
  ExtractionResponseDepartment,
} from '../../../../lib/api/department/type';

const minKeywordLength = 2;

export interface SelectDepartmentsProps {
  value: SelectOption[] | null;
  onChange?: (value: SelectOption[]) => void;
}

type SelectDepartmentsState = {
  selectOptionsDepartments: SelectOption[];
};

export class SelectDepartments extends React.Component<
  SelectDepartmentsProps,
  SelectDepartmentsState
> {
  constructor(props: SelectDepartmentsProps) {
    super(props);

    this.state = {
      selectOptionsDepartments: [],
    };
  }

  handleOnDepartmentsInputChange(keyword: string) {
    if (keyword.length < minKeywordLength) {
      return;
    }

    getDepartments(keyword).then((response: ExtractionGetDepartmentsResponse) => {
      let departments: SelectOption[] = [];
      if (response.data) {
        departments = response.data.map((department: ExtractionResponseDepartment) => {
          return { value: department.id.toString(), label: department.text };
        });
      }

      this.setState({
        selectOptionsDepartments: departments,
      });
    });
  }

  render() {
    return (
      <Select
        value={this.props.value}
        options={this.state.selectOptionsDepartments}
        onInputChange={(value) => this.handleOnDepartmentsInputChange(value)}
        onChange={this.props.onChange}
        isMulti={true}
      />
    );
  }
}

export default SelectDepartments;
