import * as React from 'react';
import { Trans } from 'react-i18next';
import trans from 'i18next';
import { Modal, Button, Paragraph, FormItem, TextArea } from 'elmo-elements';
import { ActionModalProps } from './types';
import { sendRequest, getExistingApiUrl } from '../../../../../lib/api';

type OnHoldRequisitionModalState = {
  isSubmitButtonDisabled: boolean;
  reason: string;
};

export class OnHoldRequisitionModal extends React.Component<
 ActionModalProps,
 OnHoldRequisitionModalState
>{
  title:string = trans.t('Place requisition on hold');
  submitLabel:string = trans.t('Yes, place on hold');
  closeLabel:string = trans.t('Cancel');
  reasonMaxLength = 255;

  constructor(props: ActionModalProps) {
    super(props);

    this.state = {
      isSubmitButtonDisabled: true,
      reason: '',
    };
  }

  async handleSubmit(id: number) {
    const payload = {
      id: id,
      onHoldReason: this.state.reason,
    };

    await sendRequest(`${getExistingApiUrl('ONHOLD')}`, 'post', payload);
    this.props.handlePostSubmit();
    this.props.toggleModal();
  }

  isWithinRange(charLength: number){
    return charLength > 0 && charLength <= this.reasonMaxLength;
  };

  disableSubmitButton(disable: boolean) {
    this.setState({
      isSubmitButtonDisabled: disable
    });
  };

  validateReason(reason: string) {
    if (!this.isWithinRange(reason.length)) {
      this.disableSubmitButton(true);

      return ;
    }

    this.disableSubmitButton(false);
  }

  onReasonChange(event: any) {
    this.validateReason(event.target.value);
    
    if (!this.state.isSubmitButtonDisabled) {
      this.setState({
        reason: event.target.value,
      });
    }
  }

  render() {
    let primaryButton;
    const requisitionId =
      this.props.data && this.props.data.hasOwnProperty('id')
        ? this.props.data.id
        : '';

    if (requisitionId) {
      primaryButton = (
        <Button
          type="primary"
          onClick={() => this.handleSubmit(requisitionId)}
          isDisabled={this.state.isSubmitButtonDisabled}
        >
          { this.submitLabel }
        </Button>
      );
    }

    return (
      <Modal
        id="onHoldRequisitionModal"
        type="medium"
        isOpened={this.props.isModalOpen}
        closeModal={this.props.toggleModal}
        title={`${this.title}?`}
        closeLabel={this.closeLabel}
        ariaLabel={this.title}
        primaryButton={primaryButton}
        hasCloseFooterButton={true}
      >
        <Paragraph>
          <Trans>Adverts associated with the requisition will be disabled. New actions on candidates and the requisition will be disabled.</Trans>
        </Paragraph>

        <br />

        <Paragraph>
          <Trans>Previously scheduled interviews, panel reviews and tasks can still be performed.</Trans>
        </Paragraph>

        <br />

        <FormItem
          label={`${trans.t('Reason for placing on hold?')}`}
        >
          <TextArea
            id="inputOnHoldRequisitionReason"
            name="reason"
            onChange={(event) => this.onReasonChange(event)}
          />
        </FormItem>
      </Modal>
    );
  }
}

export default OnHoldRequisitionModal;