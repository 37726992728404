import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchRequisitionList } from './state/action';
import { RequisitionListPage } from './RequisitionListPage';
import { State } from '../../../state/reducer';
import { RequisitionListRequest } from '../models/RequisitionListRequest';
import { initialiseInterceptor } from './helper/RequisitionListInterceptor';

initialiseInterceptor();

const mapStateToProps = (state: State) => ({
  requisitions: state.requisitions,
  filter: state.requisitions.filter,
  pagination: state.requisitions.pagination,
  sort: state.requisitions.sort
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchRequisitionList: (request?: RequisitionListRequest) =>
  dispatch(fetchRequisitionList(request)),
  displayAddForm: () => dispatch(fetchRequisitionList()),
  dispatch: dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RequisitionListPage));
