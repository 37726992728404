import React from 'react';
import trans from 'i18next';
import moment from 'moment';
import {DataTable, Loader, Modal, Alert} from 'elmo-elements';
import {sanitizeHtmlString} from "../../../../../lib/helper/string";
import {ActionModalProps} from './types';
import {ApprovalStatus, ApprovalStatusStatus} from "../../../models/ApprovalStatus";
import {fetchApprovalStatusesForRequisition} from "../../../service";

const dateFormat = 'D MMM YYYY HH:mma';

type Flash = {
  message: string|null;
  type?: FlashType;
};

type FlashType = 'warning' | 'info' | 'success' | 'danger';

type ViewRequisitionApprovalStatusActionState = {
  approvalStatuses: ApprovalStatus[],
  flash: Flash,
  isLoading?: boolean,
};

export class ViewRequisitionApprovalStatusModal extends React.Component<
  ActionModalProps,
  ViewRequisitionApprovalStatusActionState
> {
  constructor(props: ActionModalProps) {
    super(props);

    this.state = {
      approvalStatuses: [],
      flash: {
        message: null,
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    const requisitionId = this.props.data && this.props.data.hasOwnProperty('id') ? this.props.data.id : '';

    if (requisitionId) {
      this.loadData(requisitionId);
    }
  }

  private handleFlashClose = () => {
    this.setState({
      flash: {
        message: null,
      },
    });
  };

  private loadData = (requisitionId: number) => {
    this.setState({
      approvalStatuses: [],
      flash: {
        message: null,
      },
      isLoading: true,
    }, () => {
      fetchApprovalStatusesForRequisition(requisitionId)
        .then((approvalStatuses: ApprovalStatus[]) => {
          this.setState({
            approvalStatuses: approvalStatuses,
            isLoading: false,
          })
        })
        .catch((error) => {
          const message =
            error.hasOwnProperty('response') &&
            error.response.hasOwnProperty('data') &&
            error.response.data.hasOwnProperty('message') &&
            error.message !== ''
            ? error.response.data.message
            : trans.t('Unable to fetch approval status data.');
          this.setState({
            isLoading: false,
            flash: {
              message: message,
              type: 'danger',
            }
          });
        });
    });
  };

  private renderDate = (data: ApprovalStatus) => {
    let dateStr = '';

    const date = data['date'];

    if (date instanceof Date) {
      dateStr = moment(date).format(dateFormat);
    }

    return (
      <div>{dateStr}</div>
    );
  };

  private renderFlash = () => {
    if (!this.state.flash.message) {
      return;
    }

    return (
      <>
        <Alert
          message={this.state.flash.message}
          type={this.state.flash.hasOwnProperty('type') ? this.state.flash.type : 'danger'}
          onClose={() => this.handleFlashClose()}
        />
        <br />
      </>
    );
  };

  private renderMessage = (data: ApprovalStatus) => {
    const message = data['message'] || '';

    // Confirmed that HTML tags in messages are allowed, as per the existing functionality.
    return (
      <div dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(message) }}></div>
    );
  };

  private renderStatus = (data: ApprovalStatus) => {
    const status = data['status'];

    switch (status) {
      case ApprovalStatusStatus.PENDING:
        return trans.t('Pending');
      case ApprovalStatusStatus.APPROVED:
        return trans.t('Approved');
      case ApprovalStatusStatus.REJECTED:
        return trans.t('Rejected');
    }
  };

  render() {
    const ariaLabel = `${trans.t('Approval Status')}`;

    let content = <Loader type={'spinner'}></Loader>;

    if (!this.state.isLoading) {
      let columns = [
        { title: trans.t('Approver') },
        { title: trans.t('Status') },
        { title: trans.t('Date') },
        { title: trans.t('Message') },
      ];

      content = (
        <DataTable>
          <DataTable.Header columns={columns} />
          <DataTable.Body>
            {this.state.approvalStatuses.map((approvalStatus: ApprovalStatus, key: number) => (
              <DataTable.Tr key={key}>
                <DataTable.Td>{approvalStatus.approver}</DataTable.Td>
                <DataTable.Td>{this.renderStatus(approvalStatus)}</DataTable.Td>
                <DataTable.Td>{this.renderDate(approvalStatus)}</DataTable.Td>
                <DataTable.Td>{this.renderMessage(approvalStatus)}</DataTable.Td>
              </DataTable.Tr>
            ))}
          </DataTable.Body>
        </DataTable>
      );
    }

    return (
      <Modal
        id="viewRequisitionApprovalStatusModal"
        type="medium"
        isOpened={this.props.isModalOpen}
        closeModal={this.props.toggleModal}
        title={trans.t('Approval Status')}
        ariaLabel={ariaLabel}
        hasCloseFooterButton={false}
      >
        {this.renderFlash()}
        {content}
      </Modal>
    );
  }
}

export default ViewRequisitionApprovalStatusModal;
