// tslint:disable member-ordering
import React, { Component } from 'react';
import LayoutContent from './component/HireLayoutContent';
import LayoutHeader from './component/HireLayoutHeader';
import HireLayoutMenu from './component/HireLayoutMenu';
import HireLayoutContext from './component/HireLayoutContext';
import HireLayoutSidePanel from './component/HireLayoutSidePanel';
import {
  getClass,
  getScrollY,
  scrollLock,
  setScrollY,
  classOSinHTML,
} from '../_lib/helper';
import {
  HireLayoutProps,
  MenuStateType,
  HireLayoutContextProps,
  HireLayoutThemes,
} from './type';

class HireLayout extends Component<HireLayoutProps, HireLayoutContextProps> {
  static defaultProps = {
    isStyleReboot: true,
    theme: HireLayoutThemes.default,
  };
  static Header = LayoutHeader;
  static Content = LayoutContent;
  static SidePanel = HireLayoutSidePanel;
  private bodyScrollTop = 0;

  constructor(props: HireLayoutProps) {
    super(props);
    this.handleClassOSinHTML();
    const { isStyleReboot } = props;
    if (isStyleReboot) {
      this.getRebootStyle();
    }
  }

  /* istanbul ignore next */
  getRebootStyle() {
    require('../_lib/style/_reboot.css');
  }

  /* istanbul ignore next */
  handleClassOSinHTML() {
    classOSinHTML();
  }

  setMenuStatus = (menuState: MenuStateType) => {
    this.setState({ menuState });
  };

  toggleSidePanelOpen = () => {
    this.setState(
      ({ isSidePanelOpen }: HireLayoutContextProps) => ({
        isSidePanelOpen: !isSidePanelOpen,
      }),
      () => {
        if (this.state.isSidePanelOpen) {
          this.bodyScrollTop = getScrollY();
          scrollLock(true);
        } else {
          scrollLock(false);
          setScrollY(this.bodyScrollTop);
        }
      }
    );
  };

  toggleSidePanelOverlay = () => {
    this.setState(({ isSidePanelOverlay }: HireLayoutContextProps) => ({
      isSidePanelOverlay: !isSidePanelOverlay,
    }));
  };

  toggleBulkActionOpen = () => {
    this.setState(({ isBulkActionOpen }: HireLayoutContextProps) => ({
      isBulkActionOpen: !isBulkActionOpen,
    }));
  };

  toggleBulkActionDisabled = () => {
    this.setState(({ isBulkActionDisabled }: HireLayoutContextProps) => ({
      isBulkActionDisabled: !isBulkActionDisabled,
    }));
  };

  toggleSearchOpen = () => {
    this.setState(({ isSearchOpen }: HireLayoutContextProps) => ({
      isSearchOpen: !isSearchOpen,
    }));
  };

  toggleModalOpen = () => {
    this.setState(
      ({ isModalOpen }: HireLayoutContextProps) => ({
        isModalOpen: !isModalOpen,
      }),
      () => {
        if (this.state.isModalOpen) {
          this.bodyScrollTop = getScrollY();
          scrollLock(true);
        } else {
          scrollLock(false);
          setScrollY(this.bodyScrollTop);
        }
      }
    );
  };

  setHeaderRef = (ref: any) => {
    this.setState({ headerRef: ref }, () => {
      const onUpdateHeaderRef = new CustomEvent('onUpdateHeaderRef', {
        detail: {
          target: ref,
        },
      });
      window.dispatchEvent(onUpdateHeaderRef);
    });
  };

  setLayoutRef = (ref: any) => {
    this.setState({ layoutRef: ref }, () => {
      const onUpdateLayoutRef = new CustomEvent('onUpdateLayoutRef', {
        detail: {
          target: ref,
        },
      });
      window.dispatchEvent(onUpdateLayoutRef);
    });
  };

  // For the HireLayoutContext we define the value as state to prevent
  // unnecessary rendering. Please refer to Context API for more info.
  state: HireLayoutContextProps = {
    menuState: 'wide',
    isContentScrolling: false,
    isSidePanelOpen: false,
    isSidePanelOverlay: false,
    isBulkActionOpen: false,
    isBulkActionDisabled: true,
    isSearchOpen: false,
    isModalOpen: false,
    setMenuStatus: this.setMenuStatus,
    toggleSidePanelOpen: this.toggleSidePanelOpen,
    toggleSidePanelOverlay: this.toggleSidePanelOverlay,
    toggleBulkActionOpen: this.toggleBulkActionOpen,
    toggleBulkActionDisabled: this.toggleBulkActionDisabled,
    toggleSearchOpen: this.toggleSearchOpen,
    toggleModalOpen: this.toggleModalOpen,
    layoutRef: undefined,
    setLayoutRef: this.setLayoutRef,
    headerRef: undefined,
    setHeaderRef: this.setHeaderRef,
  };

  render() {
    const {
      menuState,
      isSearchOpen,
      isBulkActionOpen,
      isSidePanelOpen,
      isModalOpen,
    } = this.state;
    const { menu, children, theme } = this.props;

    return (
      <div
        className={getClass(
          'elmo-layout',
          {
            [`menu-status-${menuState}`]: true,
            'elmo-elements': true, // To solve the classname conflict.
          },
          {
            'is-search-open': isSearchOpen,
            'is-bulkaction-open': isBulkActionOpen,
            'is-sidepanel-open': isSidePanelOpen,
            'is-modal-open': isModalOpen,
            [`theme-${theme}`]: theme,
          }
        )}
      >
        <HireLayoutContext.Provider value={this.state}>
          {menu && <HireLayoutMenu>{menu}</HireLayoutMenu>}
          <div className="elmo-layout__main-app">{children}</div>
        </HireLayoutContext.Provider>
      </div>
    );
  }
}

export default HireLayout;
