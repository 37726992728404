import React from 'react';
import { Checkbox } from 'elmo-elements';
import HireListTableHeader from './HireListTableHeader';
import HireListTableHeaderSelectAll from './HireListTableHeaderSelectAll';
import { withListTableContext } from '../HireListTableContextConsumer';
import { Sticky } from 'elmo-elements';

export type HireListTableHeaderWrapperProps = {
  /** @Ignore From HireLayoutContext */
  isBulkActionOpen?: boolean;
  /** @Ignore From DataTableContext */
  selectAllIsChecked?: boolean;
  /** @Ignore From DataTableContext */
  selectAllIsIndeterminate?: boolean;
  /** @Ignore From DataTableContext */
  showSelectAllAvailableHeader?: boolean;
  /** @Ignore From DataTableContext */
  onSelectAllToggle?: (isSelect: boolean) => void;
  /** @Ignore From DataTableContext */
  isItemSelected?: (data: any) => boolean;
  /** @Ignore From DataTableContext */
  isItemDisabled?: (data: any) => boolean;
  /** @Ignore From DataTableContext */
  setContextState?: (state: any) => void;
  /** @Ignore From DataTableContext */
  onSelectAllAvailableToggle?: (isSelect: boolean) => void;
  /** Id of the HireListTable */
  id?: string;
  /** inherit from HireListTable */
  isSticky?: boolean;
};

class HireListTableHeaderWrapper extends React.Component<
  HireListTableHeaderWrapperProps
> {
  onSelectAllAvailable = (isSelect: boolean) => () => {
    const { setContextState, onSelectAllAvailableToggle } = this.props;

    if (!isSelect && setContextState) {
      setContextState({
        showSelectAllAvailableHeader: false,
        selectAllIsChecked: false,
      });
    }

    if (onSelectAllAvailableToggle) {
      onSelectAllAvailableToggle(isSelect);
    }
  };

  onSelectAllClick = () => {
    const {
      onSelectAllToggle,
      selectAllIsChecked,
      setContextState,
    } = this.props;

    const nextSelectAllIsChecked = !selectAllIsChecked;

    if (setContextState) {
      setContextState({
        selectAllIsChecked: nextSelectAllIsChecked,
        selectAllIsIndeterminate: false,
        showSelectAllAvailableHeader: nextSelectAllIsChecked,
      });
    }

    if (onSelectAllToggle) {
      onSelectAllToggle(nextSelectAllIsChecked);
    }
  };

  render() {
    const {
      isBulkActionOpen,
      selectAllIsChecked,
      selectAllIsIndeterminate,
      showSelectAllAvailableHeader,
      id,
      isSticky,
    } = this.props;

    const renderListTableHeaderWrapper = (
      <div className="elmo-listtable__header-wrapper" role="rowgroup">
        {isBulkActionOpen && (
          <div className="elmo-listtable__row-checkbox">
            <Checkbox
              id="selectAll"
              onChange={this.onSelectAllClick}
              isChecked={selectAllIsChecked}
              isIndeterminate={selectAllIsIndeterminate}
              ariaLabel="Select all"
            />
          </div>
        )}
        {!showSelectAllAvailableHeader && <HireListTableHeader />}
        {showSelectAllAvailableHeader && (
          <HireListTableHeaderSelectAll
            onSelectAllAvailable={this.onSelectAllAvailable}
          />
        )}
      </div>
    );
    if (id && isSticky) {
      return (
        <Sticky.Element id={id + '-Sticky'}>
          {renderListTableHeaderWrapper}
        </Sticky.Element>
      );
    }
    return renderListTableHeaderWrapper;
  }
}

export { HireListTableHeaderWrapper as HireListTableHeaderWrapperNoContext };
export default withListTableContext(HireListTableHeaderWrapper);
