import * as React from 'react';
import { Menu as ElmoMenu } from 'elmo-elements';
import { fetchNavigationItems, fetchSubNavigationItems } from './service';
import {
  NavigationMenuProps,
  NavigationMenuComponentState,
  NavigationItemsInterface,
  MenuItemInterface,
} from './types';
import { NAVIGATION_DEFAULT_STATE } from './defaultState';
import {
  getBranding,
  generateUserMenu,
  generateUserDetails,
  generateSubNavigationData,
  generateMainNavigationData,
} from './helper';

export class NavigationMenu extends React.Component<
  NavigationMenuProps,
  NavigationMenuComponentState
> {
  async componentDidMount() {
    try {
      const mainNavigationData = await fetchNavigationItems<
        NavigationItemsInterface
      >();

      const subNavigationData = await fetchSubNavigationItems<
        Array<MenuItemInterface>
      >();

      this.setState({
        navigationData: {
          ...mainNavigationData,
          subNavigation: subNavigationData
        },
        isLoaded: true
      });
    } catch (error) {
      console.log('Failed to load navigation items.');
      this.setState({
        error,
        isLoaded: true
      });
    }
  }

  constructor(props: NavigationMenuProps) {
    super(props);
    this.state = NAVIGATION_DEFAULT_STATE;
  }

  public render() {
    const { isLoaded } = this.state;

    return (
      <ElmoMenu
        logo={getBranding(this.state).logo}
        asSubMenu={true}
        subMenuItems={generateSubNavigationData(this.state)}
        menuItems={generateMainNavigationData(this.state)}
        userDetails={generateUserDetails(this.state)}
        userMenu={generateUserMenu(this.state)}
        isReact={true}
        isLoading={!isLoaded}
      />
    );
  }
}
