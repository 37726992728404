import axios, { Canceler, AxiosRequestConfig } from 'axios';
import { CANCEL_TO_BE_REPLACED } from '../state/action';

const CancelToken = axios.CancelToken;
export const cancelCollection: Function[] = [];
let isInitialised = false;

export function requestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
  if (!config.url || config.url.indexOf('/api/recruitment/v1/requisitions') < 0) {
    return config;
  }

  cancelAll();

  return {
    ...config,
    cancelToken: new CancelToken(addToCancelCollection)
  };
}

export function cancelAll() {
  cancelCollection.map(cancelItem => cancelItem(CANCEL_TO_BE_REPLACED));
}

export function addToCancelCollection(cancel: Canceler) {
  cancelCollection.push(cancel);
}

export function handleError(error: any): Promise<never> {
  return Promise.reject(error);
}

/**
 * This is a temporary solution where it'd try to cancel previous
 * requests for /api/recruitment/v1/requisitions.
 * 
 * TODO: proper implementation of cancelling previous still pending requests.
 * 
 * REFS:
 * - https://github.com/axios/axios#cancellation
 * - https://stackoverflow.com/questions/42896831/cancel-axios-get-request-when-typing-reactjs
 * - https://stackoverflow.com/questions/44670782/know-if-there-are-pending-request-in-axios
 */
export function initialiseInterceptor(): boolean {
  if (isInitialised) {
    // prevent multiple init
    return false;
  }

  axios.interceptors.request.use(requestInterceptor, handleError);

  isInitialised = true;
  
  return true;
};