export function getLastUrlParam(path: string = ''): string {
  if (location.pathname && location.pathname === '/' && !path) {
    return '';
  }
  const pathName =
    location.pathname && location.pathname !== '/' ? location.pathname : path;
  const splitedPathName = pathName.split('/');

  return String([...splitedPathName].pop());
}
