import keyMirror from '../lib/helper/keyMirror';
import { ObjStringValueMap } from '../types';

export const Actions: ObjStringValueMap = keyMirror({
  TRANSLATIONS_FETCH_START: undefined,
  TRANSLATIONS_FETCH_FAILURE: undefined,
  REC_REQUISITION_LIST_FETCH_REQUEST: undefined,
  REC_REQUISITION_LIST_FETCH_START: undefined,
  REC_REQUISITION_LIST_FETCH_SUCCESS: undefined,
  REC_REQUISITION_LIST_FETCH_FAILURE: undefined,
  REC_REQUISITION_FINALISE_START: undefined,
  REC_REQUISITION_FINALISE_SUCCESS: undefined,
  REC_REQUISITION_FINALISE_FAILURE: undefined,
  REC_REQUISITION_WITHDRAW_START: undefined,
  REC_REQUISITION_WITHDRAW_SUCCESS: undefined,
  REC_REQUISITION_WITHDRAW_FAILURE: undefined,
  REC_REQUISITION_POST_SUCCESS: undefined,
  APP_NEW_MESSAGE: undefined,
  OPEN_MODAL: undefined,
  CLOSE_MODAL: undefined,
  INIT_ACTION_SUCCESS: undefined,
  DO_AUTH_FAILURE: undefined,
  DO_TRANSLATIONS_FAILURE: undefined,
  AUTHENTICATION_SUCCESS: undefined,
  CLIENT_API_BASE_INITIALISE_FAILURE: undefined,
  CLIENT_API_BASE_INITIALISE_START: undefined,
  CONFIGURATION_FETCH_START: undefined,
  CONFIGURATION_FETCH_FAILURE: undefined,
});

export default Actions;