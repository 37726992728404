import React from 'react';
import {
  Header,
  AddIcon,
  CloudDownloadIcon,
} from 'elmo-elements';
import trans from 'i18next';

type HeaderProps = {
  title: string|'';
  dispatch: any;
  toggleDemoActionModal: Function;
};

type HeaderState = {
  isDemoModalActionOpen: boolean;
};

class RequisitionListHeader extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      isDemoModalActionOpen: false,
    };
  }

  getHeaderButtons = () => [
    {
      icon: <CloudDownloadIcon />,
      label: trans.t('Export'),
      onClick: () => {
        console.log('Exporting!');
        this.props.toggleDemoActionModal();
      }
    },
  ];

  primaryButton = () => {
    return {
      icon: <AddIcon />,
      label: trans.t('Requisition'),
      // Temporarily redirect to current "New Requisition" page until redesign modals are ready.
      onClick: () =>  { window.location.href='/controlpanel/recruitment/requisition/add' }
    };
  };

  render() {
    return (
      <>
        <Header
          buttons={this.getHeaderButtons()}
          primaryButton={this.primaryButton()}
          title={this.props.title}
          titleAddon={<></>}
        >
        </Header>
      </>
    );
  }
}

export default RequisitionListHeader;
