import React, { Children, ReactElement, ReactNode } from 'react';
import { isElementOfType } from '../../_lib/helper';
import HireListTableTr from './HireListTableTr';

export type HireListTableBodyProps = {
  /** Contains an array of ListTableTr elements */
  children?: ReactNode;
};

function HireListTableBody({ children }: HireListTableBodyProps) {
  return (
    <>
      {Children.map(
        children as ReactElement<any>[],
        (child: ReactElement<any>, rowIndex: number) => {
          if (
            !React.isValidElement(child) ||
            !isElementOfType(child, HireListTableTr)
          ) {
            return null;
          }

          return React.cloneElement(child as ReactElement<any>, {
            rowIndex: rowIndex,
          });
        }
      )}
    </>
  );
}

HireListTableBody.displayName = 'HireListTableBody';

export default HireListTableBody;
