import './FilterControl.css';
import * as React from 'react';
import trans from 'i18next';
import {
  AddIcon,
  Dropdown,
  EditOutlinedIcon,
  FilterListIcon,
  Text,
} from 'elmo-elements';
import FilterForm from './FilterForm';
import {
  FilterControlProps,
  FilterControlState,
  FilterValues,
  SavedFilterValues,
} from './type';

/**
 * Here were all filter related events and actions should be managed.
 */
class FilterControl extends React.Component<FilterControlProps, FilterControlState>
{
  createLabelText:string = trans.t("Create Filter");
  savedFiltersLabelText:string = trans.t("Saved Filters");

  constructor(props: FilterControlProps)
  {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  handleSavedFilterSearch = (savedFilterId: number) => {
    this.props.handleSavedFilterSearch(savedFilterId);
  };

  handleToggleFormModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  };

  getSavedFilters = (): SavedFilterValues[] => {
    return this.props.savedFilters;
  }

  hasSavedFilters = (): boolean => {
    return this.props.savedFilters && this.props.savedFilters.length > 0;
  }

  renderSavedFilter = (savedFilter: SavedFilterValues): React.ReactFragment => {
    return (
      <Dropdown.Item
        ariaLabel={savedFilter.name}
        value={savedFilter.id}
        onClick={this.handleSavedFilterSearch}
      >
        <span>{savedFilter.name}</span> {/* <EditOutlinedIcon className="float-right ml-xxl-auto"/> */}
      </Dropdown.Item>
    );
  }

  renderSavedFiltersMenuSection = (): React.ReactFragment => {
    let savedFiltersMenuSection = <></>;

    if (this.hasSavedFilters()) {
      const savedFiltersContent = this.getSavedFilters().map((savedFilter: SavedFilterValues) => {
        return this.renderSavedFilter(savedFilter);
      });
      savedFiltersMenuSection = (
        <div id={'save-filters__menu'}>
          <Dropdown.Divider/>
          <Dropdown.Label>
            <p className="elmo-typography mt-3 mb-2 ml-3 mr-3 text-size-sm text-color-gray">
              {this.savedFiltersLabelText}
            </p>
          </Dropdown.Label>
          {savedFiltersContent}
        </div>
      );
    }

    return savedFiltersMenuSection;
  }

  handleSubmit = (formFields: FilterValues) => {
    this.props.handleSubmit(formFields);

    if (this.props.isCloseOnSubmit) {
      this.handleToggleFormModal();
    }
  };

  render()
  {
    const savedFiltersMenuSection = this.renderSavedFiltersMenuSection();

    return (
      <>
        <FilterForm
          isModalOpen={this.state.isModalOpen}
          toggleModal={this.handleToggleFormModal}
          renderFormContent={ this.props.renderFormContent ? this.props.renderFormContent : <></> }
          title={ this.props.formTitle ? this.props.formTitle : '' }
          handleSubmit={this.handleSubmit}
          filterValues={this.props.filterValues}
          handleClearForm={() => this.props.handleClearForm()}
          isApplyDisabled={this.props.isApplyDisabled}
        />
        <Dropdown
          icon={<FilterListIcon />}
          className="filter-control"
          id={this.props.id ? this.props.id : 'filterDropdown'}
        >
          <Dropdown.Item
            ariaLabel={this.createLabelText}
            value={'create-filter'}
            onClick={this.handleToggleFormModal}
          >
            <AddIcon /> {this.createLabelText}
          </Dropdown.Item>
          {savedFiltersMenuSection}
        </Dropdown>
      </>
    );
  }
}

export default FilterControl;