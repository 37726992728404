import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchJobApplicationList } from './state/action';
import { JobApplicationList } from './view';
import { State } from '../../../state/reducer';
import { JobApplicationListRequest } from '../models/JobApplicationListRequest';
import './styles/style.css';

const mapStateToProps = (state: State) => ({
  jobApplications: state.jobApplications,
  searchOptions: state.jobApplications.searchOptions,
  pagination: state.jobApplications.pagination,
  sort: state.jobApplications.sort
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchJobApplicationList: (requisitionId: string, request?: JobApplicationListRequest) =>
  dispatch(fetchJobApplicationList(requisitionId, request)),
  dispatch: dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(JobApplicationList));
