import * as React from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { GlobalMessage } from '../types';
import { State } from '../state/reducer';

interface AlertProps {
  message: GlobalMessage
}

interface AlertState {
  visible: boolean
}

class AlertMessage extends React.Component<AlertProps, AlertState> {
   
  constructor(props: AlertProps) {
    super(props);

    this.state = {
      visible: true
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  public onDismiss() {
    this.setState({ visible: false });
  }

  public render() {
    if (!this.props.message || this.props.message.text === '') {
      return (<React.Fragment />);
    }
    return (
      <Alert color={this.props.message ? this.props.message.type : "info"} isOpen={this.state.visible} toggle={this.onDismiss}>
        {this.props.message.text}
      </Alert>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    message: state.sharedAppState.message
  }
};

export default connect(mapStateToProps)(AlertMessage);
