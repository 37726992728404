import immutable from 'immutability-helper';
import { createReducer } from '../../../../lib/helper/createReducer'
import { ReduxAction, Obj, CrudLoadingAttributes } from '../../../../types';
import { PaginatedList } from "../../models/PaginatedList";
import { RequisitionItem } from "../../models/RequisitionItem";
import {PENDING, FULFILLED, ERROR, RESET, CANCEL_TO_BE_REPLACED } from './action';

export const onRequestSuccess = (state: PaginatedList<RequisitionItem>, action: ReduxAction) => {
    return immutable(state, {
      data: { $set: action.payload.data },
      pagination: { $set: action.payload.pagination },
      filter: { $set: action.payload.filter },
      sort: { $set: action.payload.sort },
      state: {
        error: { $set: null },
        [CrudLoadingAttributes[action.payload.crudActionName]]: { $set: false }
      }
    });
};

export const onRequestPending = (state: PaginatedList<RequisitionItem>, action: ReduxAction) => {
  return immutable(state, {
    state: {
      [CrudLoadingAttributes[action.payload.crudActionName]]: { $set: true }
    }
  });
};

export const onRequestFailure = (state: PaginatedList<RequisitionItem>, action: ReduxAction) => {
  let actionState = false;

  if (action.payload.error && action.payload.error.message === CANCEL_TO_BE_REPLACED) {
    actionState = true;
  }

  return immutable(state, {
    state: {
      [CrudLoadingAttributes[action.payload.crudActionName]]: { $set: actionState },
      error: { $set: action.payload.error ?  action.payload.error : 'Error'},
    }
  });
};

export const onReset = (state: PaginatedList<RequisitionItem>, action: ReduxAction) => {
  return DEFAULT_STATE.requisition;
};

export const DEFAULT_STATE: Obj = {
  requisitions: {
    data: [],
    filters: {},
    state: {
      isFetching: false,
      isCreating: false,
      isUpdating: false,
      isDeleting: false
    },
    pagination: {
      resultsPerPage: 10,
      pageNumber: 1,
      totalResults: 0,
      totalPages: 0,
    },
    sort: {
      field: "requisitionTitle",
      order: "asc"
    }
  },
}

export default createReducer(DEFAULT_STATE.requisitions, {
  [FULFILLED]: onRequestSuccess,
  [PENDING]: onRequestPending,
  [ERROR]: onRequestFailure,
  [RESET]: onReset
});
