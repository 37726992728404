import { sendRequest, getExistingApiUrl } from '../../api';
import { ExtractionGetLocationsResponse } from './type';

export async function getLocations(
  keyword: string
): Promise<ExtractionGetLocationsResponse> {
  return await sendRequest(
    `${getExistingApiUrl('LOCATION_SEARCH', {
      keyword: keyword,
    })}`,
    'get'
  );
}
