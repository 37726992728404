import React, { ReactChild } from 'react';
import trans from 'i18next';
import {
  AttachMoneyIcon,
  MessagingOutlinedIcon,
  MyTeamOutlinedIcon,
  WorkOutlineIcon,
  BlockIcon,
  AssignmentTurnedInOutlinedIcon,
  PauseCircleOutlineIcon,
  AutorenewIcon,
  StatusIcon,
  EditOutlinedIcon,
  InfoOutlinedIcon,
} from 'elmo-elements';
import {
  RefreshIcon,
  InfoIcon
} from 'elmo-elements-icons';
import { ListTableAction } from 'elmo-elements/ListTable/type';
import { Action } from '../../models/Action';
import { RequisitionItem } from '../../models/RequisitionItem';

export const MoreActionItems = {
  canViewJobApplications: 'canViewJobApplications',
  canViewJobAds: 'canViewJobAds',
  canViewInterviews: 'canViewInterviews',
  canViewCosts: 'canViewCosts',
  canWithdraw: 'canWithdraw',
  canResubmit: 'canResubmit',
  canFinalise: 'canFinalise',
  canOnHold: 'canOnHold',
  canReOpen: 'canReOpen',
  canViewDetails: 'canViewDetails',
  canViewApprovalStatus: 'canViewApprovalStatus',
  canEdit: 'canEdit',
};

const MoreActionItemGroup = {
  link: [
    MoreActionItems.canViewJobApplications,
    MoreActionItems.canViewJobAds,
    MoreActionItems.canViewInterviews,
    MoreActionItems.canViewCosts,
  ],
  action: [
    MoreActionItems.canOnHold,
    MoreActionItems.canWithdraw,
    MoreActionItems.canViewApprovalStatus,
    MoreActionItems.canResubmit,
    MoreActionItems.canFinalise,
    MoreActionItems.canReOpen,
    MoreActionItems.canViewDetails,
    MoreActionItems.canEdit,
  ]
};

// elmo-elements expects ReactChild
export function getActionIcon(key: string): ReactChild
{
  let icon:ReactChild;

  switch (key) {
    case  MoreActionItems.canViewJobApplications:
      icon = <MyTeamOutlinedIcon />;
      break;
    case  MoreActionItems.canViewJobAds:
      icon = <WorkOutlineIcon />;
      break;
    case  MoreActionItems.canViewInterviews:
      icon = <MessagingOutlinedIcon />;
      break;
    case  MoreActionItems.canViewCosts:
      icon = <AttachMoneyIcon />;
      break;
    case  MoreActionItems.canWithdraw:
      icon = <BlockIcon />;
      break;
    case  MoreActionItems.canResubmit:
      icon = <RefreshIcon />;
      break;
    case  MoreActionItems.canFinalise:
      icon = <AssignmentTurnedInOutlinedIcon />;
      break;
    case MoreActionItems.canOnHold:
      icon = <PauseCircleOutlineIcon />;
      break;
    case MoreActionItems.canReOpen:
      icon = <AutorenewIcon />;
      break;
    case MoreActionItems.canViewDetails:
      icon = <InfoOutlinedIcon />;
      break;
    case  MoreActionItems.canViewApprovalStatus:
      icon = <StatusIcon />;
      break;
    case MoreActionItems.canEdit:
      icon = <EditOutlinedIcon />;
      break;
    default: icon = <></>;
  }

  return icon;
};

export function getActionLabel(key: string): string
{
  let label:string;

  switch (key) {
    case  MoreActionItems.canViewJobApplications:
      label = 'View Candidates';
      break;
    case  MoreActionItems.canViewJobAds:
      label = 'View Job Ads';
      break;
    case  MoreActionItems.canViewInterviews:
      label = 'View Interviews';
      break;
    case  MoreActionItems.canViewCosts:
      label = 'View Costs';
      break;
    case  MoreActionItems.canWithdraw:
      label = 'Withdraw';
      break;
    case  MoreActionItems.canResubmit:
      label = 'Re-submit';
      break;
    case  MoreActionItems.canFinalise:
      label = 'Finalise';
      break;
    case MoreActionItems.canOnHold:
      label = 'Place On Hold';
      break;
    case MoreActionItems.canReOpen:
      label = 'Re-open';
      break;
    case MoreActionItems.canViewDetails:
      label = 'View Details';
      break;
    case MoreActionItems.canViewApprovalStatus:
      label = 'Approval Status';
      break;
    case MoreActionItems.canEdit:
      label = 'Edit';
      break;
    default: label = key;
  }

  if (trans.isInitialized) {
    label = trans.t(label);
  }

  return label;
}

export default (
  actions: Action,
  data: RequisitionItem,
  callbacks: Function
): ListTableAction[] => {
  let items: ListTableAction[] = [];

  // helps makes ListTableAction item
  const helper = (action: string):ListTableAction => {
    return {
      label: getActionLabel(action),
      icon: getActionIcon(action),
      onClick: callbacks(action),
      isDisabled: false,
      ariaLabel: getActionLabel(action),
    };
  };

  // hyperlinks section
  MoreActionItemGroup.link.map((action: string) => {
    if (actions.indexOf(action) >= 0) {
      items.push(helper(action));
    }
  });
  
  if (items.length > 0 && actions.length > items.length) {
    items[items.length - 1].withDivider = true;
  }  

  // action section
  MoreActionItemGroup.action.map((action: string) => {
    if (actions.indexOf(action) >= 0) {
      items.push(helper(action));
    }
  });

  return items;
};