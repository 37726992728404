import React from 'react';
import { Trans } from 'react-i18next';
import trans from 'i18next';
import { Modal, Button, Paragraph } from 'elmo-elements';
import { ActionModalProps } from './types';
import { sendRequest, getExistingApiUrl } from '../../../../../lib/api';
import { TramIcon } from 'elmo-elements-icons';

type ReOpenRequisitionActionState = {};

export class ReOpenRequisitionModal extends React.Component<
  ActionModalProps,
  ReOpenRequisitionActionState
> {
  constructor(props: ActionModalProps) {
    super(props);
  }

  async handleOnHoldReOpenSubmit(id: number) {
    await sendRequest(`${getExistingApiUrl('REOPEN_ONHOLD')}`, 'post', {
      id: id,
    });
    this.props.handlePostSubmit();
    this.props.toggleModal();
  }

  async handleReOpenSubmit(ids: Array<number>) {
    await sendRequest(`${getExistingApiUrl('REOPEN')}`, 'post', ids);
    this.props.handlePostSubmit();
    this.props.toggleModal();
  }

  renderModalContents() {
    if (this.props.data.status && this.props.data.status == 8) {
      return (
        <>
          <Paragraph>
            <Trans>Once re-opened you may need to manually re-enable any job advertisements associated with this requisition.</Trans>
          </Paragraph>
        </>
      );
    } else {
      return (
        <>
          <Paragraph>
            <Trans>Re-opening a requisition will not trigger any emails to candidates.</Trans>
          </Paragraph>

          <Paragraph>
            <Trans>Users will be able to perform standard actions on candidate records.</Trans>
          </Paragraph>
        </>
      );
    }
  }

  render() {
    let primaryButton;
    const requisitionId =
      this.props.data && this.props.data.hasOwnProperty('id')
        ? this.props.data.id
        : '';

    if (requisitionId) {
      const handleSubmit =
        this.props.data.status && this.props.data.status == 8
          ? () => this.handleOnHoldReOpenSubmit(requisitionId)
          : () => this.handleReOpenSubmit([requisitionId]);

      primaryButton = (
        <Button type={'primary'} onClick={handleSubmit}>
          <Trans>Yes, Re-open</Trans>
        </Button>
      );
    }

    const ariaLabel = `${trans.t('Yes, re-open')}`;
    const closeLabel = `${trans.t('Cancel')}`;

    return (
      <Modal
        id="reopenRequisitionModal"
        type="medium"
        isOpened={this.props.isModalOpen}
        closeModal={this.props.toggleModal}
        title={trans.t('Re-Open Requisition?')}
        closeLabel={closeLabel}
        ariaLabel={ariaLabel}
        primaryButton={primaryButton}
        hasCloseFooterButton={true}
      >
        {this.renderModalContents()}
      </Modal>
    );
  }
}

export default ReOpenRequisitionModal;
