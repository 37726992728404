import { SelectOption } from "../../type/form/Select";
import { IdTextItem } from "../../type/common/items";

export function convertIdTextItemToSelectOption(
  options: IdTextItem[]
): SelectOption[] {
  return options.map((option: IdTextItem) => {
    return {
      value: `${option.id}`,
      label: option.text,
    };
  });
}

export function convertSelectOptionToIdTextItem(
  options: SelectOption[]
): IdTextItem[] {
  return options.map((option: SelectOption) => {
    return {
      id: parseInt(option.value),
      text: option.label,
    };
  });
}