import React from 'react';
import { Trans } from 'react-i18next';
import trans from 'i18next';
import { Modal, Button, Paragraph } from 'elmo-elements';
import { ActionModalProps } from './types';
import { sendRequest, getExistingApiUrl } from '../../../../../lib/api';

type FinaliseRequisitionActionState = {};

export class FinaliseRequisitionModal extends React.Component<
  ActionModalProps,
  FinaliseRequisitionActionState
> {
  constructor(props: ActionModalProps) {
    super(props);
  }

  async handleSubmit(ids: Array<number>) {
    await sendRequest(`${getExistingApiUrl('FINALISE')}`, 'post', ids);
    this.props.handlePostSubmit();
    this.props.toggleModal();
  }

  render() {
    let primaryButton;
    const requisitionId =
      this.props.data && this.props.data.hasOwnProperty('id')
        ? this.props.data.id
        : '';

    if (requisitionId) {
      primaryButton = (
        <Button
          type={'primary'}
          onClick={() => this.handleSubmit([requisitionId])}
        >
          <Trans>Yes, finalise</Trans>
        </Button>
      );
    }

    const ariaLabel = `${trans.t('Yes, finalise')}`;
    const closeLabel = `${trans.t('Cancel')}`;

    return (
      <Modal
        id="finaliseRequisitionModal"
        type="medium"
        isOpened={this.props.isModalOpen}
        closeModal={this.props.toggleModal}
        title={trans.t('Finalise requisition(s)?')}
        closeLabel={closeLabel}
        ariaLabel={ariaLabel}
        primaryButton={primaryButton}
        hasCloseFooterButton={true}
      >
        <Paragraph>
          {trans.t('Any active job adverts on this requisition will expire.')}
        </Paragraph>
        <br />
      </Modal>
    );
  }
}

export default FinaliseRequisitionModal;
