import { sendRequest, generateApiUrl } from '../../../lib/api/index';
import { API_ENDPOINTS, MAIN_NAVIGATION } from '../../../lib/api/config';
import { getClientApiHost } from '../../../lib/clientUrl/state/action';

export async function fetchNavigationItems<T>(): Promise<T> {
  const url = `${getClientApiHost().url}/${MAIN_NAVIGATION}`;
  return sendRequest(url, 'GET');
}

export async function fetchSubNavigationItems<T>(): Promise<T> {
  const url = generateApiUrl('RECRUITMENT_NAVIGATION');
  return sendRequest(url, 'GET');
}
