import React from 'react';
import { withListTableContext } from '../HireListTableContextConsumer';
import { getClass } from '../../_lib/helper';

export type HireListTableHeaderSelectAllProps = {
  onSelectAllAvailable: (isSelect: boolean) => React.MouseEventHandler;
  /** @Ignore From DataTableContext */
  countItemsAvailable?: number;
  /** @Ignore From DataTableContext */
  isAllAvailableSelected?: boolean;
  /** @Ignore From DataTableContext */
  countSelectable: number;
};

function HireListTableHeaderSelectAllMessage({ children, className }: any) {
  return (
    <div className={getClass('elmo-listtable__select-message', className)}>
      {children}
    </div>
  );
}

function HireListTableHeaderSelectAll({
  countItemsAvailable,
  onSelectAllAvailable,
  isAllAvailableSelected,
  countSelectable,
}: HireListTableHeaderSelectAllProps) {
  if (isAllAvailableSelected) {
    return (
      <HireListTableHeaderSelectAllMessage className="all-selected">
        All {countItemsAvailable} items are selected.&nbsp;
        <span onClick={onSelectAllAvailable(false)}>Clear Selection</span>
      </HireListTableHeaderSelectAllMessage>
    );
  }

  return (
    <HireListTableHeaderSelectAllMessage>
      All {countSelectable} items selected on this page.&nbsp;
      <span onClick={onSelectAllAvailable(true)}>
        Select all {countItemsAvailable} items.
      </span>
    </HireListTableHeaderSelectAllMessage>
  );
}

export default withListTableContext(HireListTableHeaderSelectAll);
