import immutable from 'immutability-helper';
import { createReducer } from '../../helper/createReducer';
import { Actions } from '../../../state/actions';
import { DEFAULT_STATE } from '../../../state/defaultState';
import { ReduxAction } from '../../../types';

export const appInitCounter = (state: any, action: ReduxAction) => {
  return immutable(state, { $merge: action.payload });
};

export default createReducer(DEFAULT_STATE.init, {
  [Actions.INIT_ACTION_SUCCESS]: appInitCounter
});
