import { getConfiguration } from '../../lib/configuration/state/action';
import {
  RequisitionEditParams,
  RequisitionConfigParams,
  KeywordSearchParams,
  FileParams,
  RequisitionSavedFilterParams,
} from './type';

export const RECRUITMENT_API_PATH = 'api/recruitment';

export const API_RESOURCES = {
  REQUISITION: 'requisitions',
  AUTHENTICATION: 'authentication',
  TRANSLATIONS: 'translations',
};

export const API_ENDPOINTS = {
  REQUISITION_LIST: 'requisitions',
  SAVE: 'save',
  DO_AUTH: 'do_auth',
  WITHDRAW: 'controlpanel/recruitment/requisition/requisition/withdraw',
  FINALISE: 'controlpanel/recruitment/requisition/requisition/finalise',
  JOB_APPLICATION_LIST: 'job-app/list',
  CONFIGURATION: 'config',
  RECRUITMENT_NAVIGATION: 'navigation/recruitment',
  TRANSLATION: 'translations',
  ONHOLD: 'controlpanel/recruitment/requisition/requisition/onhold',
  REOPEN: 'controlpanel/recruitment/requisition/requisition/reopen',
  REOPEN_ONHOLD: 'controlpanel/recruitment/requisition/requisition/onhold-reopen',
  REQUISITION_GET: (resourceParams: RequisitionEditParams) => {
    validateRequiredProps(resourceParams, ['requisitionId']);

    return `controlpanel/recruitment/requisition/requisition/get/${resourceParams.requisitionId}`;
  },
  REQUISITION_OVERVIEW: (resourceParams: RequisitionEditParams) => {
    validateRequiredProps(resourceParams, ['requisitionId']);

    return `controlpanel/recruitment/requisition/job-app/${resourceParams.requisitionId}/overview`
  },
  REQUISITION_EDIT: (resourceParams: RequisitionEditParams) => {
    validateRequiredProps(resourceParams, ['requisitionId']);

    return `controlpanel/recruitment/requisition/job-app/${resourceParams.requisitionId}/overview/details/save`;
  },
  REQUISITION_CONFIG_DATA: (resourceParams: RequisitionConfigParams) => {
    validateRequiredProps(resourceParams, ['type']);

    return `controlpanel/recruitment/requisition/requisition/get-form-data?type=${resourceParams.type}`;
  },
  REQUISITION_SAVED_FILTER_DATA: (resourceParams: RequisitionSavedFilterParams) => {
    validateRequiredProps(resourceParams, ['savedFilterId']);

    return `controlpanel/recruitment/requisition/get-filter/${resourceParams.savedFilterId}`;
  },
  USER_SEARCH: (resourceParams: KeywordSearchParams) => {
    validateRequiredProps(resourceParams, ['keyword']);

    const { token } = getConfiguration();
    const { access_token } = token;

    return `controlpanel/user-search-by-name/?access_domain=all&access_token=${access_token}&name=${resourceParams.keyword}`;
  },
  POSITION_SEARCH: (resourceParams: KeywordSearchParams) => {
    validateRequiredProps(resourceParams, ['keyword']);

    return `controlpanel/position/search/position/?keyword=${resourceParams.keyword}`;
  },
  DEPARTMENT_SEARCH: (resourceParams: KeywordSearchParams) => {
    validateRequiredProps(resourceParams, ['keyword']);

    return `controlpanel/department/search/?keyword=${resourceParams.keyword}`;
  },
  LOCATION_SEARCH: (resourceParams: KeywordSearchParams) => {
    validateRequiredProps(resourceParams, ['keyword']);

    return `controlpanel/location/search/?keyword=${resourceParams.keyword}`;
  },
  INTERNAL_DOWNLOAD_FILE: (params: FileParams): string => {
    validateRequiredProps(params, ['hash']);

    return `controlpanel/download/file/${params.hash}`;
  },
  SAVE_REQUISITION_FILTER: `controlpanel/recruitment/requisition/save-filter`,
};

const validateRequiredProps = (
  params: object,
  props: Array<string | number>
) => {
  props.forEach((property) => {
    if (!params.hasOwnProperty(property) || params[property] === null) {
      throw new Error(`Invalid property "${property}" given to build the URL.`);
    }
  });
};

export const MAIN_NAVIGATION = 'nav_resource/api/navigation_json';

export const API_DEFAULT_VERSION = 'v1';

export const TRANSLATIONS = {
  DEFAULT_LOCALE: 'en',
};
