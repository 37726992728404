import { RequisitionFilterValues } from "../../../type/requisition/Filter";
import { RequisitionExtractionFilter } from "./type";
import { convertIdTextItemToSelectOption } from "../../helper/typeConverter";
import { SelectOption } from "../../../type/form/Select";
import { IdTextItem } from "../../../type/common/items";
import { convertSelectOptionToIdTextItem } from "../../helper/typeConverter";

export const BackendToFrontendFilterMapper = {
  map: function (backendFilter: RequisitionExtractionFilter): RequisitionFilterValues
  {
    const frontendFilter: RequisitionFilterValues = {};

    if (Array.isArray(backendFilter.keywords) && backendFilter.keywords.length > 0) {
      frontendFilter.keywords = backendFilter.keywords.map((item: string) => {
        return { value: item, label: item};
      });
    } else {
      frontendFilter.keywords = null;
    }

    this.mapOptions(backendFilter, frontendFilter, [
      'departments',
      'jobTypes',
      'locations',
      'positions',
    ]);

    this.mapNumbers(backendFilter, frontendFilter, [
      'createdFrom',
      'createdTo',
      'dueFrom',
      'dueTo',
    ]);

    this.mapUsers(backendFilter, frontendFilter, [
      'owners',
      'requesters',
    ]);

    return frontendFilter;
  },

  mapNumbers: function (
    backendFilter: RequisitionExtractionFilter,
    frontendFilter: RequisitionFilterValues,
    keys: string[]
  ) {
    keys.forEach(key => {
      // string|double to number (int) if needed
      const originalValue = backendFilter[key];
      const intValue = parseInt(originalValue);

      if (!isNaN(originalValue) && intValue == originalValue) {
        frontendFilter[key] = intValue;
      } else {
        frontendFilter[key] = null;
      }
    });
  },

  mapOptions: function (
    backendFilter: RequisitionExtractionFilter,
    frontendFilter: RequisitionFilterValues,
    keys: string[]
  ) {
    keys.forEach(key => {
      if (Array.isArray(backendFilter[key]) && backendFilter[key].length > 0) {
        frontendFilter[key] = convertIdTextItemToSelectOption(backendFilter[key]);
      } else {
        frontendFilter[key] = null;
      }
    });
  },

  mapUsers: function (
    backendFilter: RequisitionExtractionFilter,
    frontendFilter: RequisitionFilterValues,
    keys: string[]
  ) {
    keys.forEach(key => {
      if (Array.isArray(backendFilter[key]) && backendFilter[key].length > 0) {
        frontendFilter[key] = convertIdTextItemToSelectOption(backendFilter[key]);
      } else {
        frontendFilter[key] = null;
      }
    });
  }
};

export const FrontendToBackendFilterMapper = {
  map: function (filter: RequisitionFilterValues): RequisitionExtractionFilter
  {
    const extractionFilter: RequisitionExtractionFilter = {};
  
    if (Array.isArray(filter.keywords) && filter.keywords.length) {
      extractionFilter.keywords = filter.keywords.map((item: SelectOption) => {
        return item.value;
      });
    }
  
    this.mapOptions(filter, extractionFilter, [
      'jobTypes',
      'positions',
      'departments',
      'locations'
    ]);
  
    this.mapNumbers(filter, extractionFilter, [
      'dueFrom',
      'dueTo',
      'createdFrom',
      'createdTo'
    ]);
  
    this.mapUsers(filter, extractionFilter, [
      'owners',
      'requesters',
    ]);
  
    return extractionFilter;
  },

  mapNumbers: function (
    frontendFilter: RequisitionFilterValues,
    backendFilter: RequisitionExtractionFilter,
    keys: string[]
  ) {
    keys.map(key => {
      // string|double to number (int) if needed
      const originalValue = frontendFilter[key];
      const intValue = parseInt(originalValue);
  
      if (!isNaN(originalValue) && intValue == originalValue) {
        backendFilter[key] = intValue;
      }
    });
  },

  mapOptions: function(
    frontendFilter: RequisitionFilterValues,
    backendFilter: RequisitionExtractionFilter,
    keys: string[]
  ) {
    keys.map(key => {
      if (Array.isArray(frontendFilter[key]) && frontendFilter[key].length > 0) {
        backendFilter[key] = convertSelectOptionToIdTextItem(frontendFilter[key]);
      }
    });
  },

  mapUsers: function (
    frontendFilter: RequisitionFilterValues,
    backendFilter: RequisitionExtractionFilter,
    keys: string[]
  ) {
    keys.map(key => {
      if (Array.isArray(frontendFilter[key]) && frontendFilter[key].length > 0) {
        const collection = convertSelectOptionToIdTextItem(frontendFilter[key]);
  
        backendFilter[key] = collection.map((item: IdTextItem) => {
          return {
            ...item,
            name: item.text
          };
        });
      }
    });
  }
};