import { authenticate } from '../../../lib/api';
import { doInitActionSuccess } from '../../init/state/action';
import Actions from '../../../state/actions';
import { ReduxAction } from '../../../types';

export const doAuth = () => {
  return async (dispatch: any, getState: any) => {
    try {
      // const response = await authenticate();
      // dispatch(fetchAuthenticationSuccess({ locale: response.lang }));
      dispatch(doInitActionSuccess({ initActionCount: getState().initState.initActionCount - 1 }));
    }
    catch (err) {
      dispatch(doAuthFailure(err));
      console.log(err);
    }
  }
}

export const doAuthFailure = (err: any): ReduxAction => {
  return {
    type: Actions.DO_AUTH_FAILURE,
    payload: { err }
  }
}

export const fetchAuthenticationSuccess = (data: any): ReduxAction => {
  return {
    type: Actions.AUTHENTICATION_SUCCESS,
    payload: { ...data }
  }
}
