import React from 'react';
import { HireListTableProps } from './type';
import HireLayoutContext from '../HireLayout/component/HireLayoutContext';
import HireListTable from './HireListTable';

class HireLayoutAwareListTable extends React.Component<HireListTableProps> {
  render() {
    return (
      <HireLayoutContext.Consumer>
        {(layoutProps: any) => {
          return (
            <HireListTable
              {...this.props}
              toggleBulkActionOpen={layoutProps.toggleBulkActionOpen}
              toggleBulkActionDisabled={layoutProps.toggleBulkActionDisabled}
            />
          );
        }}
      </HireLayoutContext.Consumer>
    );
  }
}

export default HireLayoutAwareListTable;
