import { sendRequest, getExistingApiUrl } from '../../api';
import { RequisitionConfig } from '../../../type/requisition/Requisition';

import {
  ExtractionRequisition,
  ExtractionEditRequisitionRequest,
  ExtractionRequisitionResponseData
} from './type';
import {RequisitionExtractionFilterResponse} from "../filter";

export async function getRequisition(
  requisitionId: number
): Promise<ExtractionRequisition> {
  return await sendRequest(
    `${getExistingApiUrl('REQUISITION_GET', {
      requisitionId: requisitionId,
    })}`,
    'get'
  );
}

export async function editRequisition(
  requisitionId: number,
  payload: ExtractionEditRequisitionRequest
): Promise<ExtractionRequisitionResponseData> {
  return await sendRequest(
    `${getExistingApiUrl('REQUISITION_EDIT', {
      requisitionId: requisitionId,
    })}`,
    'post',
    payload
  );
}

export async function getRequisitionConfig(
  type: string
): Promise<RequisitionConfig> {
  return await sendRequest(
    `${getExistingApiUrl('REQUISITION_CONFIG_DATA', {
      type: type,
    })}`,
    'get'
  );
}

export async function getSavedFilter(
  savedFilterId: number
): Promise<RequisitionExtractionFilterResponse> {
  return await sendRequest(
    `${getExistingApiUrl('REQUISITION_SAVED_FILTER_DATA', {
      savedFilterId,
    })}`,
    'get'
  );
}
