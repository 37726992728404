import { ReduxAction } from '../../../types';
import Actions from '../../../state/actions';
import { doInitActionSuccess } from '../../init/state/action';
import { SESSION_STORAGE } from '../../../config/initialisation';
import { ClientApiHost } from '../models/ClientUrl';
import { API_DEFAULT_VERSION } from '../../api/config';

export const initialiseClientApiBase = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(initialiseClientApiBaseStart(true));

    try {
      let subDomain = window.location.hostname.split('.')[0];
      let clientApiUrl = getApiUrl();

      const clientApiHost = {
        subDomain: subDomain,
        url: clientApiUrl,
        apiVersion: getApiVersion(),
      };

      storeClientApiHost(clientApiHost);
      dispatch(
        doInitActionSuccess({
          initActionCount: getState().initState.initActionCount - 1,
        })
      );
      dispatch(initialiseClientApiBaseStart(false));
    } catch (err) {
      dispatch(initialiseClientApiBaseFailure(err));
      console.log(err);
    }
  };
};

export const getApiVersion = (): string => {
  if (
    typeof process.env.REACT_APP_API_DATA_VERSION !== 'undefined' &&
    process.env.REACT_APP_API_DATA_VERSION != ''
  ) {
    return process.env.REACT_APP_API_DATA_VERSION;
  }
  return API_DEFAULT_VERSION;
};

export const getApiUrl = (): string => {
  let clientApiUrl = window.location.origin;

  // Use mock server
  if (
    typeof process.env.REACT_APP_API_USE_MOCK_SERVER !== 'undefined' &&
    process.env.REACT_APP_API_USE_MOCK_SERVER == 'true'
  ) {
    clientApiUrl = `${process.env.REACT_APP_API_HTTP_PROTOCOL}://${
      process.env.REACT_APP_API_MOCK_SERVER_BASE_URL
    }`;
  }
  return clientApiUrl;
};

export const initialiseClientApiBaseStart = (
  fetching: boolean
): ReduxAction => {
  return {
    type: Actions.CLIENT_API_BASE_INITIALISE_START,
    payload: { fetching },
  };
};

export const initialiseClientApiBaseFailure = (data: any): ReduxAction => {
  return {
    type: Actions.CLIENT_API_BASE_INITIALISE_FAILURE,
    payload: { data },
  };
};

export const storeClientApiHost = (clientApiHost: ClientApiHost) => {
  sessionStorage.setItem(
    SESSION_STORAGE.CLIENT_API_BASE,
    JSON.stringify(clientApiHost)
  );
};

export const getClientApiHost = (): ClientApiHost => {
  return JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE.CLIENT_API_BASE) || '{}'
  );
};
