import { SelectOption } from '../../type/form/Select';

export const validateSelect = (selectOption: SelectOption): boolean => {
  return selectOption.value ? true : false;
};

export const validateMultiSelect = (selectOptions: SelectOption[]): boolean => {
  if (!Array.isArray(selectOptions)) {
    return false;
  }
  const hasInvalidOptions = selectOptions.some((selectOption: SelectOption): boolean => {
    return !selectOption.value;
  });

  return hasInvalidOptions ? false : true;
};
