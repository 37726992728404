import RequisitionConfig from "../../../../src/config/requisition";

export enum ApprovalStatusStatus {
  PENDING = RequisitionConfig.requisitionActionStatus.PENDING,
  APPROVED = RequisitionConfig.requisitionActionStatus.APPROVED,
  REJECTED = RequisitionConfig.requisitionActionStatus.REJECTED,
}

export type ApprovalStatus = {
  id: number | null;
  approver: string | null;
  status: ApprovalStatusStatus;
  date: Date | null;
  message: string | null;
};
