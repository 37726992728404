import React from 'react';
import { getPositions } from '../../../../lib/api/position/service';
import { Select } from 'elmo-elements';
import { SelectOption } from '../../../../type/form/Select';
import {
  ExtractionGetPositionsResponse,
  ExtractionResponsePosition,
} from '../../../../lib/api/position/type';

const minKeywordLength = 2;

export interface SelectPositionsProps {
  value: SelectOption[] | null;
  onChange?: (value: SelectOption[]) => void;
}

type SelectPositionsState = {
  selectOptionsPositions: SelectOption[];
};

export class SelectPositions extends React.Component<
  SelectPositionsProps,
  SelectPositionsState
> {
  constructor(props: SelectPositionsProps) {
    super(props);

    this.state = {
      selectOptionsPositions: [],
    };
  }

  handleOnPositionsInputChange(keyword: string) {
    if (keyword.length < minKeywordLength) {
      return;
    }

    getPositions(keyword).then((response: ExtractionGetPositionsResponse) => {
      let positions: SelectOption[] = [];
      if (response.data) {
        positions = response.data.map(
          (position: ExtractionResponsePosition) => {
            return { value: position.id.toString(), label: position.text };
          }
        );
      }

      this.setState({
        selectOptionsPositions: positions,
      });
    });
  }

  render() {
    return (
      <Select
        value={this.props.value}
        options={this.state.selectOptionsPositions}
        onInputChange={(value) => this.handleOnPositionsInputChange(value)}
        onChange={this.props.onChange}
        isMulti={true}
      />
    );
  }
}

export default SelectPositions;
