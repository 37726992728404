/**
 * Default values for state
 * This is for components state.
 * Default state values for Redux should go under /src/state/defaultState
 */

import {
  NavigationMenuComponentState,
  NavigationItemsInterface,
  UserInterface,
  BrandingInterface,
  ThemeInterface,
  NavigationMenuInterface,
  MenuItemInterface
} from './types';

export const USER_DEFAULT_STATE: UserInterface = {
  id: '',
  name: '',
  img: '',
};

export const THEME_DEFAULT_STATE: ThemeInterface = {
  profile: {},
  admin: {},
  menu: {},
};

export const BRANDING_DEFAULT_STATE: BrandingInterface = {
  logo: '',
  smallLogo: '',
  logoLink: '',
  companyName: '',
  theme: THEME_DEFAULT_STATE,
};

export const MENU_ITEM_DEFAULT_STATE: MenuItemInterface = {
  key: '',
  icon: '',
  title: '',
  url: '',
};

export const MAIN_MENU_DEFAULT_STATE: NavigationMenuInterface = {
  user: [MENU_ITEM_DEFAULT_STATE],
  modules: [MENU_ITEM_DEFAULT_STATE]
};

export const NAVIGATION_ITEMS_DEFAULT_STATE: NavigationItemsInterface = {
  user: USER_DEFAULT_STATE,
  branding: BRANDING_DEFAULT_STATE,
  menu: MAIN_MENU_DEFAULT_STATE,
  subNavigation: [MENU_ITEM_DEFAULT_STATE]
};

export const NAVIGATION_DEFAULT_STATE: NavigationMenuComponentState = {
  navigationData: NAVIGATION_ITEMS_DEFAULT_STATE,
  isLoaded: false
};
