import classNames from 'classnames';
import { ReactNode, Children } from 'react';

/**
 * Make a BEM string based on props
 * @param  {string} name Base class.
 * @param  {Object<string, any>} classes Component classes.
 * @param  {Object<string, any>} modifiers Component extra class modifiers passing as props.
 * @return {string} BEM class string.
 */
export function getClass(name: string, classes: any, modifiers?: any): string {
  const modifierClasses: any = {};
  for (const key in modifiers) {
    if (key === 'className') {
      modifierClasses[`${modifiers[key]}`] = true;
    } else if (modifiers[key]) {
      modifierClasses[`${name}--${key}`] = modifiers[key];
    }
  }

  return classNames(name, classes, modifierClasses);
}

export function isElementOfType(element: any, component: any) {
  return (
    element &&
    element.type &&
    component &&
    element.type.displayName === component.displayName
  );
}

/**
 * Default noop function
 * @param e
 */
export function noop(e: any): any {
  return;
}

/**
 * TODO: this function only delete the first item that has been found in the list
 *
 * Remove an item from array by searching string
 * @param Array<string> items
 * @param {string} value
 */
export function removeArrayItem(items: Array<string>, value: string) {
  const index = items.indexOf(value);
  if (index === -1) {
    return;
  }
  items.splice(index, 1);
}

/**
 * Remove a classname from an element
 *
 * @param {HTMLElement} element
 * @param {string} classname
 */
export function removeClassToElement(element: HTMLElement, className: string) {
  // tslint:disable-next-line no-shadowed-variable
  let classNames: any = '';
  let classNameArray = [];
  classNames = element.getAttribute('class');
  if (classNames !== null && classNames.search(' ') >= 0) {
    classNameArray = classNames.split(' ');
  }
  removeArrayItem(classNameArray, className);
  element.setAttribute('class', classNameArray.join(' '));
}

/**
 * Add a classname to an element
 *
 * @param {HTMLElement} element
 * @param {string} classname
 */
export function addClassToElement(element: HTMLElement, className: string) {
  // tslint:disable-next-line no-shadowed-variable
  let classNames: any = '';
  let classNameArray = [];
  classNames = element.getAttribute('class');
  if (classNames !== null && classNames.search(' ') >= 0) {
    classNameArray = classNames.split(' ');
  }
  classNameArray.push(className);
  Array.from(new Set(classNameArray.map((item: any) => item.id)));
  element.setAttribute('class', classNameArray.join(' '));
}

/** Used to generate unique IDs. */
const idCounter: any = {};

/**
 * Source: Lodash
 *
 * Generates a unique ID. If `prefix` is given, the ID is appended to it.
 *
 * @since 0.1.0
 * @category Util
 * @param {string} [prefix=''] The value to prefix the ID with.
 * @returns {string} Returns the unique ID.
 * @see random
 * @example
 *
 * uniqueId('contact_')
 * // => 'contact_104'
 *
 * uniqueId()
 * // => '105'
 */
export function uniqueId(prefix: string = '$elmo$') {
  if (!idCounter[prefix]) {
    idCounter[prefix] = 0;
  }

  const id = ++idCounter[prefix];
  if (prefix === '$elmo$') {
    return `${id}`;
  }

  return `${prefix + id}`;
}

/**
 * Source: https://medium.com/@TCAS3/debounce-deep-dive-javascript-es6-e6f8d983b7a1
 * @param delay
 * @param fn should be only an sync function
 */
export function debounce(fn: Function, delay: number) {
  let timerId: any;

  return function(...args: any[]) {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}

/** function that locks scroll by adding a classname in HTML tag */
export function scrollLock(isLock: boolean) {
  const htmlTag = document.querySelector('html');

  if (htmlTag) {
    if (isLock) {
      htmlTag.classList.add('elmo-noscroll');
    } else {
      htmlTag.classList.remove('elmo-noscroll');
    }
  }
}

/** function that returns current scroll position */
export function getScrollY() {
  return window.pageYOffset;
}

/** function that sets scroll position manually */
export function setScrollY(scrollY: number | undefined) {
  if (document.scrollingElement) {
    document.scrollingElement.scrollTop = scrollY !== undefined ? scrollY : 0;
  }
}

/** function that adds a classname ”isIOS” in the HTML tag */
export function classOSinHTML() {
  if (
    navigator &&
    navigator.userAgent &&
    navigator.userAgent.match(/iPhone|iPad|iPod/i)
  ) {
    const htmlTag = document.querySelector('html');
    if (htmlTag) {
      htmlTag.classList.add('isIOS');
    }
  }
}

/**
 * Get a random item from the given array
 *
 * @param items
 */
export function getRandomItem(items: Array<any>): any {
  const index = Math.floor(Math.random() * items.length);
  return items[index];
}

export function isMobileDevice() {
  try {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  } catch (e) {
    return false;
  }
}

/**
 * Number formatter for Badge on the Button
 */
export function abbreviateNumber(badgeNumber: number) {
  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

  // tslint:disable-next-line no-bitwise
  const tier = (Math.log10(badgeNumber) / 3) | 0;

  if (badgeNumber < 10000) {
    return badgeNumber.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  const scaled = badgeNumber / scale;
  let str = scaled.toString().substr(0, 3);
  if (str.substr(str.length - 1, 1) === '.') {
    str = str.substr(0, str.length - 1);
  }

  return str + suffix + '+';
}

/**
 * Check if two arrays are equal based on key comparison
 * @param children1
 * @param children2
 */
export function childrenIsEqual(children1: ReactNode, children2: ReactNode) {
  if (Children.count(children1) !== Children.count(children2)) {
    return false;
  }

  const children1Arr: any[] = Children.toArray(children1);
  const children2Arr: any[] = Children.toArray(children2);

  for (let i = 0; i < children1Arr.length; i++) {
    if (children1Arr[i].key !== children2Arr[i].key) {
      return false;
    }
  }

  return true;
}

/**
 * Find Closest Element by ID
 */
export function closestParentById(el: Element, id: string) {
  let element: (Node & ParentNode) | null = el;
  while ((element as Element).id !== id) {
    element = element.parentNode;
    if (!element) {
      return null;
    }
  }
  return element;
}
