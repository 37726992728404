import * as React from 'react';

export const FlagIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H9L9.4 2H15V12H8L7.6 10H2V17H0V0Z"
          fill="black"
        />
      </svg>
    </>
  );
};
