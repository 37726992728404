import React from 'react';
import RequisitionHelper from '../../../../../config/requisition';
import { RequisitionFilterSuiteProp } from './type';
import {
  Row,
  Col,
  Search,
} from 'elmo-elements';
import { getConfiguration } from '../../../../../lib/configuration/state/action';
import { DropdownFilter } from '../../../../../elements/dropdownFilter';
import RequisitionFilter from '../RequisitionFilter/RequisitionFilter';
import {RequisitionFilterValues, RequisitionSavedFilterValues} from '../../../../../type/requisition/Filter';

type RequisitionFilterState = {
  filter: RequisitionFilterValues;
};

export class RequisitionFilterSuite extends React.Component<
  RequisitionFilterSuiteProp,
  RequisitionFilterState
> {
  items = [
    RequisitionHelper.status.getLabelAndValue('pending'),
    RequisitionHelper.status.getLabelAndValue('open'),
    RequisitionHelper.status.getLabelAndValue('rejected'),
    RequisitionHelper.status.getLabelAndValue('finalised'),
    RequisitionHelper.status.getLabelAndValue('withdrawn'),
    RequisitionHelper.status.getLabelAndValue('onHold'),
  ];

  constructor(props: RequisitionFilterSuiteProp)
  {
    super(props); 

    this.state = {
      filter: this.props.filter,
    };

    this.handleDropdownFilterChange = this.handleDropdownFilterChange.bind(this);
  }

  handleSearchChange = (keyword: string) => {
    this.setState({ filter: { keyword: keyword } });
  };

  triggerChange = () => {
    this.props.onChange(this.state.filter);
  };

  private renderSearch = () => {
    const { company } = getConfiguration();
    const { showRequisitionId } = company;

    const placeholder: string = showRequisitionId
      ? 'Search by Requisition Title or ID'
      : 'Search by Requisition Title';

    return (
      <Search
        placeholder={placeholder}
        isVisible={true}
        className="align-content-end"
        onChange={this.handleSearchChange}
        onSubmit={this.triggerChange}
        value={this.state.filter.keyword}
        isSubmitEnabledAlways={true}
      />
    );
  };

  handleDropdownFilterChange = (selected: string[]) => {
    const selectedInt: number[] = selected.map((item:string) => {
      return parseInt(item);
    });

    this.makeSearch({
      ...this.state.filter,
      statuses: selectedInt
    });
  };

  makeSearch = (filter: RequisitionFilterValues) => {
    this.setState({ filter: filter }, () => this.triggerChange());
  }

  renderDropdownFilter(): React.ReactFragment 
  {
    let statuses: string[] = [];
    if (this.state.filter.statuses) {
      statuses = [...this.state.filter.statuses].map((status: number) => status.toString());
    }

    return (
      <div className="float-left">
        <DropdownFilter
          id="statusFilter"
          items={this.items}
          subTitle="Status"
          labelAll="All requisitions"
          labelEmpty="No requisitions"
          onChange={this.handleDropdownFilterChange}
          initSelected={statuses}
        />
      </div>
    );
  };

  render(): React.ReactFragment
  {
    return (
      <Row wrap="nowrap" isNoGutters={true}>
        <Col isAuto={true} className="elmo-search__wrapper">
          { this.renderDropdownFilter() }
          <RequisitionFilter
            handleFilterSubmit={(filter: RequisitionFilterValues) => {
              this.makeSearch(filter);
            }}
            handleSavedFilterSearch={(savedFilterId: number) => {
              const savedFilters = this.props.savedFilters.filter((savedFilter: RequisitionSavedFilterValues) => {
                return savedFilter.id === savedFilterId;
              });
              if (savedFilters.length === 1) {
                this.makeSearch(savedFilters[0]);
              }
            }}
            defaultValues={this.props.filter}
            savedFilters={this.props.savedFilters}
            requisitionConfig={this.props.requisitionConfig}
          />
        </Col>
        <Col span={10}>{this.renderSearch()}</Col>
      </Row>
    );
  }
}

export default RequisitionFilterSuite;