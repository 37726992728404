import { Obj } from '../types';
import { initActions } from '../config/initialisation';

export const DEFAULT_STATE: Obj = {
  init: {
    initActions: initActions,
    initActionCount: initActions.length,
  },
  app: {
    isLoading: false,
    message: {
      text: '',
      type: '',
    },
  },
  user: {
    locale: '',
    acl: {
      can_add_requisition: false,
      can_edit_requisition: false,
      can_view_candidate: false,
    },
  },
  requisition: {
    data: {},
    state: {
      error: null,
      isLoading: false,
    },
  },
  requisitions: {
    data: [],
    filters: {
      keyword: "",
    },
    state: {
      isFetching: false,
      isCreating: false,
      isUpdating: false,
      isDeleting: false,
    },
    pagination: {
      resultsPerPage: 10,
      pageNumber: 1,
      totalResults: 0,
      totalPages: 0,
    },
    sort: {
      field: 'requisitionTitle',
      order: 'asc',
    },
  },
  jobApplications: {
    requisitionId: '',
    requisitionName: '',
    data: [],
  },
  candidate: {
    data: {},
    state: {
      error: null,
      isLoading: false,
    },
  },
  candidates: {
    data: [],
    filters: {},
    state: {},
  },
  modals: [],
  clientApiBase: {
    state: {
      isLoading: true,
      error: null,
    },
  },
  translations: {
    data: {},
    state: {
      error: null,
      isLoading: false,
    },
  },
  configuration: {
    data: {},
    state: {
      error: null,
      isLoading: false,
    },
  },
};
