import Sanitize from "sanitize-html";
const sanitizeHtml = require('sanitize-html');

/**
 * Get all (or some - user defined number) first characters of each words.
 */
export function getInitials(words: string, separator?: string, max?: number):string
{
  if (!words) {
    return '';
  }
  
  let initials:string[] = [];

  words.split(' ').map((word, index) => {
    if (max && ((index + 1) > max)) {
      return ;
    }

    initials.push(word.charAt(0).toUpperCase());
  });

  return initials.join(separator || '');
}

/**
 * Removes the tags and converts html entities.
 * @param content
 */
export function extractPureText(content: string) :string
{
  const div = document.createElement("div");
  div.innerHTML = content;
  return div.textContent || div.innerText || '';
}

export function formatToCurrency(value: number, currency?: string, withDecimals?: boolean): string
{
  let strValue = value.toString();
  if (withDecimals === true || typeof withDecimals === 'undefined') {
    strValue = value.toFixed(2);
  }
  
  if (!currency) {
    currency = '$';
  }

  const result = currency + strValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

  return result;
}

export function sanitizeHtmlString(html: string, options?: Sanitize.IOptions): string
{
  const defaults = {
    allowedTags: [ 'p', 'strong', 'em', 'i', 'u', 'ol', 'ul', 'li' ],
  };

  return sanitizeHtml(html, { ...defaults, ...options });
}