import { sendRequest, getExistingApiUrl } from '../../api';
import { ExtractionGetPositionsResponse } from './type';

export async function getPositions(
  keyword: string
): Promise<ExtractionGetPositionsResponse> {
  return await sendRequest(
    `${getExistingApiUrl('POSITION_SEARCH', {
      keyword: keyword,
    })}`,
    'get'
  );
}
