export const TYPE_PARAGRAPH = 0;
export const TYPE_SINGLE_LINE_TEXT = 1;
export const TYPE_DROPDOWN = 2;
export const TYPE_MULTIPLE_CHOICE = 3;
export const TYPE_SINGLE_CHOICE = 4;
export const TYPE_BUTTONS = 5;
export const TYPE_DATE = 7;
export const TYPE_ATTACHMENT = 9;

export type TYPES =
  typeof TYPE_PARAGRAPH
| typeof TYPE_SINGLE_LINE_TEXT
| typeof TYPE_DROPDOWN
| typeof TYPE_MULTIPLE_CHOICE
| typeof TYPE_SINGLE_CHOICE
| typeof TYPE_BUTTONS
| typeof TYPE_DATE
| typeof TYPE_ATTACHMENT;