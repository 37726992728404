import * as React from 'react';
import trans from 'i18next';
import {
  FormItem,
  Select,
  Loader,
  Container,
  Col,
  Row,
  Datepicker,
  Checkbox,
  Input,
} from 'elmo-elements';
import { FilterControl } from '../../../../../elements/filter';
import {
  RequisitionConfig,
  JobType,
} from '../../../../../type/requisition/Requisition';
import { SelectOption } from '../../../../../type/form/Select';
import { validateMultiSelect } from '../../../../../lib/helper/validator';
import {
  unixTimestampToDate,
  validateUnixTimestamp,
  dateToUnixTimestamp,
} from '../../../../../lib/helper/date';
import {
  RequisitionFilterValues,
  RequisitionSavedFilterValues,
} from '../../../../../type/requisition/Filter';
import {
  InputTags,
  SelectUsers,
  SelectPositions,
  SelectLocations,
  SelectDepartments,
} from '../../../../../elements/forms/fields';
import {
  saveRequisitionFilter,
  FrontendToBackendFilterMapper
} from '../../../../../lib/api/filter';

type FormFields = {
  jobTypes: SelectOption[] | null;
  createdFrom: Date | null;
  createdTo: Date | null;
  dueFrom: Date | null;
  dueTo: Date | null;
  keywords: SelectOption[] | null;
  owners: SelectOption[] | null;
  positions: SelectOption[] | null;
  locations: SelectOption[] | null;
  departments: SelectOption[] | null;
  requesters: SelectOption[] | null;
};

type isFieldValid = {
  jobTypes: boolean;
  createdFrom: boolean;
  createdTo: boolean;
  dueFrom: boolean;
  dueTo: boolean;
  keywords: boolean;
  owners: boolean;
  positions: boolean;
  locations: boolean;
  departments: boolean;
  requesters: boolean;
};

export interface RequisitionFilterProps {
  requisitionConfig: RequisitionConfig;
  handleFilterSubmit: Function;
  handleSavedFilterSearch: (savedFilterId: number) => void;
  defaultValues: RequisitionFilterValues;
  savedFilters: RequisitionSavedFilterValues[];
}

interface RequisitionFilterState {
  formFields: FormFields;
  isLoading: boolean;
  isFieldValid: isFieldValid;
  jobTypeOptions: SelectOption[];
  ownerOptions: SelectOption[];
  positionOptions: SelectOption[];
  locationOptions: SelectOption[];
  departmentOptions: SelectOption[];
  requesterOptions: SelectOption[];
  saveFilter: {
    isEnabled: boolean;
    title: string;
    isSaving: boolean;
  };
}

const initialFormFieldValues: FormFields = {
  jobTypes: null,
  createdFrom: null,
  createdTo: null,
  dueFrom: null,
  dueTo: null,
  keywords: null,
  owners: null,
  positions: null,
  locations: null,
  departments: null,
  requesters: null,
};

const dateFields: string[] = [
  'createdFrom',
  'createdTo',
  'dueFrom',
  'dueTo'
];

const defaultSaveFilter = {
  isEnabled: false,
  title: '',
  isSaving: false,
};

class RequisitionFilter extends React.Component<
  RequisitionFilterProps,
  RequisitionFilterState
> {
  constructor(props: RequisitionFilterProps) {
    super(props);

    this.state = {
      jobTypeOptions: [],
      ownerOptions: [],
      positionOptions: [],
      locationOptions: [],
      departmentOptions: [],
      requesterOptions: [],
      isFieldValid: {
        jobTypes: false,
        createdFrom: false,
        createdTo: false,
        dueFrom: false,
        dueTo: false,
        keywords: false,
        owners: false,
        positions: false,
        locations: false,
        departments: false,
        requesters: false,
      },
      formFields: initialFormFieldValues,
      isLoading: true,
      saveFilter: {
        ...defaultSaveFilter
      }
    };
  }

  componentDidMount() {
    let jobTypeOptions: Array<SelectOption> = [];

    const { jobTypes } = this.props.requisitionConfig;

    if (jobTypes) {
      jobTypeOptions = jobTypes.map((jobType: JobType) => {
        return { value: jobType.id.toString(), label: jobType.text };
      });
    }

    const formFields = { ...this.state.formFields };
    let isFieldValid = { ...this.state.isFieldValid };

    if (this.props.hasOwnProperty('defaultValues')) {
      const defaultValues: RequisitionFilterValues = this.props.defaultValues;

      if (defaultValues.jobTypes && defaultValues.jobTypes.length > 0) {
        formFields['jobTypes'] = defaultValues.jobTypes;
        isFieldValid['jobTypes'] = true;
      }

      dateFields.forEach((dateField: string) => {
        isFieldValid[dateField] = false;

        if (
          this.props.defaultValues.hasOwnProperty(dateField) &&
          validateUnixTimestamp(this.props.defaultValues[dateField])
        ) {
          formFields[dateField] = unixTimestampToDate(
            this.props.defaultValues[dateField]
          );
          isFieldValid[dateField] = true;
        }
      });

      if (defaultValues.owners && defaultValues.owners.length > 0) {
        formFields['owners'] = defaultValues.owners;
        isFieldValid['owners'] = true;
      }

      if (defaultValues.keywords) {
        formFields['keywords'] = defaultValues.keywords;
        isFieldValid['keywords'] = true;
      }
      if (defaultValues.positions && defaultValues.positions.length > 0) {
        formFields['positions'] = defaultValues.positions;
        isFieldValid['positions'] = true;
      }

      if (defaultValues.locations && defaultValues.locations.length > 0) {
        formFields['locations'] = defaultValues.locations;
        isFieldValid['locations'] = true;
      }

      if (defaultValues.departments && defaultValues.departments.length > 0) {
        formFields['departments'] = defaultValues.departments;
        isFieldValid['departments'] = true;
      }

      if (defaultValues.requesters && defaultValues.requesters.length > 0) {
        formFields['requesters'] = defaultValues.requesters;
        isFieldValid['requesters'] = true;
      }

    }

    this.setState({
      jobTypeOptions: jobTypeOptions,
      isLoading: false,
      formFields: formFields,
      isFieldValid: isFieldValid,
    });
  }

  handleMultiSelectOnChange(field: string, selectedOptions: Array<SelectOption>) {
    const isValidOption = validateMultiSelect(selectedOptions);
    const formFields = { ...this.state.formFields };

    if (isValidOption) {
      formFields[field] = selectedOptions;
      const fieldValidity = selectedOptions.length <= 0 ? false : true;

      this.setState(
        {
          formFields: formFields,
        },
        () => this.setFieldValidity(field, fieldValidity)
      );
    }
  }


  handleDateOnChange(field: string, selectedDate: Date | null) {
    let formFields = { ...this.state.formFields };

    if (!formFields.hasOwnProperty(field)) {
      return;
    }

    const isValidDateField = 
      selectedDate instanceof Date || selectedDate == null ? true : false;

    if (isValidDateField) {
      formFields[field] = selectedDate;
      this.setState({
        formFields: formFields,
      });
    }

    this.setFieldValidity(field, isValidDateField);
  }

  setFieldValidity(field: string, isValid: boolean) {
    let isFieldValid = { ...this.state.isFieldValid };
    isFieldValid[field] = isValid;
    this.setState({ isFieldValid });
  }

  canApplyFilter ()
  {
    // if save is not enabled
    if (!this.state.saveFilter.isEnabled) {
      return true;
    }

    // when it's saving, button should be disabled
    if (this.state.saveFilter.isSaving) {
      return false;
    }

    // has name
    if (this.state.saveFilter.title.length > 1) {
      return true;
    }

    return false;
  }

  renderFormContent(): React.ReactFragment {
    if (this.state.isLoading) {
      return <Loader type="spinner" />;
    }

    return (
      <>
        <Container>
          <Row>
            <Col xs={24} md={24} className="pl-0">
              <FormItem label={`${trans.t('Owner')}`} id="requisitionFilterOwner">
                <SelectUsers
                  value={this.state.formFields.owners}
                  onChange={(value: SelectOption[]) => this.handleMultiSelectOnChange('owners', value)}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} className="pl-0">
              <FormItem
                id="requisitionFilterJobType"
                label={`${trans.t('Job Type')}`}
              >
                <Select
                  value={this.state.formFields.jobTypes}
                  options={this.state.jobTypeOptions}
                  onChange={(value: SelectOption[]) => this.handleMultiSelectOnChange('jobTypes', value)}
                  isMulti={true}
                  id="requisitionFilterSelectJobType"
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} className="pl-0">
              <FormItem
                id="requisitionFilterCreatedFrom"
                label={`${trans.t('Created Date')}`}
              >
                <Datepicker
                  value={this.state.formFields.createdFrom}
                  onChange={(value: Date | null) =>
                    this.handleDateOnChange('createdFrom', value)
                  }
                  id="requisitionFilterInputCreatedFrom"
                />
              </FormItem>
            </Col>
            <Col xs={12} md={12} className="pl-0">
              <FormItem
                id="requisitionFilterCreatedTo"
                label={`${trans.t('To')}`}
              >
                <Datepicker
                  value={this.state.formFields.createdTo}
                  onChange={(value: Date | null) =>
                    this.handleDateOnChange('createdTo', value)
                  }
                  id="requisitionFilterInputCreatedTo"
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} className="pl-0">
              <FormItem
                id="requisitionFilterDueFrom"
                label={`${trans.t('Due Date')}`}
              >
                <Datepicker
                  value={this.state.formFields.dueFrom}
                  onChange={(value: Date | null) =>
                    this.handleDateOnChange('dueFrom', value)
                  }
                  id="requisitionFilterInputDueFrom"
                />
              </FormItem>
            </Col>
            <Col xs={12} md={12} className="pl-0">
              <FormItem id="requisitionFilterDueTo" label={`${trans.t('To')}`}>
                <Datepicker
                  value={this.state.formFields.dueTo}
                  onChange={(value: Date | null) => this.handleDateOnChange('dueTo', value)}
                  id="requisitionFilterInputDueTo"
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} className="pl-0">
              <FormItem
                id="requisitionFilterTitle"
                label={`${trans.t('Requisition Title')}`}
              >
                <InputTags
                  id="requisitionFilterInputTitle"
                  defaultValues={this.state.formFields.keywords||[]}
                  onChange={(value: SelectOption[]) => this.handleMultiSelectOnChange('keywords', value)}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} className="pl-0">
              <FormItem label={`${trans.t('Position')}`} id="requisitionFilterPosition">
                <SelectPositions
                  value={this.state.formFields.positions}
                  onChange={(value: SelectOption[]) => this.handleMultiSelectOnChange('positions', value)}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} className="pl-0">
              <FormItem label={`${trans.t('Location')}`} id="requisitionFilterLocation">
                <SelectLocations
                  value={this.state.formFields.locations}
                  onChange={(value: SelectOption[]) => this.handleMultiSelectOnChange('locations', value)}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} className="pl-0">
              <FormItem label={`${trans.t('Department')}`} id="requisitionFilterDepartment">
                <SelectDepartments
                  value={this.state.formFields.departments}
                  onChange={(value: SelectOption[]) => this.handleMultiSelectOnChange('departments', value)}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} className="pl-0">
              <FormItem label={`${trans.t('Requester')}`} id="requisitionFilterRequester">
                <SelectUsers
                  value={this.state.formFields.requesters}
                  onChange={(value: SelectOption[]) => this.handleMultiSelectOnChange('requesters', value)}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} className="pl-0">
              <Checkbox
                id="checkSaveFilter"
                label={`${trans.t('Save this filter')}`}
                ariaLabel={`${trans.t('Save this filter')}`}
                onChange={this.handleSaveFilterToggle}
                isChecked={this.state.saveFilter.isEnabled}
              />

              {this.state.saveFilter.isEnabled && (
                <FormItem label={`${trans.t('Filter Title')}`} id="requisitionFilterTitle">
                  <Input
                    id="saveFilterTitle"
                    onChange={this.handleOnChangeTitle}
                    status={this.state.saveFilter.title.length > 1 ? 'success' : 'error'}
                    value={this.state.saveFilter.title}
                  />
                </FormItem>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  handleSaveFilterToggle = () => {
    const nextState = !this.state.saveFilter.isEnabled;
    const title = nextState ? '' : this.state.saveFilter.title;
    this.setState({
      saveFilter: {
        ...this.state.saveFilter,
        isEnabled: nextState,
        title,
      }
    });
  };

  handleOnChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      saveFilter: {
        ...this.state.saveFilter,
        title: event.target.value
      }
    });
  };

  getFieldValues(): RequisitionFilterValues {
    if (!this.state.formFields) {
      return {};
    }

    const {
      jobTypes,
      owners,
      positions,
      locations,
      departments,
      requesters,
      keywords,
    } = { ...this.state.formFields };

    let formValues: RequisitionFilterValues = {};

    formValues.jobTypes = jobTypes || null;
    formValues.owners = owners || null;
    formValues.positions = positions || null;
    formValues.locations = locations || null;
    formValues.departments = departments || null;
    formValues.requesters = requesters || null;
    formValues.keywords = keywords || null;

    dateFields.forEach((dateField: string) => {
      formValues[dateField] = null;

      if (
        this.state.formFields.hasOwnProperty(dateField) &&
        validateUnixTimestamp(this.state.formFields[dateField])
      ) {
        const toDates: string[] = ['createdTo', 'dueTo'];

        if (toDates.indexOf(dateField) > -1) {
          this.state.formFields[dateField].setHours(23, 59, 59, 0);
        } else {
          this.state.formFields[dateField].setHours(0, 0, 0, 0);
        }

        const dateValue = dateToUnixTimestamp(this.state.formFields[dateField]);

        formValues[dateField] = dateValue;
      }
    });

    return formValues;
  }

  getSavedFilters = (): RequisitionSavedFilterValues[] => {
    return this.props.savedFilters;
  }

  handleClearForm() {
    this.setState({
      formFields: initialFormFieldValues,
      saveFilter: {...defaultSaveFilter},
    });
  }

  handleSubmit = (formFields: RequisitionFilterValues) => {
    if (!this.state.saveFilter.isEnabled) {
      this.props.handleFilterSubmit(formFields);
      return; // exit
    }

    const saveNow = () => {
      const filter = FrontendToBackendFilterMapper.map(formFields);
      saveRequisitionFilter(this.state.saveFilter.title, filter)
        .then(response => {
          this.props.handleFilterSubmit(formFields);
        })
        .catch(error => {
          // TODO: next version, better handling of error; ER-3920
          console.log('Error in saving the filter.');
          this.props.handleFilterSubmit(formFields);
        })
      ;
    };

    this.setState({
      saveFilter: {
        ...this.state.saveFilter,
        isSaving: true,
      }
    }, () => saveNow());
  };

  render() {
    return (
      <FilterControl
        renderFormContent={this.renderFormContent()}
        handleSavedFilterSearch={(savedFilterId: number) => {
          this.props.handleSavedFilterSearch(savedFilterId);
        }}
        handleSubmit={this.handleSubmit}
        filterValues={this.getFieldValues()}
        savedFilters={this.getSavedFilters()}
        handleClearForm={() => this.handleClearForm()}
        isApplyDisabled={!this.canApplyFilter()}
        isCloseOnSubmit={true}
      />
    );
  }
}

export default RequisitionFilter;
