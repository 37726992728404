import moment from 'moment';

export function formatUnixToDateString(unix: string, format: string)
{
  const date = new Date(parseInt(unix) * 1000);

  return moment(date).format(format);
}

export function unixTimestampToDate(unixTimestamp: number)
{
  return new Date(unixTimestamp * 1000);
}

export function dateToUnixTimestamp(date: Date) {
  return date.getTime() / 1000;
}

export function validateUnixTimestamp(date: number) {
  return new Date(date).getTime() > 0 ? true : false;
}