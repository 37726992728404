import React, {ReactChild} from 'react';
import { Trans } from 'react-i18next';
import trans from 'i18next';
import {
  Modal,
  Paragraph,
  Heading,
  DescriptionList,
  Loader,
} from 'elmo-elements';
import { ActionModalProps } from './types';
import './RequisitionDetailsModal.css';
import { formatUnixToDateString } from '../../../../../lib/helper/date';
import { extractPureText, formatToCurrency, sanitizeHtmlString } from '../../../../../lib/helper/string';
import { QuestionAnswer } from '../../../../../type/question';
import { splitByBR } from '../../../../../lib/helper/array';
import { getRequisition } from '../../../../../lib/api/requisition/service';
import { ExtractionRequisition } from '../../../../../lib/api/requisition/type';
import { DownloadableFile } from '../../../../../elements/file';
import {
  TYPE_MULTIPLE_CHOICE,
  TYPE_ATTACHMENT,
  TYPE_PARAGRAPH
} from '../../../../../config/questions';
import {RequisitionTitle} from "../../../../../elements/RequisitionTitle";
import {getConfiguration} from "../../../../../lib/configuration/state/action";

interface RequisitionDetails {
  id: number;
  originalId: number;
  title: string;
  position: string;
  owner: string;
  department: string;
  jobType: string;
  location: string;
  numberOfPositions: number;
  dueDate: string;
  note: string;
  message?: string;
};

type SalaryDetails = {
  budget?: number;
  note?: string;
};

type RequisitionDetailsModalState = {
  details: RequisitionDetails;
  salary: SalaryDetails;
  questions: QuestionAnswer[];
  isLoading: boolean;
};

export class RequisitionDetailsModal extends React.Component<
  ActionModalProps,
  RequisitionDetailsModalState
> {
  constructor(props: ActionModalProps)
  {
    super(props);

    this.state = {
      details: {
        id: 0,
        originalId: 0,
        title: '',
        position: '',
        owner: '',
        department: '',
        jobType: '',
        location: '',
        numberOfPositions: 0,
        dueDate: '',
        note: '',
        message: '',
      },
      salary: {
        budget: 0,
        note: '',
      },
      questions: [],
      isLoading: false,
    }
  }

  componentDidMount()
  {
    this.fetchDetails(this.props.data.id);
  }

  fetchDetails(id: number)
  {
    const fetchCallback = () => {
      getRequisition(id).then((result:ExtractionRequisition) => {
        let owner = '';
        if (result.owner) {
          owner = `${result.owner.firstName} ${result.owner.lastName}`;
        }

        const details: RequisitionDetails = {
          id: result.id,
          originalId: result.requisitionReference,
          jobType: result.jobType.text,
          title: result.requisitionTitleDisplay,
          note: result.requisitionNote,
          position: result.position.title,
          department: result.department.title,
          location: result.location.title,
          owner,
          numberOfPositions: result.numberOfPositions,
          dueDate: `${result.dueDate}`,
          message: result.message || '',
        };
        const salary: SalaryDetails = {
          note: result.salaryNote,
          budget: result.budget || 0,
        };
        const questions: QuestionAnswer[] = [];
  
        result.questions.map(function(exQuestionAnswer) {
          const item: QuestionAnswer = {
            id: exQuestionAnswer.id,
            type: exQuestionAnswer.type,
            question: exQuestionAnswer.question,
            answerText: exQuestionAnswer.answerText,
            answerValue: exQuestionAnswer.answer
          };
  
          if (item.type === 9) {
            item.answerValue = exQuestionAnswer.token || '';
          }
  
          questions.push(item);
        });
  
        this.setState({
          details,
          salary,
          questions,
          isLoading: false,
        });
      });
    };

    this.setState({
      isLoading: true
    }, 
      () => fetchCallback()
    );
  }

  renderDetails(): React.ReactFragment
  {
    if (!this.state.details) {
      return <></>;
    }

    return (
      <div id="requisitionDetailsSection">
        <DescriptionList.Item label="">
          { this.renderLabel('Requisition title') }
          { this.renderTitle() }
          
          { this.renderLabel('Position') }
          { this.renderContent(this.state.details.position) }
          
          { this.renderLabel('Owner') }
          { this.renderContent(this.state.details.owner) }
          
          { this.renderLabel('Department') }
          { this.renderContent(this.state.details.department) }
          
          { this.renderLabel('Job type') }
          { this.renderContent(this.state.details.jobType) }
          
          { this.renderLabel('Location') }
          { this.renderContent(this.state.details.location) }
          
          { this.renderLabel('Number of positions') }
          { this.renderContent(this.state.details.numberOfPositions) }
          
          { this.renderLabel('Due date') }
          { this.renderContent(formatUnixToDateString(this.state.details.dueDate, 'D MMM YYYY')) }
          
          { this.renderLabel('Requisition note') }
          { this.renderContent(this.state.details.note) }

          { this.renderLabel('Request message') }
          { this.renderRichText(this.state.details.message || '') }
          
        </DescriptionList.Item>
      </div>
    );
  }

  renderSalary(): React.ReactFragment
  {
    const { budget, note } = this.state.salary;

    if (!budget && !note) {
      return <></>;
    }

    return (
      <div id="salarySection">
        <hr />
        <DescriptionList.Item label="Salary">
          { this.renderLabel('Salary budget') }
          { this.renderContent(formatToCurrency(budget || 0)) }

          { this.renderLabel('Salary notes') }
          { this.renderContent(note || '') }
        </DescriptionList.Item>
      </div>
    );
  }

  renderLabel(text: string): React.ReactFragment {
    return <Heading size="xs" color="gray" ><Trans>{ text }</Trans></Heading>;
  };

  renderContent(text?: string|number|ReactChild): React.ReactFragment {
    if (!text) {
      return <Paragraph><sup>&mdash;</sup></Paragraph>;
    }

    return <Paragraph>{ text }</Paragraph>;
  };

  renderTitle(): React.ReactFragment {
    const { company } = getConfiguration();
    const { showRequisitionId } = company;

    return this.renderContent(
      <RequisitionTitle
        id={this.state.details.id}
        originalId={this.state.details.originalId}
        showLink={false}
        showRequisitionId={showRequisitionId}
        title={this.state.details.title}
      />
    );
  }

  renderQuestion(question: QuestionAnswer): React.ReactFragment
  {
    let answer: React.ReactFragment = <></>;

    // multiple answers
    if ([TYPE_MULTIPLE_CHOICE].indexOf(question.type) > -1) {
      answer = splitByBR(question.answerText).map((item, index) => {
        if (item) {
          return (
            <span key={`multiple-answer-text-${question.id}-${index}`}>
              { this.renderContent(item) }
            </span>
          );
        }

        return <></>;
      });
    }

    // attachment
    else if (question.type === TYPE_ATTACHMENT) {
      answer =
        <Paragraph>
          <DownloadableFile
            type="internal"
            hash={question.answerValue}
            label={question.answerText}
          />
        </Paragraph>
      ;
    }

    // richtext
    else if (question.type === TYPE_PARAGRAPH) {
      answer = this.renderRichText(question.answerText);
    }

    // have flat answer
    else {
      answer = this.renderContent(extractPureText(question.answerText));
    }

    return <>
      { this.renderLabel(question.question) }
      { answer }
    </>;
  }

  renderQuestions(): React.ReactFragment
  {
    if (!this.state.questions.length) {
      return <></>;
    }

    return (
      <div id="requisitionQuestionSection">
        <hr />
        <DescriptionList.Item label="Requisition questions" >
          { this.state.questions.map((question) =>
            <span key={`question-answer-${question.id}`}>
              { this.renderQuestion(question) }
            </span>
          ) }
        </DescriptionList.Item>
      </div>
    );
  }

  renderRichText(content: string): React.ReactFragment
  {
    const html = {'__html': sanitizeHtmlString(content)};
    return (
      <Paragraph>
        <div dangerouslySetInnerHTML={html} />
      </Paragraph>
    );
  }

  renderBody(): React.ReactFragment
  {
    if (this.state.isLoading) {
      return (
        <DescriptionList>
          <Loader type="spinner" />
        </DescriptionList>
      );
    }

    return (
      <DescriptionList>
        { this.renderDetails() }
        { this.renderSalary() }
        { this.renderQuestions() }
      </DescriptionList>
    );
  }

  render()
  {
    const titleLabel:string = trans.t('Requisition details');
    const closeLabel:string = trans.t('Close');

    return (
      <Modal
        id="viewDetailsModal"
        type="large"
        isOpened={ this.props.isModalOpen}
        closeModal={ this.props.toggleModal}
        title={titleLabel}
        closeLabel={closeLabel}
        ariaLabel={titleLabel}
        hasCloseFooterButton={true}
      >
        {this.renderBody()}
      </Modal>
    );
  }
}

export default RequisitionDetailsModal;