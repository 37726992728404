import { sendRequest, getExistingApiUrl } from '../../api';
import { ExtractionResponseGetUsers } from './type';

export async function getUsers(
  keyword: string
): Promise<ExtractionResponseGetUsers> {
  return await sendRequest(
    `${getExistingApiUrl('USER_SEARCH', {
      keyword: keyword,
    })}`,
    'get'
  );
}
