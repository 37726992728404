import { SortAscIcon, SortDescIcon } from 'elmo-elements';
import React from 'react';
import { SORT_ASC, SORT_DESC } from '../../_lib/const';
import { getClass } from '../../_lib/helper';

export type HireListTableSortIconProps = {
  /** The sort direction. 'asc' | 'desc' */
  direction?: SORT_ASC | SORT_DESC;
};

function HireListTableSortIcon({ direction }: HireListTableSortIconProps) {
  let classes = getClass('elmo-listtable__sort-icon', '', {
    [direction as string]: direction !== undefined,
  });

  let icon = null;

  switch (direction) {
    case SORT_ASC: {
      icon = <SortAscIcon />;
      break;
    }
    case SORT_DESC: {
      icon = <SortDescIcon />;
      break;
    }

    default: {
      return null;
    }
  }

  return (
    <span className={classes} data-sort={direction}>
      {icon}
    </span>
  );
}

export default HireListTableSortIcon;
