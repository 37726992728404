import React from 'react';
import { Trans } from 'react-i18next';
import trans from 'i18next';
import {
  Modal,
  Button,
  FormItem,
  Input,
  Select,
  Datepicker,
  Loader,
  Alert,
} from 'elmo-elements';
import { ActionModalProps } from './types';
import {
  unixTimestampToDate,
  validateUnixTimestamp,
} from '../../../../../lib/helper/date';
import { SelectUser } from '../../../../../elements/forms/fields';
import { RequisitionConfig } from '../../../../../type/requisition/Requisition';
import {
  Department,
  JobType,
} from '../../../../../type/requisition/Requisition';
import {
  getRequisition,
  editRequisition,
} from '../../../../../lib/api/requisition/service';
import { ExtractionRequisition } from '../../../../../lib/api/requisition/type';
import { SelectOption } from '../../../../../type/form/Select';

const inputFieldMaxLength = 255;

type FormFields = {
  requisitionId: number;
  requisitionTitle: string;
  owner: SelectOption | null;
  dueDate: Date | null;
  department: SelectOption | null;
  jobType: SelectOption | null;
};

type isFieldValid = {
  requisitionTitle: boolean;
  owner: boolean;
  dueDate: boolean;
  department: boolean;
  jobType: boolean;
};

type ModalAlert = {
  isDisplay: boolean;
  message: string;
};

type EditRequisitionActionState = {
  isSubmitButtonDisabled: boolean;
  departmentOptions: Array<SelectOption>;
  jobTypeOptions: Array<SelectOption>;
  isFieldValid: isFieldValid;
  formFields: FormFields;
  isLoading: boolean;
  modalAlert: ModalAlert;
};

interface EditRequisitionModalProps extends ActionModalProps {
  requisitionConfig?: RequisitionConfig;
  showNotification: Function;
}

export class EditRequisitionModal extends React.Component<
  EditRequisitionModalProps,
  EditRequisitionActionState
> {
  constructor(props: EditRequisitionModalProps) {
    super(props);

    this.state = {
      isSubmitButtonDisabled: true,
      departmentOptions: [],
      jobTypeOptions: [],
      isFieldValid: {
        requisitionTitle: false,
        owner: false,
        dueDate: false,
        department: false,
        jobType: false,
      },
      formFields: {
        requisitionId: 0,
        requisitionTitle: '',
        owner: null,
        dueDate: null,
        department: null,
        jobType: null,
      },
      isLoading: true,
      modalAlert: {
        isDisplay: false,
        message: '',
      },
    };
  }

  async componentDidMount() {
    const requisitionId = this.getRequisitionId();

    if (!this.props.requisitionConfig) {
      throw new Error(`Requisition config not found.`);
    }

    const { departments, jobTypes } = this.props.requisitionConfig;

    if (departments) {
      const departmentOptions: Array<SelectOption> = departments.map((department: Department) => {
        return { value: department.id.toString(), label: department.text };
      });

      this.setState({
        departmentOptions: departmentOptions,
      });
    }

    if (jobTypes) {
      const jobTypeOptions = jobTypes.map((jobType: JobType) => {
        return { value: jobType.id.toString(), label: jobType.text };
      });

      this.setState({
        jobTypeOptions: jobTypeOptions,
      });
    }

    if (!requisitionId) {
      throw new Error(`Requisition ID not found.`);
    }

    await getRequisition(requisitionId).then(
      (response: ExtractionRequisition) => {
        let requisitionTitle = '';
        let owner = null;
        let dueDate = null;
        let department = null;
        let jobType = null;
        let isFieldValid = { ...this.state.isFieldValid };

        if (
          response.requisitionTitleDisplay &&
          this.validateRequisitionTitle(response.requisitionTitleDisplay)
        ) {
          requisitionTitle = response.requisitionTitleDisplay;
          isFieldValid.requisitionTitle = true;
        }

        if (response.owner && response.owner.id) {
          const userFirstName = response.owner.firstName
            ? response.owner.firstName
            : '';
          const userLastName = response.owner.lastName
            ? response.owner.lastName
            : '';
          const userEmail = response.owner.email ? response.owner.email : '';

          const label = `${userFirstName} ${userLastName} (${userEmail})`;
          const value = response.owner.id.toString();
          owner = {
            label: label,
            value: value,
          };

          isFieldValid.owner = true;
        }

        if (validateUnixTimestamp(response.dueDate)) {
          dueDate = unixTimestampToDate(response.dueDate);
          isFieldValid.dueDate = true;
        }

        if (
          response.department &&
          response.department.id &&
          response.department.title
        ) {
          const label = response.department.title;
          const value = response.department.id.toString();
          department = {
            label: label,
            value: value,
          };

          isFieldValid.department = true;
        }

        if (
          response.jobType &&
          response.jobType.hasOwnProperty('id') &&
          response.jobType.text
        ) {
          const label = response.jobType.text;
          const value = response.jobType.id.toString();
          jobType = {
            label: label,
            value: value,
          };

          isFieldValid.jobType = true;
        }

        const formFields = {
          requisitionId: response.id,
          requisitionTitle: requisitionTitle,
          owner: owner,
          dueDate: dueDate,
          department: department,
          jobType: jobType,
        };

        this.setState(
          {
            formFields: formFields,
            isFieldValid: isFieldValid,
            isLoading: false,
          },
          () => this.toggleSubmitButton()
        );
      }
    );
  }

  getRequisitionId() {
    return this.props.data && this.props.data.hasOwnProperty('id')
      ? this.props.data.id
      : '';
  }

  toggleSubmitButton() {
    const hasInvalidFields = Object.keys(this.state.isFieldValid).some(
      (field) => !this.state.isFieldValid[field]
    );
    this.setState({ isSubmitButtonDisabled: hasInvalidFields });
  }

  setFieldValidity(field: string, isValid: boolean) {
    let isFieldValid = { ...this.state.isFieldValid };
    isFieldValid[field] = isValid;
    this.setState({ isFieldValid }, () => this.toggleSubmitButton());
  }

  async handleSubmit() {
    if (
      !this.state.hasOwnProperty('formFields') ||
      !this.state.formFields ||
      !this.state.formFields.hasOwnProperty('owner') ||
      !this.state.formFields.owner ||
      !this.state.formFields.owner.value ||
      !this.state.formFields.hasOwnProperty('department') ||
      !this.state.formFields.department ||
      !this.state.formFields.department.value ||
      !this.state.formFields.hasOwnProperty('jobType') ||
      !this.state.formFields.jobType ||
      !this.state.formFields.jobType.hasOwnProperty('value') ||
      !this.state.formFields.hasOwnProperty('dueDate') ||
      this.state.formFields.dueDate === null
    ) {
      return false;
    }

    const payload = {
      owner: parseInt(this.state.formFields.owner.value),
      dueDate: this.state.formFields.dueDate.getTime() / 1000,
      title: this.state.formFields.requisitionTitle,
      department: parseInt(this.state.formFields.department.value),
      jobType: parseInt(this.state.formFields.jobType.value),
    };

    await editRequisition(this.state.formFields.requisitionId, payload)
      .then((response) => {
        this.props.showNotification({
          show: true,
          message: `${trans.t('Requisition has been successfully updated.')}`,
          type: 'success',
        });
        this.props.handlePostSubmit();
        this.props.toggleModal();
      })
      .catch((error) => {
        const message =
          error.hasOwnProperty('response') &&
          error.response.hasOwnProperty('data') &&
          error.response.data.hasOwnProperty('message') &&
          error.message !== ''
            ? error.response.data.message
            : trans.t('Unable to update Requisition.');
        this.setModalAlert({ isDisplay: true, message: message });
      });
  }

  handleRequisitionTitleChange(event: any) {
    const validateRequisitionTitle = this.validateRequisitionTitle(
      event.target.value
    );

    this.setFieldValidity('requisitionTitle', validateRequisitionTitle);

    let formFields = { ...this.state.formFields };
    formFields.requisitionTitle = event.target.value;
    this.setState({
      formFields: formFields,
    });
  }

  validateRequisitionTitle(title: string) {
    return title.length > 0 && title.length <= inputFieldMaxLength;
  }

  handleOwnerOnChange(selectedOption: SelectOption) {
    const isValidOwner = this.validateSelect(selectedOption);

    if (isValidOwner) {
      let formFields = { ...this.state.formFields };
      formFields.owner = selectedOption;
      this.setState({
        formFields: formFields,
      });
    }

    this.setFieldValidity('owner', isValidOwner);
  }

  validateSelect(selectOption: SelectOption) {
    return selectOption.value ? true : false;
  }

  handleDepartmentOnChange(selectedOption: SelectOption) {
    const validateDepartment = this.validateSelect(selectedOption);

    if (validateDepartment) {
      let formFields = { ...this.state.formFields };
      formFields.department = selectedOption;
      this.setState({
        formFields: formFields,
      });
    }

    this.setFieldValidity('department', validateDepartment);
  }

  handleJobTypeOnChange(selectedOption: SelectOption) {
    const validateJobType = this.validateSelect(selectedOption);

    if (validateJobType) {
      let formFields = { ...this.state.formFields };
      formFields.jobType = selectedOption;
      this.setState({
        formFields: formFields,
      });
    }

    this.setFieldValidity('jobType', validateJobType);
  }

  handleDueDateOnChange(selectedDate: Date | null) {
    const validateDate = selectedDate instanceof Date;

    if (validateDate) {
      let formFields = { ...this.state.formFields };
      formFields.dueDate = selectedDate;
      this.setState({
        formFields: formFields,
      });
    }

    this.setFieldValidity('dueDate', validateDate);
  }

  getForm() {
    if (this.state.isLoading) {
      return <Loader type="spinner" />;
    }

    return (
      <>
        <FormItem
          label={`${trans.t('Requisition Title')}`}
          id="editRequisitionTitle"
        >
          <Input
            id="inputRequisitionTitle"
            name="requisition_title"
            onChange={(event) => this.handleRequisitionTitleChange(event)}
            value={this.state.formFields.requisitionTitle}
          />
        </FormItem>

        <FormItem label={`${trans.t('Owner')}`} id="editRequisitionOwner">
          <SelectUser
            value={this.state.formFields.owner}
            onChange={(value: SelectOption) => this.handleOwnerOnChange(value)}
          />
        </FormItem>
        <FormItem label={`${trans.t('Due Date')}`} id="editRequisitionDueDate">
          <Datepicker
            value={this.state.formFields.dueDate}
            onChange={(value) => this.handleDueDateOnChange(value)}
          />
        </FormItem>

        <FormItem
          label={`${trans.t('Department')}`}
          id="editRequisitionDepartment"
        >
          <Select
            value={this.state.formFields.department}
            options={this.state.departmentOptions}
            onChange={(value) => this.handleDepartmentOnChange(value)}
          />
        </FormItem>

        <FormItem label={`${trans.t('Job Type')}`} id="editRequisitionJobType">
          <Select
            value={this.state.formFields.jobType}
            options={this.state.jobTypeOptions}
            onChange={(value) => this.handleJobTypeOnChange(value)}
            id=""
          />
        </FormItem>
      </>
    );
  }

  handleAlertModalClose() {
    this.setState({
      modalAlert: {
        isDisplay: false,
        message: '',
      },
    });
  }

  setModalAlert(modalAlert: ModalAlert) {
    this.setState({
      modalAlert: modalAlert,
    });
  }

  getAlert() {
    if (
      !this.state.modalAlert.isDisplay ||
      this.state.modalAlert.message == ''
    ) {
      return;
    }

    return (
      <>
        <Alert
          message={this.state.modalAlert.message}
          type="danger"
          isCloseable={true}
          onClose={() => this.handleAlertModalClose()}
        />
        <br />
      </>
    );
  }

  render() {
    const primaryButton = (
      <Button
        type={'primary'}
        onClick={() => this.handleSubmit()}
        isDisabled={this.state.isSubmitButtonDisabled}
        id="editRequisitionSubmit"
      >
        <Trans>Save</Trans>
      </Button>
    );

    const ariaLabel = `${trans.t('Edit requisition')}`;
    const closeLabel = `${trans.t('Close')}`;

    return (
      <Modal
        id="editRequisitionModal"
        type="medium"
        isOpened={this.props.isModalOpen}
        closeModal={this.props.toggleModal}
        title={trans.t('Edit requisition')}
        closeLabel={closeLabel}
        ariaLabel={ariaLabel}
        primaryButton={primaryButton}
        hasCloseFooterButton={true}
      >
        {this.getAlert()}
        {this.getForm()}
      </Modal>
    );
  }
}

export default EditRequisitionModal;
