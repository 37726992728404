import React, { Children, ReactElement, ReactNode } from 'react';
import { Checkbox } from 'elmo-elements';
import { Row } from 'elmo-elements';
import { HireListTableColumnProps } from './HireListTableColumn';
import HireListTableTd, { HireListTableTdProps } from './HireListTableTd';
import { HireListTableAction, HireListTableAvatarType } from '../type';
import HireListTableActions from './HireListTableActions';
import { getClass, isElementOfType } from '../../_lib/helper';
import { withListTableContext } from '../HireListTableContextConsumer';
import { Avatar } from 'elmo-elements';

export type HireListTableTrProps = {
  /** The Id of this row */
  id?: string;
  /** Unique test id for the row, used instead of row index */
  testId?: string;
  /** An array of ListTableTds */
  children?: ReactNode;
  /** The actions available in this row. If set, will show a dropdown */
  actions?: HireListTableAction[];
  /** The icon used for showing the actions in the row. If no actions set, will show an icon */
  icon?: ReactNode;
  /** True if row is selected */
  isSelected?: boolean;
  /** True if row is disabled */
  isDisabled?: boolean;
  /** Called when row clicked */
  onRowClick?: (rowIndex: number) => void;
  /** The url to go to when clicked */
  href?: string;
  /** Called when row selected/deselected */
  onItemToggle?: (isSelect: boolean) => void;
  /** @Ignore from HireListTable */
  rowData?: any;
  /** @Ignore from HireListTable or HireListTableBody */
  rowIndex: number;
  /** @Ignore From HireListTable. */
  columns?: any;
  /** @Ignore From HireListTable */
  countSelectedItems?: number;
  /** @Ignore From HireListTable */
  selectAllIsChecked: boolean;
  /** @Ignore From HireListTable */
  setContextState: (state: any) => void;
  /** @Ignore From HireListTable */
  updateSelectAllCheckboxState: (isSelected: boolean) => void;
  /** @Ignore From HireLayoutContext */
  isBulkActionOpen?: boolean;
  /** @Ignore From Avatar Type */
  avatarType?: HireListTableAvatarType;
};

class HireListTableTr extends React.Component<HireListTableTrProps> {
  toggleCheckbox = (isSelected: boolean) => {
    const { onItemToggle, updateSelectAllCheckboxState } = this.props;

    updateSelectAllCheckboxState(isSelected);
    if (onItemToggle) {
      onItemToggle(isSelected);
    }
  };

  handleRowClick = (isSelected: boolean) => () => {
    const { isBulkActionOpen, onRowClick, rowIndex } = this.props;
    if (isBulkActionOpen) {
      this.toggleCheckbox(isSelected);
    } else if (onRowClick) {
      onRowClick(rowIndex);
    }
  };

  handleRowKeyPress = (isSelected: boolean) => (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.handleRowClick(isSelected)();
    }
    return false;
  };

  renderAvatar() {
    const { avatarType, rowData } = this.props;
    const rowDataHasAvatar = rowData && rowData.avatar && rowData.avatar.label;

    if (avatarType === 'avatar' && rowDataHasAvatar) {
      return (
        <Avatar
          src={rowData.avatar.src}
          label={rowData.avatar.label}
          isLabelVisible={false}
        />
      );
    }

    if (avatarType === 'thumbnail' && rowDataHasAvatar) {
      const backgroundImage = rowData.avatar.src
        ? { backgroundImage: 'url(' + rowData.avatar.src + ')' }
        : undefined;
      return (
        <div
          className="elmo-listtable__thumbnail"
          title={rowData.avatar.label}
          style={{ ...backgroundImage }}
          role="img"
          aria-label={rowData.avatar.label}
        />
      );
    }
  }

  renderActions() {
    const { actions, icon, isBulkActionOpen, rowIndex } = this.props;

    return (
      <HireListTableActions actions={actions} icon={icon} rowIndex={rowIndex} />
    );
  }

  renderColumns() {
    const { children, columns, rowData, rowIndex } = this.props;

    if (children) {
      return Children.map(
        children as React.ReactElement<any>[],
        (child: ReactElement<any>, colIndex: number) => {
          const column = columns[colIndex];
          const label = column ? column.props.label : '';

          if (
            !React.isValidElement(child) ||
            !isElementOfType(child, HireListTableTd)
          ) {
            return null;
          }

          return React.cloneElement(child as ReactElement<HireListTableTdProps>, {
            rowIndex: rowIndex,
            colIndex: colIndex,
            label: label,
          });
        }
      );
    } else if (columns) {
      return columns.map((child: any, colIndex: number) => {
        const column = child as ReactElement<HireListTableColumnProps>;
        return (
          <HireListTableTd
            data={rowData}
            rowIndex={rowIndex}
            colIndex={colIndex}
            label={column.props.label}
            property={column.props.property}
            render={column.props.render}
            key={colIndex}
          />
        );
      });
    }
  }

  render() {
    const {
      rowIndex,
      isSelected,
      isDisabled,
      isBulkActionOpen,
      id,
      testId,
      href,
      onRowClick,
      avatarType,
      rowData,
    } = this.props;

    const isClickable = !!(onRowClick || href) && !isDisabled;

    return (
      <div
        key={rowIndex}
        role="row"
        className={getClass('elmo-listtable__row-wrapper', '', {
          selected: isSelected,
          clickable: isClickable,
        })}
        data-testid={`elmo-listtable-row-${testId || id || 'default'}`}
        {...!isDisabled && {
          onClick: this.handleRowClick(!isSelected),
          onKeyPress: this.handleRowKeyPress(!isSelected),
        }}
        tabIndex={isClickable ? 0 : -1}
      >
        {isBulkActionOpen && (
          <div className="elmo-listtable__row-checkbox" role="cell">
            <Checkbox
              id={'cb-' + rowIndex}
              isChecked={isSelected}
              isDisabled={isDisabled}
              ariaLabel="Select row"
            />
          </div>
        )}
        {this.renderAvatar()}
        <div className="elmo-listtable__cols">
          <Row className="elmo-listtable__row">{this.renderColumns()}</Row>
        </div>
        {href && !isBulkActionOpen && (
          <a
            href={href}
            className="elmo-listtable__row-overlay-link"
            aria-label="Row href"
          />
        )}
        {this.renderActions()}
      </div>
    );
  }
}

export { HireListTableTr as HireListTableTrNoContext };
export default withListTableContext(HireListTableTr);
