import React from 'react';
import {
  DropdownFilterProp, DropdownFilterItem
} from './type';
import {
  Dropdown,
  Paragraph,
  Checkbox,
} from 'elmo-elements';
import { Trans } from 'react-i18next';
import trans from 'i18next';

type DropdownFilterState = {
  selected: string[];
  renderText: string;
};

export class DropdownFilter extends React.Component<
  DropdownFilterProp,
  DropdownFilterState
> {
  MAX_SHOWN_LABEL = 2;
  DEFAULT_LABEL_EMPTY = '';
  DEFAULT_LABEL_ALL = '';

  constructor(props: DropdownFilterProp)
  {
    super(props);

    this.DEFAULT_LABEL_EMPTY = this.props.labelEmpty || 'No item selected';
    this.DEFAULT_LABEL_ALL = this.props.labelAll || 'Select all';

    this.state = {
      selected: this.props.initSelected||[],
      renderText: this.DEFAULT_LABEL_EMPTY,
    };
  }

  getLabel(value: string): string
  {
    let result = '';

    this.props.items.map(item => {
      if (item.value === value) {
        result = item.label;
      }
    });

    return result;
  }

  handleClick = (value: string) => {
    const selected = [...this.state.selected];

    if (this.state.selected.indexOf(value) < 0) {
      selected.push(value);
    } else {
      const index = selected.indexOf(value);
      if (index > -1) {
        selected.splice(index, 1);
      }
    }

    this.setState({
      selected
    }, () => this.triggerChange());
  }

  handleSelectAllClick = () => {
    let selected:string[] = [];

    if (!this.isAllSelected()) {
      this.props.items.map(item => {
        selected.push(item.value);
      });
    }
    
    this.setState({
      selected
    }, () => this.triggerChange());
  };

  triggerChange = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state.selected);
    }
  };

  isAllSelected = (): boolean => {
    return this.props.items.length === this.state.selected.length;
  };

  isItemSelected = (value: string): boolean => {
    return this.state.selected.indexOf(value) >= 0;
  };

  renderItem(item: DropdownFilterItem): React.ReactFragment
  {
    const identifier = `${this.props.id}-dropdown-filter-item-${item.value}`;

    return (
      <Dropdown.Item
          value={ item.value }
          onClick={ this.handleClick }
          key={ identifier }
        >
          <Checkbox
            id={ identifier }
            key={ identifier }
            value={ item.value }
            className="mr-3"
            isChecked={ this.isItemSelected(item.value) }
          />
          <Trans>{ item.label }</Trans>  
      </Dropdown.Item>
    );
  }

  generateDropdownText = (values: string[]): string => {
    let selectedText = '';

    if (values.length > 0) {
      const selectedLabels:string[] = [];
      // when 1 to n selected
      values.map(selectedValue => {
        if (selectedLabels.length >= this.MAX_SHOWN_LABEL) {
          return ;
        }

        selectedLabels.push(this.getLabel(selectedValue));
      });

      selectedText = selectedLabels.join(', ');

      // when more than n selected, +n difference
      const overflowCount = values.length - this.MAX_SHOWN_LABEL;
      if (overflowCount > 0) {
        selectedText += ` +${overflowCount}`;
      }

      // when everything is selected
      if (this.isAllSelected()) {
        selectedText = this.DEFAULT_LABEL_ALL;
      }
    }

    return selectedText || trans.t(this.DEFAULT_LABEL_EMPTY);
  };

  render(): React.ReactFragment
  {
    const mainText:string = this.generateDropdownText(this.state.selected);
    
    return (
      <Dropdown
        id={`${this.props.id}-dropdown-filter`}
        text={ mainText }
        className="filter-control"
      >
        <Dropdown.Label>
          <Paragraph size="sm" className="pl-2 pt-3 ml-2" color="gray">
            <Trans>{ this.props.subTitle || 'Filter' }</Trans>
          </Paragraph>
        </Dropdown.Label>

        <Dropdown.Item
          value="__all__"
          dividerType="default"
          onClick={ this.handleSelectAllClick }
          key={`${this.props.id}-dropdown-filter-item-all`}
        >
          <Checkbox
            id={`${this.props.id}-dropdown-filter-item-all`}
            value="__all__"
            className="mr-3"
            isChecked={ this.isAllSelected() }
          />
          <Trans>{ this.DEFAULT_LABEL_ALL }</Trans>
        </Dropdown.Item>

        { this.props.items ? this.props.items.map(item => this.renderItem(item)) : <></> }
      </Dropdown>
    )
  }
}

export default DropdownFilter;