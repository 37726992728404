import { Row } from 'elmo-elements';
import React, { ReactElement, ReactNode } from 'react';
import { getClass, isElementOfType } from '../../_lib/helper';
import HireListTable from '../HireListTable';
import { HireListTableColumnProps } from './HireListTableColumn';
import HireListTableTh from './HireListTableTh';
import { withListTableContext } from '../HireListTableContextConsumer';

export type HireListTableHeaderProps = {
  /** Contains an array of HireListTableColumn elements */
  children?: ReactNode;
  /** @Ignore From HireListTableContext */
  hasActions?: boolean;
  /** @Ignore From HireListTableContext */
  isBulkActionsEnabled?: boolean;
  /** @Ignore From HireListTableContext */
  columns?: ReactNode[];
};

function HireListTableHeader({
  isBulkActionsEnabled,
  columns,
  hasActions,
}: HireListTableHeaderProps) {
  const colsClassNames = getClass('elmo-listtable__cols', '', {
    actions: hasActions && !isBulkActionsEnabled,
  });

  if (!columns) {
    return null;
  }

  return (
    <div className={colsClassNames} role="row">
      <Row className="elmo-listtable__header">
        {columns.map((child: any, index: number) => {
          if (!isElementOfType(child, HireListTable.Column)) {
            return null;
          }

          let column = child as ReactElement<HireListTableColumnProps>;
          return (
            <HireListTableTh
              index={index}
              label={column.props.label}
              sortable={!!column.props.sortable}
              key={index}
              isPlaceholder={column.props.isPlaceholder}
            />
          );
        })}
      </Row>
    </div>
  );
}

HireListTableHeader.displayName = 'HireListTableHeader';

export default withListTableContext(HireListTableHeader);
