import immutable from 'immutability-helper';
import { createReducer } from '../../../../lib/helper/createReducer';
import { ReduxAction, Obj, CrudLoadingAttributes } from '../../../../types';
import { JobApplicationPaginatedList } from '../../models/JobApplicationPaginatedList';
import { JobApplicationItem } from '../../models/JobApplicationItem';
import { PENDING, FULFILLED, ERROR, RESET } from './action';

export const onRequestSuccess = (
  state: JobApplicationPaginatedList<JobApplicationItem>,
  action: ReduxAction
) => {
  return immutable(state, {
    data: { $set: action.payload.jobApplications},
    requisitionId: { $set: action.payload.requisitionId },
    requisitionName: { $set: action.payload.requisitionName },
    pagination: { },
    searchOptions: {  },
    sort: {  },
    state: {
      error: { $set: null },
      [CrudLoadingAttributes[action.payload.crudActionName]]: { $set: false },
    },
  });
};

export const onRequestPending = (
  state: JobApplicationPaginatedList<JobApplicationItem>,
  action: ReduxAction
) => {
  return immutable(state, {
    state: {
      [CrudLoadingAttributes[action.payload.crudActionName]]: { $set: true },
    },
  });
};

export const onRequestFailure = (
  state: JobApplicationPaginatedList<JobApplicationItem>,
  action: ReduxAction
) => {
  return immutable(state, {
    state: {
      [CrudLoadingAttributes[action.payload.crudActionName]]: { $set: false },
      error: { $set: action.payload.error ? action.payload.error : 'Error' },
    },
  });
};

export const onReset = (
  state: JobApplicationPaginatedList<JobApplicationItem>,
  action: ReduxAction
) => {
  return DEFAULT_STATE.jobApplications;
};

export const DEFAULT_STATE: Obj = {
  jobApplications: {
    requisitionId: '',
    requisitionName: '',
    data: [],
    filters: {},
    state: {
      isFetching: false,
      isCreating: false,
      isUpdating: false,
      isDeleting: false,
    },
    pagination: {
      resultsPerPage: 10,
      pageNumber: 1,
      totalResults: 0,
      totalPages: 0,
    },
    sort: {
      field: 'id',
      order: 'asc',
    },
  },
};

export default createReducer(DEFAULT_STATE.jobApplications, {
  [FULFILLED]: onRequestSuccess,
  [PENDING]: onRequestPending,
  [ERROR]: onRequestFailure,
  [RESET]: onReset,
});
