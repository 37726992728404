import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import RequisitionList from './pages/requisition/list';
import JobApplicationList from './pages/jobApplications/list';
import { hot } from 'react-hot-loader/root';
import { State } from './state/reducer';
import { RouteConfig } from './types';
import { doInit } from './lib/init/state/action';
import i18n from "./lib/helper/i18n";
import { I18nextProvider } from "react-i18next";
import { getTranslations } from './lib/translation/state/action';
import { Loader } from 'elmo-elements';
import { NavigationMenu } from './elements/menu/MainNavigation';
import { default as HireLayout } from './elements/HireLayout';

export const routePaths: RouteConfig = {
  home: {
    path: '/',
    exact: true,
  },
  requisitionAdd: {
    path: '/controlpanel/recruitment/requisition/add',
    exact: true,
  },
  requisitionList: {
    path: '/controlpanel/recruitment/requisition/requisition',
    exact: true,
  },
  openRequisitionList: {
    path: '/controlpanel/recruitment/requisition/open-requisition',
    exact: true,
  },
  talentSearch: {
    path: '/controlpanel/recruitment/talent-search',
    exact: true,
  },
  interviews: {
    path: '/controlpanel/recruitment/interviews',
    exact: true,
  },
  panelReview: {
    path: '/controlpanel/recruitment/panel-review',
    exact: true,
  },
  approval: {
    path: '/controlpanel/recruitment/requisition/approval',
    exact: true,
  },
  jobApplicationList: {
    path: '/controlpanel/recruitment/requisition/job-app/',
    exact: false,
  }
}

export class Routers extends React.Component<any> {
  componentDidMount() {
    this.props.doInit();
  }

  /**
   * Different Route for Modal may cause perfomance issue, because it re-renders the RequisitionList, on modal open and close 
   * (does not do ajax request to fetch list, but still it has to rendor dom again)
   */
  public render() {
    if (!this.props.initState || this.props.initState.initActionCount > 0) {
      return (
        <Loader type="content"></Loader>
      );
    }

    const { userState } = this.props;

    return (
      <I18nextProvider i18n={i18n(getTranslations(), userState.locale)} >
        <BrowserRouter>
          <HireLayout menu={<NavigationMenu />}>
            <Route>
              <App>
                <Switch>
                  <Route exact={routePaths.home.exact} path={routePaths.home.path} />
                  <Route
                    exact={routePaths.requisitionList.exact}
                    path={routePaths.requisitionList.path}
                    render={props => <RequisitionList myRecords={true} {...props} />}
                  />
                  <Route
                    exact={routePaths.openRequisitionList.exact}
                    path={routePaths.openRequisitionList.path}
                    render={props => <RequisitionList myRecords={false} {...props} />}
                  />

                  {/* TODO: below are placeholder routes until we implement actual components */}
                  <Route
                    exact={routePaths.talentSearch.exact}
                    path={routePaths.talentSearch.path}
                    render={props => <RequisitionList myRecords={false} {...props} />}
                  />
                  <Route
                    exact={routePaths.interviews.exact}
                    path={routePaths.interviews.path}
                    render={props => <RequisitionList myRecords={false} {...props} />}
                  />
                  <Route
                    exact={routePaths.panelReview.exact}
                    path={routePaths.panelReview.path}
                    render={props => <RequisitionList myRecords={false} {...props} />}
                  />
                  <Route
                    exact={routePaths.approval.exact}
                    path={routePaths.approval.path}
                    render={props => <RequisitionList myRecords={false} {...props} />}
                  />
                  <Route exact={routePaths.jobApplicationList.exact} path={routePaths.jobApplicationList.path} component={JobApplicationList} />
                </Switch>
              </App>
            </Route>
          </HireLayout>
        </BrowserRouter >
      </I18nextProvider>
    );
  }
}

const mapStateToProps = (state: State) => ({
  initState: state.initState,
  userState: state.userState
});

const mapDispatchToProps = (dispatch: any) => ({
  doInit: () => dispatch(doInit()),
});

export default connect(mapStateToProps, mapDispatchToProps)
  (hot(Routers));