import React from 'react';
import { HireListTableContextProps } from './type';
import HireLayoutContext from '../HireLayout/component/HireLayoutContext';
import HireListTableContext from './HireListTableContext';
import { HireLayoutContextProps } from '../HireLayout';

export type ListTableConsumerProps = Partial<
  HireListTableContextProps & HireLayoutContextProps
>;

export const withListTableContext = <P extends ListTableConsumerProps>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentClass<any> => {
  return class extends React.Component<P> {
    static displayName: string = WrappedComponent.displayName
      ? WrappedComponent.displayName
      : '';

    render() {
      return (
        <HireLayoutContext.Consumer>
          {(layoutProps: any) => (
            <HireListTableContext.Consumer>
              {(listTableProps: any) => (
                <WrappedComponent
                  {...(listTableProps.hasLayout ? layoutProps : {})}
                  {...listTableProps}
                  {...this.props}
                />
              )}
            </HireListTableContext.Consumer>
          )}
        </HireLayoutContext.Consumer>
      );
    }
  };
};
