import { RECRUITMENT_API_PATH, API_RESOURCES, API_ENDPOINTS } from '../../../../lib/api/config';
import { RequisitionListRequest } from "../../models/RequisitionListRequest";
import { generateCrudAction, generateApiUrl } from '../../../../lib/api';
import { getClientApiHost, getApiVersion } from '../../../../lib/clientUrl/state/action';

export const PENDING = "REQUISITION_ACTION_PENDING";
export const FULFILLED = "REQUISITION_ACTION_FULFILLED";
export const ERROR = "REQUISITION_ACTION_ERROR";
export const RESET = "REQUISITION_ACTION_RESET";

export const CANCEL_TO_BE_REPLACED = 'CANCEL_TO_BE_REPLACED';

const getRequisitionListEndpoint = (resource: string) => `${generateApiUrl('REQUISITION_LIST')}`;

const { fetchRecords, createRecords } = generateCrudAction({
  pendingActionType: PENDING,
  fulfilledActionType: FULFILLED,
  errorActionType: ERROR,
  resetActionType: RESET
})

export const fetchRequisitionList = (request?: RequisitionListRequest) => {
  return fetchRecords(getRequisitionListEndpoint(API_RESOURCES.REQUISITION), request, "post");
}
