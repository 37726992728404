import { Action, AnyAction, Reducer } from 'redux';

export interface Handlers<S, A extends Action = AnyAction> {
  [actionType: string]: Reducer<S, A>;
}

export function createReducer<S, A extends Action = AnyAction>(initialState: S, handlers: Handlers<S, A>): Reducer<S, A> {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  }
}
