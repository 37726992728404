import { combineReducers } from 'redux';
import RequisitionReducer from '../pages/requisition/list/state/reducer';
import JobApplicationReducer from '../pages/jobApplications/list/state/reducer';
import AppReducer from './app/reducer';
import { PaginatedList } from '../lib/api/models/PaginatedList';
import { PaginatedList as RequisitionPaginatedList } from '../pages/requisition/models/PaginatedList';
import { CommonAppState } from '../types';
import { RequisitionItem } from '../pages/requisition/models/RequisitionItem';
import { JobApplicationItem } from '../pages/jobApplications/models/JobApplicationItem';
import InitAppReducer from '../lib/init/state/reducer';
import AuthReducer from '../lib/auth/state/reducer';
import { Pagination } from '../lib/api/models/Pagination';
import { Sort } from '../lib/api/models/Sort';
import { SearchOptions } from '../lib/api/models/SearchOptions';

export interface State {
  requisitions: RequisitionPaginatedList<RequisitionItem>;
  jobApplications: PaginatedList<JobApplicationItem>;
  sharedAppState: CommonAppState;
  modals: Array<any>,
  initState: Array<any>,
  userState: Array<any>
  searchOptions: SearchOptions;
  pagination: Pagination;
  sort: Sort;
};

export default combineReducers({
  requisitions: RequisitionReducer,
  jobApplications: JobApplicationReducer,
  sharedAppState: AppReducer,
  initState: InitAppReducer,
  userState: AuthReducer,
});