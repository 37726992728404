import { SelectOption } from '../../type/form/Select';

export function getSelectedIds(selectedOptions: SelectOption[]): number[] {
  return selectedOptions.map((selectedOption: SelectOption) => {
    return parseInt(selectedOption.value);
  });
}

export function getSelectedStringValue(selectedOption: SelectOption[]): string[] {
  return selectedOption.map((selectedOption: SelectOption) => {
    return `${selectedOption.value}`;
  });
}
