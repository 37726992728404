import { matchPath } from 'react-router-dom';
import {
  NavigationMenuComponentState,
  MenuItemInterface,
  BrandingInterface,
  UserMenuItemInterface,
  UserInterface,
  UserDetailsInterface,
  NavigationItemInterface,
} from './types';
import {
  RECRUITMENT_MENU_KEY,
  APP_ADMIN_MENU_KEY,
  SUBMENU_RECRUITMENT_REQUISITIONS,
  SUBMENU_RECRUITMENT_MYREQUISITIONS,
  SUBMENU_RECRUITMENT_TALENTSEARCH,
  SUBMENU_RECRUITMENT_PANELREVIEWS,
  SUBMENU_RECRUITMENT_INTERVIEWS,
  SUBMENU_RECRUITMENT_APPROVAL
} from '../../../config/navigation';
import {
  OpenRequisitionIcon,
  MessagingOutlinedIcon,
  ChatOutlinedIcon,
  DoneAllIcon
} from 'elmo-elements';
import { routePaths } from '../../../routes';

export const iconComponents: any = {
  OpenRequisitionIcon: OpenRequisitionIcon,
  MessagingOutlinedIcon: MessagingOutlinedIcon,
  ChatOutlinedIcon: ChatOutlinedIcon,
  DoneAllIcon: DoneAllIcon
};

export const getBranding = (
  componentState: NavigationMenuComponentState
): BrandingInterface => {
  const {
    navigationData: { branding }
  } = componentState;

  return branding;
};

export const generateUserMenu = (
  componentState: NavigationMenuComponentState
): Array<UserMenuItemInterface> => {
  const {
    navigationData: {
      menu: { user }
    }
  } = componentState;

  const menuItems = user.filter(function(menuItem) {
    return menuItem.key !== APP_ADMIN_MENU_KEY;
  });

  return menuItems.map(menuItem => ({
    title: menuItem.title,
    url: menuItem.url,
    icon: menuItem.icon
  }));
};

export const getUserDetails = (
  componentState: NavigationMenuComponentState
): UserInterface => {
  const {
    navigationData: { user }
  } = componentState;

  return user;
};

export const getAdminMenuDetails = (
  componentState: NavigationMenuComponentState
): UserMenuItemInterface | undefined => {
  const {
    navigationData: {
      menu: { user }
    }
  } = componentState;

  return user
    .filter(function(menuItem) {
      return menuItem.key == APP_ADMIN_MENU_KEY;
    })
    .shift();
};

export const generateUserDetails = (
  componentState: NavigationMenuComponentState
): UserDetailsInterface => {
  const userDetails = getUserDetails(componentState);
  const adminUserDetails = getAdminMenuDetails(componentState);

  let isAdmin = true;
  let adminUrl = '';

  if (adminUserDetails && 'url' in adminUserDetails) {
    isAdmin = true;
    adminUrl = adminUserDetails.url;
  }

  return {
    avatar: userDetails.img,
    name: userDetails.name,
    isAdmin: isAdmin,
    adminUrl: adminUrl
  };
};

export const matchMenuPath = (routePath: string): boolean => {
  return matchPath(window.location.pathname, {
    path: routePath
  }) !== null
    ? true
    : false;
};

export const subMenuSelected = (menuItem: MenuItemInterface): boolean => {
  switch (menuItem.key) {
    case SUBMENU_RECRUITMENT_MYREQUISITIONS:
      return matchMenuPath(routePaths.requisitionList.path);
      break;
    case SUBMENU_RECRUITMENT_REQUISITIONS:
      return matchMenuPath(routePaths.openRequisitionList.path);
      break;
    case SUBMENU_RECRUITMENT_TALENTSEARCH:
      return matchMenuPath(routePaths.talentSearch.path);
      break;
    case SUBMENU_RECRUITMENT_INTERVIEWS:
      return matchMenuPath(routePaths.interviews.path);
      break;
    case SUBMENU_RECRUITMENT_PANELREVIEWS:
      return matchMenuPath(routePaths.panelReview.path);
      break;
    case SUBMENU_RECRUITMENT_APPROVAL:
      return matchMenuPath(routePaths.approval.path);
      break;
  }
  return false;
};

export const generateSubNavigationData = (
  componentState: NavigationMenuComponentState
): Array<NavigationItemInterface> => {
  const {
    navigationData: { subNavigation }
  } = componentState;

  return subNavigation.map(menuItem => ({
    title: menuItem.title,
    url: menuItem.url,
    icon: iconComponents[menuItem.icon],
    isSelected: subMenuSelected(menuItem)
  }));
};

export const generateMainNavigationData = (
  componentState: NavigationMenuComponentState
) => {
  const {
    navigationData: {
      menu: { modules }
    }
  } = componentState;

  return modules.map(menuItem => ({
    title: menuItem.title,
    url: menuItem.url,
    icon: menuItem.icon,
    isSelected: menuItem.key === RECRUITMENT_MENU_KEY
  }));
};
