import React from 'react';
import { getLocations } from '../../../../lib/api/location/service';
import { Select } from 'elmo-elements';
import { SelectOption } from '../../../../type/form/Select';
import {
  ExtractionGetLocationsResponse,
  ExtractionResponseLocation,
} from '../../../../lib/api/location/type';

const minKeywordLength = 2;

export interface SelectLocationsProps {
  value: SelectOption[] | null;
  onChange?: (value: SelectOption[]) => void;
}

type SelectLocationsState = {
  selectOptionsLocations: SelectOption[];
};

export class SelectLocations extends React.Component<
  SelectLocationsProps,
  SelectLocationsState
> {
  constructor(props: SelectLocationsProps) {
    super(props);

    this.state = {
      selectOptionsLocations: [],
    };
  }

  handleOnLocationsInputChange(keyword: string) {
    if (keyword.length < minKeywordLength) {
      return;
    }

    getLocations(keyword).then((response: ExtractionGetLocationsResponse) => {
      let locations: SelectOption[] = [];
      if (response.data) {
        locations = response.data.map((location: ExtractionResponseLocation) => {
          return { value: location.id.toString(), label: location.text };
        });
      }

      this.setState({
        selectOptionsLocations: locations,
      });
    });
  }

  render() {
    return (
      <Select
        value={this.props.value}
        options={this.state.selectOptionsLocations}
        onInputChange={(value) => this.handleOnLocationsInputChange(value)}
        onChange={this.props.onChange}
        isMulti={true}
      />
    );
  }
}

export default SelectLocations;
