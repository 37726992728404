import React from 'react';
import { getUsers } from '../../../../lib/api/user/service';
import { Select } from 'elmo-elements';
import { SelectOption } from '../../../../type/form/Select';
import {
  ExtractionResponseGetUsers,
  ExtractionResponseUser,
} from '../../../../lib/api/user/type';

const minKeywordLength = 2;

export interface SelectUsersProps {
  value: SelectOption[] | null;
  onChange?: (value: SelectOption[]) => void;
}

type SelectUsersState = {
  selectOptionsUsers: SelectOption[];
};

export class SelectUsers extends React.Component<
  SelectUsersProps,
  SelectUsersState
> {
  constructor(props: SelectUsersProps) {
    super(props);

    this.state = {
      selectOptionsUsers: [],
    };
  }

  handleOnUsersInputChange(keyword: string) {
    if (keyword.length < minKeywordLength) {
      return;
    }

    getUsers(keyword).then((response: ExtractionResponseGetUsers) => {
      let users: SelectOption[] = [];
      if (response.data) {
        users = response.data.map((user: ExtractionResponseUser) => {
          return { value: user.id.toString(), label: user.text };
        });
      }

      this.setState({
        selectOptionsUsers: users,
      });
    });
  }

  render() {
    return (
      <Select
        value={this.props.value}
        options={this.state.selectOptionsUsers}
        onInputChange={(value) => this.handleOnUsersInputChange(value)}
        onChange={this.props.onChange}
        isMulti={true}
      />
    );
  }
}

export default SelectUsers;
