import { JobApplications } from './components/view';
import { JobApplicationPaginatedList } from "../models/JobApplicationPaginatedList";
import { routePaths } from '../../../routes';
import React from 'react';
import { JobApplicationListRequest } from '../models/JobApplicationListRequest';
import { RouteComponentProps } from 'react-router';
import { JobApplicationItem } from '../models/JobApplicationItem';
import {
  Layout
} from 'elmo-elements';
import JobApplicationListHeader from './components/JobApplicationListHeader';
import { SearchOptions } from '../../../lib/api/models/SearchOptions';
import { Pagination } from '../../../lib/api/models/Pagination';
import { Sort } from '../../../lib/api/models/Sort';
import { getLastUrlParam } from './helper/GetUrlParam';

export interface JobApplicationsProps extends RouteComponentProps {
  jobApplications: JobApplicationPaginatedList<JobApplicationItem>;
  searchOptions: SearchOptions;
  pagination: Pagination;
  sort: Sort;
  fetchJobApplicationList: (requisitionId: string, request?: JobApplicationListRequest) => void;
  dispatch: any;
  requsitionId: string;
  requisitionName: string;
}

interface JobApplicationState
{
  myRecords: boolean;
  search: string;
  isSelectEnabled: boolean;
  requisitionId: string;
  requisitionName: string;
}

export class JobApplicationList extends React.Component<JobApplicationsProps, JobApplicationState> {
  constructor(props: JobApplicationsProps) {
    super(props);
    this.displayAddForm = this.displayAddForm.bind(this);
    
    //call a helper to get url id
    const requisitionId = getLastUrlParam();
    
    this.state = {      
      myRecords: true,
      search: '',
      isSelectEnabled: false,
      requisitionId: requisitionId,
      requisitionName: ''
    };
  }

  public displayAddForm() {
    this.props.history.push(routePaths.requisitionAdd.path);
  }

  componentDidMount() {    
    const requisitionListRequest: JobApplicationListRequest = {
      pagination: {
        resultsPerPage: 10,
        pageNumber: 1
      },
      sort: {
        field: "requisitionName",
        order: "asc"
      },
      searchOptions: {
        myRecords: this.state.myRecords
      }
    };

    this.props.fetchJobApplicationList(this.state.requisitionId, requisitionListRequest);
  }

  handleToggleSelectAll = () => {
    this.setState({
      isSelectEnabled: !this.state.isSelectEnabled
    });
  };

  public render() {
    return (
      <>
        <Layout.Header>
          <JobApplicationListHeader
            jobApplications={this.props.jobApplications}            
            onToggleSelectAll={this.handleToggleSelectAll}
            dispatch={this.props.dispatch}
            requisitionId={this.props.jobApplications.requisitionId ? this.props.jobApplications.requisitionId : ''}
            requisitionName={this.props.jobApplications.requisitionName ? this.props.jobApplications.requisitionName : ''}            
          />
        </Layout.Header>

        <Layout.Content>
          <JobApplications
            {...this.props}
            displayAddForm={this.displayAddForm}
            isSelectAllEnabled={this.state.isSelectEnabled}                               
            />
        </Layout.Content>
      </>
    );
  }
}